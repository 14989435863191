import React from 'react'

import './HowItWorks.scss'

import createTeam from '../../assets/images/reskin/create-team.svg'
import checkout from '../../assets/images/reskin/checkout.svg'
import inviteTeam from '../../assets/images/reskin/invite-team.svg'

const HowItWorks = () => {
  return (
    <div className="how-it-works">
      <h1>
        How it <span>works</span>
      </h1>
      <p align="center" className="narrow">
        It's quick & easy to help your team feel better and work better
      </p>
      <div className="how-works-card-container">
        <div className="how-works-card">
          <img src={createTeam} alt="Team icon" width={85.5} height={46.5} />
          <p className="title" align="center">
            Create your team
          </p>
          <p align="center">
            Choose your team name and select the number of subscriptions you'd
            like
          </p>
        </div>

        <div className="how-works-card">
          <img src={checkout} alt="Credit card icon" width={63} height={45} />
          <p className="title" align="center">
            Quick &amp; easy <br></br>checkout
          </p>
          <p align="center">Pay securely online</p>
        </div>
        <div className="how-works-card">
          <img src={inviteTeam} alt="Invitation icon" width={63} height={60} />
          <p className="title" align="center">
            Invite your team
          </p>
          <p align="center">
            Invite your team members via email to give them free access
          </p>
        </div>
      </div>
    </div>
  )
}

export default HowItWorks

import {
  SIGNUP_COMPLETE,
  SIGNUP_ERROR,
  SIGNUP_START,
  SIGNUP_LOCATION_UPDATED,
  CORPORATION_SIGNUP_INFO_UPDATED,
  SIGNUP_INVITE_LOADED,
  SIGNUP_INVITE_ERROR,
} from '../actions/types'

const initialState = {
  data: null,
  error: null,
  loading: false,
}

export default function (state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case SIGNUP_INVITE_LOADED:
      return {
        ...state,
        data: payload,
        error: null,
      }
    case SIGNUP_INVITE_ERROR:
      return {
        ...state,
        data: null,
        error: payload,
      }
    default:
      return state
  }
}

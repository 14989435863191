import { SUPER_ADMIN_LOADED } from './types'
import { db, functions } from '../firebase'

import { first } from 'lodash-es'
import { loadCorporation } from './corporation'
import { logout } from './auth'
import { copyTextToClipboard } from '../utils/util.clipboard'
import { extractId } from '../utils/utils'

const corporateSuperAdmin = functions.httpsCallable('corporateSuperAdmin')

export const loadSuperAdmins = () => async (dispatch) => {
  return corporateSuperAdmin({
    action: 'get-super-admins',
  })
    .then((result) => result.data || result)
    .then((data) => {
      const { status, result, error } = data

      switch (status) {
        case 'ok':
          dispatch({
            type: SUPER_ADMIN_LOADED,
            payload: result,
          })

          return true
          break
        case 'error':
        default:
          throw new Error(error || 'Unknown error')
          break
      }
    })
}

export const createSuperAdmin =
  ({ email }) =>
  async (dispatch) => {
    return corporateSuperAdmin({
      action: 'create-super-admin',
      data: {
        email,
      },
    })
      .then((result) => result.data || result)
      .then(async (data) => {
        const { status, result, error } = data

        console.log(status, result, error)

        switch (status) {
          case 'ok':
            const { password } = result
            if (password) {
              await copyTextToClipboard(
                `username: ${email}\npassword: ${password}`
              )
                .then(() => {
                  alert('New user created, details copied to clipboard')
                })
                .catch((err) => {
                  alert(
                    'New user created but failed to copy details to clipboard (use forgot password feature)'
                  )
                })
            }
            //reload the list of updated super admin
            return dispatch(loadSuperAdmins())
            break
          case 'error':
          default:
            throw new Error(error || 'Unknown error')
            break
        }
      })
  }

export const removeSuperAdmin = (admin) => async (dispatch) => {
  console.log('removeSuperAdmin', admin)
  return corporateSuperAdmin({
    action: 'remove-super-admin',
    //NOTE: we're passing the whole admin instance as it might be identifiable by email address or adminId
    data: admin,
  })
    .then((result) => result.data || result)
    .then(async (data) => {
      console.log('removeSuperAdmin:completed', data)
      const { status, result, error } = data

      switch (status) {
        case 'ok':
          dispatch(loadSuperAdmins())
          break
        case 'error':
        default:
          throw new Error(error || 'Unknown error')
          break
      }
    })
}

import React from 'react'
import { Link } from 'react-router-dom'

import { isUndefined, filter, join, includes, omit, first } from 'lodash-es'
import PropTypes from 'prop-types'

import './ToolTip.scss'
import { FormatClassNames } from '../../utils/utils.classnames'

const ALIGNMENTS = ['top', 'left', 'bottom', 'right']

const ToolTip = (props) => {
  const className = FormatClassNames(props.className, 'tooltip')
  const ClassName = props.as || 'div'
  const label = props.label
  const children = props.children
  const align = includes(ALIGNMENTS, props.align)
    ? props.align
    : first(ALIGNMENTS)

  var iprops = omit(props, 'children', 'label', 'as')

  return (
    <ClassName data-align={align} className={className} {...iprops}>
      {children}
      <label>{label}</label>
    </ClassName>
  )
}

ToolTip.propTypes = {
  to: PropTypes.string,
}

export default ToolTip

// Auth

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const USER_LOADED = 'USER_LOADED'
export const REGISTER_FAIL = 'REGISTER_FAIL'
export const AUTH_ERROR = 'AUTH_ERROR'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGIN_START = 'LOGIN_START'
export const LOGOUT = 'LOGOUT'

// Admin
export const ADMIN_LOADED = 'ADMIN_LOADED'

// Super Admin
export const SUPER_ADMIN_LOADED = 'SUPER_ADMIN_LOADED'

// CORPORATION DATA
export const CORPORATION_UNLOADED = 'CORPORATION_UNLOADED'
export const CORPORATION_LOADED = 'CORPORATION_LOADED'
export const CORPORATION_VOUCHERS_UPDATED = 'CORPORATION_VOUCHERS_UPDATED'
export const CORPORATION_SUBSCRIPTION_USAGE_UPDATED =
  'CORPORATION_SUBSCRIPTION_USAGE_UPDATED'
export const CORPORATION_INVOICES_UPDATED = 'CORPORATION_INVOICES_UPDATED'
export const CORPORATION_SIGNUP_INFO_UPDATED = 'CORPORATION_SIGNUP_INFO_UPDATED'
export const SET_WELCOME_FLAG = 'SET_WELCOME_FLAG'
// export const CORPORATION_SIGNUP_INFO_FAILED = 'CORPORATION_SIGNUP_INFO_FAILED';

// INVITES QUEUE
export const INVITES_UPLOAD_STARTED = 'INVITES_UPLOAD_STARTED'
export const INVITES_UPLOAD_COMPLETED = 'INVITES_UPLOAD_COMPLETED'
export const INVITES_UPLOAD_INCOMPLETE = 'INVITES_UPLOAD_INCOMPLETE'
export const INVITES_UPLOAD_PROGRESS = 'INVITES_UPLOAD_PROGRESS'

// Signup
export const SIGNUP_START = 'SIGNUP_START'
export const SIGNUP_ERROR = 'SIGNUP_ERROR'
export const SIGNUP_COMPLETE = 'SIGNUP_COMPLETE'
export const SIGNUP_LOCATION_UPDATED = 'SIGNUP_LOCATION_UPDATED'

export const SIGNUP_INVITE_LOADED = 'SIGNUP_INVITE_LOADED'
export const SIGNUP_INVITE_ERROR = 'SIGNUP_INVITE_ERROR'

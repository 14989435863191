import validator from 'validator'
import { size, isNumber as _isNumber, isNaN } from 'lodash-es'

export const isEmailValid = (value) => {
  return validator.isEmail(value)
}

export const assertEmailValid = (value) => {
  if (!isEmailValid(value)) {
    throw new Error('Invalid email address')
  }
}

export const isPasswordValid = (value, { throwError = false } = {}) => {
  if (size(value) < 6) {
    if (throwError) {
      throw Error('Password is too short')
    } else {
      return false
    }
  }

  return true
}

export const assertPasswordValid = (value) => {
  isPasswordValid(value, { throwError: true })
}

export const isTeamNameValid = (value, { throwError = false } = {}) => {
  if (size(value) < 2) {
    if (throwError) {
      throw Error('Team Name is too short')
    } else {
      return false
    }
  }

  return true
}

export const assertTeamNameValid = (value) => {
  isTeamNameValid(value, { throwError: true })
}

export const isCompanyNameValid = (value, { throwError = false } = {}) => {
  if (size(value) < 2) {
    if (throwError) {
      throw Error('Company Name is too short')
    } else {
      return false
    }
  }

  return true
}

export const assertCompanyNameValid = (value) => {
  isCompanyNameValid(value, { throwError: true })
}

export const isNumber = (value) => {
  return _isNumber(value) && !isNaN(value) ? true : false
}

import {
  SIGNUP_COMPLETE,
  SIGNUP_ERROR,
  SIGNUP_START,
  SIGNUP_LOCATION_UPDATED,
} from './types'

import app from '../firebase'
import { notifyAdmin } from './admin'
import {
  createCorporation,
  loadCorporation,
  setupCorporationSubscription,
  wakeupCorporationService,
  getCorporationSignUpInfo,
} from './corporation'

const { REACT_APP_FIREBASE_CONFIG } = process.env

export const signupWake = () => async (dispatch) => {
  console.log('signupWake')
  return dispatch(wakeupCorporationService())
}

export const signupGetInfo = () => async (dispatch) => {
  console.log('signupGetInfo')
  dispatch(getCorporationSignUpInfo())

  await fetch('https://ipapi.co/json/')
    .then((result) => result.json())
    .then((result) => {
      dispatch({
        type: SIGNUP_LOCATION_UPDATED,
        payload: result,
      })
    })
}

export const signupSetup =
  ({
    email,
    password,
    teamName,
    companyName,
    numberOfUsers,
    activationLimit,
    country,
    product,
  }) =>
  async (dispatch) => {
    console.log('signupSetup')
    return Promise.resolve()
      .then(() => {
        //clear any previous error
        dispatch({
          type: SIGNUP_START,
        })
        //call firebase and get it to create a user
        const data = {
          email,
          password,
          teamName,
          companyName,
          numberOfUsers,
          activationLimit,
          country,
          product,
        }

        return dispatch(createCorporation(data)).then(async (result) => {
          //login in silently so we can manage the users transition when they return

          dispatch({
            type: SIGNUP_COMPLETE,
          })
        })
      })
      .catch((err) => {
        console.error(err)
        dispatch(signupError(err.message || err))

        return false
      })
  }

export const signupEnquiry =
  ({
    email,
    firstName,
    lastName,
    phoneNumber,
    companySize,
    jobTitle,
    helpDescription,
  }) =>
  async (dispatch) => {
    console.log('signupSetup')
    return Promise.resolve()
      .then(() => {
        //clear any previous error
        dispatch({
          type: SIGNUP_START,
        })

        return dispatch(
          notifyAdmin({
            subject: `Signup Enquiry`,
            body: `Email: ${email}<br />
Name: ${firstName} ${lastName}<br />
Phone: ${phoneNumber}<br />
Company Size: ${companySize}<br />
Job Title: ${jobTitle}<br />
Enquiry: ${helpDescription}`,
          })
        ).then(async (result) => {
          //login in silently so we can manage the users transition when they return
          dispatch({
            type: SIGNUP_COMPLETE,
          })

          return result
        })
      })
      .catch((err) => {
        console.error(err)
        dispatch(signupError(err.message || err))

        return false
      })
  }

export const signupComplete =
  (corporationId, setupSessionId) => async (dispatch) => {
    //clear any previous error
    dispatch(signupError(null))
    //call firebase and get it to create a user
    const data = {
      setupSessionId,
    }
  }

export const signupRetryPayment =
  (corporationId, urlCancel) => async (dispatch) => {
    return dispatch(setupCorporationSubscription(corporationId))
  }

const signupError = (error) => ({
  type: SIGNUP_ERROR,
  payload: error,
})

import React, { Fragment, useState, useRef } from 'react'

import { filter, get, join, size } from 'lodash-es'

import './Uploader.scss'
import { FormatClassNames } from '../../utils/utils.classnames'

const Uploader = ({ className, children, onChange }) => {
  const refInput = useRef()
  const [files, setFiles] = useState([])

  const props = {
    className: FormatClassNames(className, 'uploader'),
  }

  const onUpload = (evt) => {
    console.log('onUpload')
    const files = get(evt, 'target.files')

    if (size(files) > 0) {
      onChange(files)
      //clear the state
      setFiles([])
    }
  }

  const onStartUpload = () => {
    console.log('onStartUpload')
    refInput.current.click()
  }

  return (
    <Fragment>
      <div {...props} onClick={onStartUpload}>
        {children}
      </div>
      <input
        ref={refInput}
        value={files}
        type="file"
        accept=".csv,.xlsx,.xls"
        className="fileUpload"
        onChange={onUpload}
      />
    </Fragment>
  )
}

Uploader.propTypes = {}

export default Uploader

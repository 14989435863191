import React, { useEffect, useState } from 'react'
import './Dropdown.scss'
import arrow from '../../assets/images/down-arrow.svg'
import { useHistory } from 'react-router-dom'

const Dropdown = ({ options, isSelfService }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(options[0])
  const history = useHistory()

  const handleOptionClick = (option) => {
    setSelectedOption(option)
    history.push(option.url)
    setIsOpen(false)
  }

  useEffect(() => {
    const currentOption = options.find(
      (option) => option.url === history.location.pathname
    )
    if (currentOption) {
      setSelectedOption(currentOption)
    }
  }, [history.location.pathname, options])
  const filteredMenuItems = options.filter((item) => {
    return isSelfService || item.title.toLowerCase() !== 'invoices'
  })

  return (
    <div className="custom-dropdown">
      <div
        className="selected-option body-2"
        onClick={() => setIsOpen(!isOpen)}
      >
        {selectedOption.title || 'Select an option'}
      </div>
      <img src={arrow} alt="" className={`${isOpen ? 'rotated' : ''} arrow`} />
      {isOpen && (
        <div className="options">
          {filteredMenuItems.map((option) => {
            return (
              <div
                key={option.title}
                className="option-item body-2"
                onClick={() => handleOptionClick(option)}
              >
                {option.title}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default Dropdown

import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { get, map } from 'lodash-es'
import moment from 'moment'

// components
import Dashboard from '../layout/DashBoard'

// styles

// assets
import downloadIcon from '../../assets/images/download-icon.svg'
import leftArrow from '../../assets/images/Long Arrow.png'
import icoArrowRight from '../../assets/images/icoArrowRight.svg'
import Logo from '../../assets/images/reskin/img-reskin_logo.svg'
import './AdminInvoices.scss'
import logoutSvg from '../../assets/images/logout.svg'
import { logout } from '../../actions/auth'

import ButtonIcon from '../common/ButtonIcon'
import AdminHeader from './AdminHeader'
import {
  ROUTE_HOME,
  ROUTE_DASHBOARD,
  ROUTE_DASHBOARD_EDIT_PLAN,
  ROUTE_DASHBOARD_INSIGHTS,
  ROUTE_DASHBOARD_INVOICES
} from '../../routes'
import AdminStatusBlocker from './AdminStatusBlocker'
import Dropdown from '../common/Dropdown'

import { loadCorporationInvoices } from '../../actions/corporation'
import { formatPrice } from '../../utils/utils.format'
import BackButton from '../common/BackButton'
import { ROUTES } from './AdminRoutes'
import { createRoutePath } from '../../utils/utils.routes'

// fontawesome.library.add(faCheckSquare, faCoffee);
const AdminInvoices = ({
  corporationId,
  companyName,
  invoices,
  loadCorporationInvoices,
  routes,
  isSelfService,
  logout
}) => {
  const [hasLoaded, setHasLoaded] = useState(false)

  useEffect(() => {
    if (corporationId && !hasLoaded) {
      setHasLoaded(true)
      loadCorporationInvoices(corporationId)
    }
  }, [corporationId])

  // const tempInvoices = [
  //   { id: 1, date: "18 November 2020", amount: `£${(9.99 * corporation.numberOfUsers).toFixed(2)}` },
  // ];
  const menuItems = [
    {
      title: 'account',
      url: ROUTE_DASHBOARD,
    },
    {
      title: 'manage account',
      url: ROUTE_DASHBOARD_EDIT_PLAN,
    },
    {
      title: 'insights',
      url: ROUTE_DASHBOARD_INSIGHTS,
    },
    {
      title: 'invoices',
      url: ROUTE_DASHBOARD_INVOICES,
    },
  ]
  return (
    <AdminStatusBlocker className="admin admin-invoices wrapper-admin">
      <div className="header">
        <Link to={ROUTE_HOME}>
          <img src={Logo} alt="Deliciously Ella Logo" width={140} height={48} />
          <img src={logoutSvg} onClick={logout} alt='logout' className='body-2 logout-text' />

        </Link>
      </div>
      <div className="mobile-selector">
        <Dropdown options={menuItems} isSelfService={isSelfService} />
      </div>
      <div className="row">

        <div className="column-menu">

          <div className="link action">
            <BackButton
              to={createRoutePath(ROUTE_DASHBOARD, { corporationId })}
              className="dash-nav"
            >
              back to account
            </BackButton>
          </div>
        </div>
        <div className="column-main">
          {/* <div className="body-frame"> */}
          <AdminHeader currentRoute={'invoices'} routes={routes} />
          <div className="invoices">
            <h2>invoices</h2>

            <table>
              <thead>
                <tr>
                  <th>
                    <h5>date</h5>
                  </th>
                  <th>
                    <h5>amount</h5>
                  </th>
                  <th>
                    <h5></h5>
                  </th>
                </tr>
              </thead>
              <tbody>
                {map(invoices, (invoice, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <p>
                          {moment(get(invoice, 'created') * 1000).format(
                            'DD/MM/YYYY'
                          )}
                        </p>
                      </td>
                      <td>
                        <p>{formatPrice(invoice.amount, invoice.currency)}</p>
                      </td>
                      <td>
                        <ButtonIcon
                          src={downloadIcon}
                          alt="download"
                          target="_blank"
                          href={invoice.receipt_url}
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          {/* </div> */}
        </div>
      </div>
    </AdminStatusBlocker>
  )
}

// Need to connect component to Redux

const mapStateToProps = (state) => ({
  corporationId: get(state, 'corporation.data.id'),
  companyName: get(state, 'corporation.data.companyName'),
  invoices: get(state, 'corporation.invoices'),
  routes: ROUTES(state),
  isSelfService:
    get(state, 'corporation.data.type') === 'selfService' ? true : false,
})

export default connect(mapStateToProps, {
  loadCorporationInvoices,
  logout,

})(AdminInvoices)

import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { parseCsvEmailList } from '../../utils/parser'
import Button from '../common/Button'
import Input from '../common/Input'
import Uploader from '../common/Uploader'

import PropTypes from 'prop-types'
import Modal from '../common/Modal'
import TableList from '../common/TableList'

import {
  find,
  first,
  filter,
  get,
  isEmpty,
  pick,
  size,
  map,
  merge,
  slice,
  orderBy,
  indexOf,
} from 'lodash-es'
import { isEmailValid } from '../../utils/utils.validator'
import { createSuperAdmin } from '../../actions/superadmin'

import { formatEmail } from '../../utils/utils.format'

import { FlexRow, FlexSpacer } from '../common/Flex'
import ErrorMessage from '../common/ErrorMessage'
import LoadingMask from '../common/LoadingMask'

import './SuperAdminInviteUser.scss'
import { copyTextToClipboard } from '../../utils/util.clipboard'
import icoClose from '../../assets/images/icoClose.png'

const SuperAdminInviteUser = ({ createSuperAdmin, onClose }) => {
  const [error, setError] = useState('')
  const [inviteUserEmail, setInviteUserEmail] = useState('')
  const [processing, setProcessing] = useState(false)

  const onInviteUser = () => {
    setProcessing(true)
    //action
    createSuperAdmin({ email: inviteUserEmail })
      .then(onClose)
      .catch((err) => {
        //do nothing for now
        console.error(err)
        setError(err.message)
        setProcessing(false)
      })
  }

  return (
    <Modal className="invite-members size-medium" onClose={onClose}>
      {processing ? (
        <LoadingMask style={{ height: 200 }} enabled={true}></LoadingMask>
      ) : (
        <Fragment>
          <FlexRow className="modal-header">
            <h5>invite super admin</h5>
            <button onClick={onClose} className="close">
              <img src={icoClose} alt="close" width={22} height={22} />
            </button>
          </FlexRow>

          {error && <ErrorMessage>{error}</ErrorMessage>}
          <div className="invite-single-member super-admin-popup">
            <p>Email:</p>
            <Input
              value={inviteUserEmail}
              onChange={setInviteUserEmail}
              className="email-input"
            />
            <Button
              className="invite-single-member-button"
              disabled={!isEmailValid(inviteUserEmail)}
              onClick={onInviteUser}
            >
              Invite
            </Button>
          </div>
        </Fragment>
      )}
    </Modal>
  )
}

SuperAdminInviteUser.propTypes = {
  onClose: PropTypes.func.isRequired,
}

SuperAdminInviteUser.defaultProps = {}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, {
  createSuperAdmin,
})(SuperAdminInviteUser)

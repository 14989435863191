import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { parseCsvEmailList } from '../../utils/parser'
import Button from '../common/Button'
import Input from '../common/Input'
import Uploader from '../common/Uploader'

import PropTypes from 'prop-types'
import Modal from '../common/Modal'

import {
  every,
  find,
  first,
  filter,
  get,
  isEmpty,
  pick,
  size,
  map,
  merge,
  slice,
  orderBy,
  indexOf,
} from 'lodash-es'

import { createCorporation } from '../../actions/corporation'

import { FlexRow, FlexSpacer } from '../common/Flex'

import './AdminCreateCorporation.scss'
import ErrorMessage from '../common/ErrorMessage'
import { signupGetInfo, signupWake } from '../../actions/signup'
import InputSelect from '../common/InputSelect'
import InputNumber from '../common/InputNumber'
import LoadingMask from '../common/LoadingMask'

import icoClose from '../../assets/images/icoClose.png'

const AdminCreateCorporation = ({
  createCorporation,
  corporation,
  show,
  onClose,
  signupWake,
  signupGetInfo,
  countries,
}) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const [email, setEmail] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [teamName, setTeamName] = useState('')
  const [country, setCountry] = useState('GBR')
  const [listOfCountries, setListOfCountries] = useState([])
  const [numberOfUsers, setNumberOfUsers] = useState(10)
  const [activationLimit, setActivationLimit] = useState(0)

  // const [listOfCountries, setListOfCountries] = useState([]);
  const isFormComplete = every([email, companyName, teamName, country])

  const countrySelected =
    country && size(listOfCountries)
      ? find(listOfCountries, { code3: country })
      : null

  console.log(country, countrySelected)

  useEffect(() => {
    signupWake()
    signupGetInfo()
  }, [])

  useEffect(() => {
    //THE LIST OF COUNTRIES NEEDS FORMATTING
    const listOfCountries = map(countries, (country) => {
      return {
        ...country,
        label: country.name,
        value: country.code3,
      }
    })

    setListOfCountries(listOfCountries)
    // setCountry( first( listOfCountries ) );
  }, [countries])

  const onSubmit = () => {
    if (!loading) {
      setLoading(true)

      createCorporation({
        type: 'enterprise',
        email,
        numberOfUsers,
        activationLimit,
        companyName,
        teamName,
        country,
      })
        .then(() => {
          onClose()
        })
        .catch((err) => {
          setError(err.message)
        })
        .then(() => {
          setLoading(false)
        })
    }
  }

  return (
    <Modal className="create-admin" size="medium" show={show}>
      <LoadingMask enabled={loading}>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {onClose && (
          <FlexRow className="close">
            <FlexSpacer />
            <img
              src={icoClose}
              alt="close"
              onClick={onClose}
              width={22}
              height={22}
            />
          </FlexRow>
        )}
        <h4>create new team</h4>
        <p className='input-text'>Email *</p>
        <Input
          // inverted
          autoFocus
          value={email}
          onChange={setEmail}
          fullWidth
          autoComplete="given-name"
        />
        <p className='input-text'>Company Name *</p>
        <Input
          // inverted
          value={companyName}
          onChange={setCompanyName}
          fullWidth
          autoComplete="companyName"
        />
        <p className='input-text'>Team Name *</p>
        <Input
          // inverted
          value={teamName}
          onChange={setTeamName}
          fullWidth
          autoComplete="teamName"
        />

        <InputSelect
          // inverted
          required
          label="country"
          value={country}
          options={listOfCountries}
          onChange={setCountry}
          fullWidth
          autoComplete="country"
        />

        <br />

        <FlexRow className="members-container">
          <InputNumber
            min={2}
            value={numberOfUsers}
            onChange={setNumberOfUsers}
            className="input-number"
          />
          <div className="member-calc">
            <span>
              <b>members</b>
            </span>
          </div>
        </FlexRow>

        <FlexRow className="members-container">
          <InputNumber
            min={0}
            value={activationLimit}
            onChange={setActivationLimit}
            className="input-number"
          />
          <div className="member-calc">
            <span>
              <b>activation limit</b>
            </span>
          </div>
        </FlexRow>

        <FlexRow>
          <FlexSpacer />
          <Button
            disabled={!isFormComplete}
            onClick={onSubmit}
            className="create-button"
          >
            create
          </Button>
        </FlexRow>
      </LoadingMask>
    </Modal>
  )
}

AdminCreateCorporation.propTypes = {
  onClose: PropTypes.func,
  show: PropTypes.bool,
}

AdminCreateCorporation.defaultProps = {
  show: true,
}

const mapStateToProps = (state) => ({
  countries: get(state, 'signup.info.countries'),
})

export default connect(mapStateToProps, {
  createCorporation,
  signupWake,
  signupGetInfo,
})(AdminCreateCorporation)

import {
  INVITES_UPLOAD_STARTED,
  INVITES_UPLOAD_PROGRESS,
  INVITES_UPLOAD_INCOMPLETE,
  INVITES_UPLOAD_COMPLETED,
} from '../actions/types'

const initialState = {
  uploading: false,
  incomplete: false,
  progress: 0,
}

export default function (state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case INVITES_UPLOAD_STARTED:
      return {
        ...state,
        uploading: true,
      }
    case INVITES_UPLOAD_PROGRESS:
      return {
        ...state,
        progress: payload,
      }
    case INVITES_UPLOAD_INCOMPLETE:
      return {
        ...state,
        incomplete: true,
      }
    case INVITES_UPLOAD_COMPLETED:
      return {
        ...state,
        uploading: false,
        incomplete: false,
        progress: 1,
      }
    default:
      return state
  }
}

import React, { useEffect, useState } from 'react'
import { ROUTE_DASHBOARD, ROUTE_HOME } from '../../routes'

import logo from '../../assets/images/DE-Logo.png'
import { Link } from 'react-router-dom'

import { map } from 'lodash-es'

import './DashBoard.scss'

const Dashboard = ({ children }) => {
  return (
    <div className="dashboard">
      <Header />
      <div className="dashboard-content">{children}</div>
    </div>
  )
}

const Header = () => {
  return (
    <div className="dashboard-header">
      <Link to={ROUTE_HOME}>
        <img src={logo} alt="Logo" width="78px" />
      </Link>
      <nav>
        <ul>
          {map(
            [
              { name: 'Accounts', link: ROUTE_DASHBOARD },
              { name: 'Insights', link: ROUTE_DASHBOARD },
            ],
            (item, index) => {
              return (
                <li key={index}>
                  <Link to={item.link}>{item.label}</Link>
                </li>
              )
            }
          )}
        </ul>
      </nav>
    </div>
  )
}

Dashboard.Body = ({ children }) => (
  <div className="dashboard-body">{children}</div>
)

Dashboard.Menu = ({ children }) => (
  <div className="dashboard-menu">{children}</div>
)

const mapStateToProps = (state) => ({})

// export default Nav;
export default Dashboard

import * as XLSX from 'xlsx'

import {
  map,
  each,
  camelCase,
  keys,
  find,
  every,
  includes,
  first,
  size,
  concat,
  trim,
} from 'lodash-es'
import validator from 'validator'
import { formatEmail } from './utils.format'

const formatHeader = (id) => {
  return (id = trim((id || '').toLowerCase()))
}

const formatCsvItem = (item) => {
  const output = {}
  each(item, (value, id) => {
    id = formatHeader(id)
    output[id] = value
  })
  return output
}

export const parseCsvEmailList = (file) => {
  return parseCsvFile(file, { formatHeaders: false })
    .then((items) => {
      //did we get headers with email/name
      if (
        every(items, (item) => includes(map(keys(item), formatHeader), 'email'))
      ) {
        //this has been correctly formatted
        return map(items, formatCsvItem)
      } else {
        const ids = keys(first(items))
        console.log(ids)
        //check if one of the headers looks like an email address
        const email = find(ids, (key) => validator.isEmail(key))
        if (email) {
          if (size(ids) > 2) {
            throw new Error(
              'CSV file is missing headers and purpose of all columns can not be determined'
            )
          } else {
            const name = find(ids, (key) => key != email)
            //add the first item and re-adjust all the keys
            return concat(
              [
                {
                  name,
                  email,
                },
              ],
              map(items, (item) => {
                const output = {}
                each(item, (value, id) => {
                  output[
                    {
                      [email]: 'email',
                      [name]: 'name',
                    }[id]
                  ] = value
                })
                return output
              })
            )
          }
          //reformat
          alert(
            'no headers in email - looks to contain only email addreesses - should we continue'
          )
        } else {
          throw new Error('Malformed CSV')
        }
      }
    })
    .then((result) => {
      return map(result, (item) => {
        item.email = formatEmail(item.email)
        return item
      })
    })
}

export const parseCsvFile = (file, { formatHeaders = true } = {}) => {
  return readFile(file).then((data) => {
    /* Parse data */
    const wb = XLSX.read(data, { type: 'binary' })
    /* Get first worksheet */
    const wsname = wb.SheetNames[0]
    const ws = wb.Sheets[wsname]

    /* Convert array of arrays */
    return map(XLSX.utils.sheet_to_json(ws), (item) => {
      if (formatHeaders) {
        //format using ids
        return formatCsvItem(item)
      } else {
        return item
      }
    })
  })
}

export const readFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (evt) => {
      resolve(evt.target.result)
    }
    reader.readAsBinaryString(file)
  })
}

import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { get } from 'lodash-es'

import logo from '../../assets/images/logo.svg' // relative path to image
import { signupRetryPayment } from '../../actions/signup'

import Button from '../common/Button'
import { FlexColumn } from '../common/Flex'

const PaymentSuccess = ({ error, signupRetryPayment, disabled }) => {
  const [setupSessionId, setSetupSessionId] = useState('')
  const [corporationId, setCorporationId] = useState('')

  //run once only
  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const setupSessionId = params.get('session_id')
    const corporationId = params.get('corporationId')
    // retrieve this session
    setSetupSessionId(setupSessionId)
    setCorporationId(corporationId)
  }, [])

  const onRetryPayment = () => {
    signupRetryPayment(corporationId)
  }

  return (
    <FlexColumn className="fill-page" align="center" justify="center">
      <div className="center" style={{ maxWidth: '400px' }}>
        <div>
          <img src={logo} alt="logo" />
        </div>
        <div className="mt-2 mb-2">
          <p>We could not complete the setup of your payment details</p>
        </div>
        <div style={{ width: 200, margin: '0px auto' }}>
          <Button
            color="secondary"
            disabled={!corporationId}
            onClick={onRetryPayment}
          >
            Try again
          </Button>
        </div>
      </div>
    </FlexColumn>
  )
}

PaymentSuccess.propTypes = {
  disabled: PropTypes.bool,
}

PaymentSuccess.defaultProps = {
  disabled: true,
}

const mapStateToProps = (state) => ({
  error: get(state, 'signup.error'),
})

export default connect(mapStateToProps, {
  signupRetryPayment,
})(PaymentSuccess)

// export default connect()(Payment);

import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, Route, Switch } from 'react-router-dom'

import { get, includes, map, size, filter } from 'lodash-es'

import Button from '../common/Button'
import Input from '../common/Input'
import Dropdown from '../common/Dropdown'

import removeIcon from '../../assets/images/remove.svg'

import './SuperAdminUsers.scss'

import { loadSuperAdmins, removeSuperAdmin } from '../../actions/superadmin'
import ButtonIcon from '../common/ButtonIcon'
import SuperAdminInviteUser from './SuperAdminInviteUser'
import { ROUTE_DASHBOARD, ROUTE_SUPER_ADMIN_USERS } from '../../routes'

const toLowerCase = (val) => {
  return (val || '').toLowerCase()
}

const menuItems = [
  {
    title: 'dashboard',
    url: ROUTE_DASHBOARD,
  },
  {
    title: 'settings',
    url: ROUTE_SUPER_ADMIN_USERS,
  },
]

const SuperAdminUsers = ({
  superAdmins: allSuperAdmins,
  loadSuperAdmins,
  removeSuperAdmin,
  userEmail,
}) => {
  const [showInviteAdmin, setShowInviteAdmin] = useState(false)
  const [search, setSearch] = useState()

  const superAdmins =
    size(search) > 0
      ? filter(allSuperAdmins, (superAdmin) => {
          return includes(toLowerCase(get(superAdmin, 'email')), search)
        })
      : allSuperAdmins

  useEffect(() => {
    loadSuperAdmins()
  }, [])

  const onShowInviteAdmin = () => {
    setShowInviteAdmin(true)
  }

  const onHideInviteAdmin = () => {
    setShowInviteAdmin(false)
  }

  const onRemoveSuperAdmin = (superAdmin) => {
    if (get(superAdmin, 'email') === userEmail) {
      alert('You are not permitted to remove your own account')
    } else {
      if (
        window.confirm(
          `Are you sure you want to remove super admin access to '${superAdmin.email}'`
        )
      ) {
        removeSuperAdmin(superAdmin)
      }
    }
  }

  return (
    <div className="super-admin-users">
      <div className="mobile-selector">
        <Dropdown options={menuItems} />
      </div>
      <div className="super-admin-main">
        <h4>super admins</h4>
        <div className="actions">
          <Button className="invite-button" onClick={onShowInviteAdmin}>
            invite admin
          </Button>
        </div>

        <div className="d-flex search-list">
          <Input
            placeholder="search"
            value={search}
            onChange={setSearch}
            className="search-email"
          />
        </div>

        <table>
          <thead>
            <tr>
              <th>
                <h5>email</h5>
              </th>
              <th>
                <h5></h5>
              </th>
            </tr>
          </thead>
          <tbody>
            {map(superAdmins, (superAdmin, index) => {
              return (
                <tr key={index}>
                  <td>
                    <p>{get(superAdmin, 'email')}</p>
                  </td>
                  <td>
                    {/* DON'T ALLOW USER TO DELETE THERE OWN ACCOUNT */}
                    <ButtonIcon
                      src={removeIcon}
                      alt="remove"
                      onClick={() => onRemoveSuperAdmin(superAdmin)}
                    />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        {showInviteAdmin && (
          <SuperAdminInviteUser onClose={onHideInviteAdmin} />
        )}
      </div>
    </div>
  )
}

// Need to connect component to Redux

const mapStateToProps = (state) => ({
  superAdmins: get(state, 'superadmin.users'),
  userEmail: get(state, 'auth.userEmail'),
})

export default connect(mapStateToProps, {
  loadSuperAdmins,
  removeSuperAdmin,
})(SuperAdminUsers)

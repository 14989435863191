import {
  LOGIN_SUCCESS,
  AUTH_ERROR,
  USER_LOADED,
  LOGOUT,
  LOGIN_START,
  LOGIN_FAIL,
  SIGNUP_INVITE_LOADED,
  SIGNUP_INVITE_ERROR,
} from './types'
import { auth } from '../firebase'
import { loadAdmin } from './admin'

import { assertEmailValid } from '../utils/utils.validator'

import { functions } from '../firebase'
const corporateSelfService = functions.httpsCallable('corporateSelfService')

export const loadUser = () => async (dispatch) => {
  try {
    auth.onAuthStateChanged((user) => {
      if (user === null) {
        dispatch({
          type: AUTH_ERROR,
        })
      } else {
        const { uid } = user
        dispatch(loadAdmin(uid)).then(() => {
          dispatch({
            type: USER_LOADED,
            payload: user.email,
          })
        })
      }
    })
  } catch (error) {}
}

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({
      type: LOGIN_START,
    })

    const data = await auth.signInWithEmailAndPassword(email, password)

    const { uid } = data.user

    dispatch(loadAdmin(uid)).then(() => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: uid,
      })
    })
    //load additional information about the user
  } catch (error) {
    console.log('error-' + error)
    dispatch({
      type: LOGIN_FAIL,
      payload: error.message || error,
    })
  }
}

export const logout = () => async (dispatch) => {
  console.log('logout')
  auth.signOut()
  dispatch({ type: LOGOUT })
}

export const loadSignupInvite = (id) => async (dispatch) => {
  return corporateSelfService({
    action: 'get-invite-info',
    data: { id },
  })
    .then((result) => result.data)
    .then((data) => {
      console.log('result', data)
      const { status, result, error } = data
      switch (status) {
        case 'ok':
          //reload the existing corporations
          dispatch({
            type: SIGNUP_INVITE_LOADED,
            payload: result,
          })
          break
        default:
          dispatch({
            type: SIGNUP_INVITE_ERROR,
            payload: error || 'Signup invite unknown error',
          })
          break
      }
    })
}

export const completeSignupInvite = (id, password) => async (dispatch) => {
  return corporateSelfService({
    action: 'complete-invite-info',
    data: {
      id,
      password,
    },
  })
    .then((result) => result.data)
    .then((data) => {
      console.log('result', data)
      const { status, result, error } = data
      switch (status) {
        case 'ok':
          //reload the existing corporations
          dispatch(login(result.admin.email, password))
          break
        default:
          dispatch({
            type: SIGNUP_INVITE_ERROR,
            payload: error || 'Signup invite unknown error',
          })
          break
      }
    })
}

export const passwordReset = (email) => async (dispatch) => {
  assertEmailValid(email)
  return auth.sendPasswordResetEmail(email)
}

import {
  CORPORATION_INVOICES_UPDATED,
  CORPORATION_UNLOADED,
  CORPORATION_LOADED,
  CORPORATION_VOUCHERS_UPDATED,
  CORPORATION_SUBSCRIPTION_USAGE_UPDATED,
} from '../actions/types'

const initialState = {
  data: null,
  vouchers: [],
}

export default function (state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case CORPORATION_LOADED:
      return {
        ...state,
        data: payload,
        // id: payload.id,
        // status: payload.status,
        // admins: payload.admins,
        // email: payload.email,
        // payment: payload.payment,
        // companyName : payload.companyName,
        // country : payload.country,
        // defaultProductId : payload.defaultProductId,
        // numberOfUsers : payload.numberOfUsers,
        // numberOfUsersAtRenewal : payload.numberOfUsersAtRenewal || payload.numberOfUsers,
        // renewsAt : payload.renewsAt,
        // teamName : payload.teamName,
        // type : payload.type,
        // // vouchers : payload.vouchers ? payload.vouchers : state.vouchers,
        // settings : payload.settings
      }
    case CORPORATION_UNLOADED:
      return {
        ...state,
        data: null,
        vouchers: null,
        invoices: null,
        subscriptionUsage: null,
      }
      break
    case CORPORATION_VOUCHERS_UPDATED:
      return {
        ...state,
        vouchers: payload,
      }
    case CORPORATION_SUBSCRIPTION_USAGE_UPDATED:
      return {
        ...state,
        subscriptionUsage: payload,
      }
    case CORPORATION_INVOICES_UPDATED:
      return {
        ...state,
        invoices: payload,
      }
    default:
      return state
  }
}

import React from 'react'
import imgReskinLogo from '../../assets/images/reskin/img-reskin_logo.svg'
import './GetInTouch.scss'

const GetInTouch = () => {
  return (
    <div className="get-in-touch">
      <img src={imgReskinLogo} alt="" />
      <h1>Get in touch</h1>
      <p>
        Still have questions? Email us at{' '}
        <a href="mailto:teams@deliciouslyella.com">teams@deliciouslyella.com</a>
      </p>
    </div>
  )
}

export default GetInTouch

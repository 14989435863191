import React, { Fragment, useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  get,
  every,
  size,
  map,
  find,
  isArray,
  isEmpty,
  includes,
  upperCase,
  some,
} from 'lodash-es'

// actions
import {
  signupSetup,
  signupWake,
  signupGetInfo,
  signupEnquiry,
} from '../../actions/signup'

// components
import LoginRedirect from '../auth/LoginRedirect'

import Button from '../common/Button'
import Input from '../common/Input'
import { FlexColumn, FlexRow } from '../common/Flex'
import InputNumber from '../common/InputNumber'
import InputSelect from '../common/InputSelect'
import ErrorMessage from '../common/ErrorMessage'
import InputCheckbox from '../common/InputCheckbox'
import LoadingMask from '../common/LoadingMask'

import Banner from './Banner'
import Summary from './Summary'
import HowItWorks from './HowItWorks'
import GetInTouch from './GetInTouch'
import Reviews from './Reviews'

// styles
import './Signup.scss'

// assets
import cardIcon from '../../assets/images/card2x.png'

// utils
import { getCurrencySymbol } from '../../utils/utils.currencies'
import { isEmailValid } from '../../utils/utils.validator'

// const IS_TEST = window.location.hostname === "localhost" ? true : false;
const DEFAULT_NUMBER_USERS = 5
const dataLayer = window['dataLayer']

const SIGNUP_METHOD_SMALL = 'small'
const SIGNUP_METHOD_LARGE = 'large'

const Signup = ({
  error,
  loading,
  signupSetup,
  signupWake,
  signupGetInfo,
  signupEnquiry,
  disabled,
  countryByIpAddress,
  countries,
  products,
}) => {
  // const history = useHistory();

  const [isInitialisingSetup, setIsInitialisingSetup] = useState(false)
  const [signupMethod, setSignupMethod] = useState('')

  const [signupCompletionInfo, setSignupCompletionInfo] = useState('')

  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [companySize, setCompanySize] = useState('')
  const [jobTitle, setJobTitle] = useState('')
  const [helpDescription, setHelpDescription] = useState('')
  const [password, setPassword] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [teamName, setTeamName] = useState('')
  const [country, setCountry] = useState('')
  const [listOfCountries, setListOfCountries] = useState([])
  const [numberOfUsers, setNumberOfUsers] = useState(DEFAULT_NUMBER_USERS)
  const [activationLimit, setActivationLimit] = useState(0)
  const [hasAgreedTerms, setHasAgreedTerms] = useState(false)
  const [hasInteracted, setHasInteracted] = useState(false)
  const [newsletterName, setNewsletterName] = useState('')
  const [newsletterEmail, setNewsletterEmail] = useState('')

  // For debugging dispatch actions
  // const state1 = useSelector(state => state);

  // useEffect(() => {
  //   console.log('State updated at:', new Date().toISOString(), 'State:', state1);
  // }, [state1]);

  //get the service ready when we first launch sign up
  useEffect(() => {
    signupWake()
    signupGetInfo()
  }, [])

  useEffect(() => {
    //check for any data change
    if (
      !hasInteracted &&
      some([
        !isEmpty(email),
        !isEmpty(password),
        !isEmpty(companyName),
        !isEmpty(teamName),
        numberOfUsers !== DEFAULT_NUMBER_USERS,
      ])
    ) {
      //if any data changed then record an interaction has occurred with the user
      setHasInteracted(true)
      //push the dataLayer
      dataLayer.push({
        event: 'formInteraction',
        Category: 'Workplace Form Initiated',
      })
    }
  }, [email, password, companyName, teamName, numberOfUsers])

  useEffect(() => {
    //THE LIST OF COUNTRIES NEEDS FORMATTING
    setListOfCountries(
      map(countries, (country) => {
        return {
          ...country,
          label: country.name,
          value: country.code3,
        }
      })
    )
  }, [countries])

  useEffect(() => {
    //THE LIST OF COUNTRIES NEEDS FORMATTING
    if (countryByIpAddress && size(countries)) {
      //set the users location
      var country = find(countries, { code3: countryByIpAddress })
      if (country) {
        setCountry(country.code3)
      }
    }
  }, [countries, countryByIpAddress])

  useEffect(() => {
    onSelectSignupSmall()
  }, [])

  const onSubmitForm = () => {
    setIsInitialisingSetup(true)

    signupSetup({
      email,
      password,
      companyName,
      teamName,
      numberOfUsers,
      activationLimit,
      country,
      product: productSelected,
    }).catch((err) => {
      return false
    })

    //record the event
    dataLayer.push({
      event: 'formSubmission',
      Category: 'Workplace Form Completion',
      Action: upperCase(currency),
      Label: numberOfUsers.toString(),
      Value: Number((numberOfUsers * price).toFixed(2)),
    })
  }

  const onSubmitEmail = () => {
    setIsInitialisingSetup(true)

    signupEnquiry({
      email,
      firstName,
      lastName,
      phoneNumber,
      companySize,
      jobTitle,
      helpDescription,
    })
      .catch((err) => {
        return false
      })
      .then((success) => {
        if (success) {
          setSignupCompletionInfo('Thank you - we will be contact shortly')
        }
        setIsInitialisingSetup(false)
      })

    //record the event
    // dataLayer.push({
    //   'event': 'formSubmission',
    //   'Category': 'Workplace Form Completion',
    //   'Action': upperCase( currency ),
    //   'Label': numberOfUsers.toString(),
    //   'Value': Number( (numberOfUsers * price).toFixed(2) )
    // });
  }

  const onNewsletterSignup = () => {}

  const isFormComplete = every([
    isEmailValid(email),
    password,
    companyName,
    teamName,
    hasAgreedTerms,
    numberOfUsers,
  ])

  const isEmailComplete = every([
    firstName,
    lastName,
    isEmailValid(email),
    companySize,
    jobTitle,
    helpDescription,
  ])

  const newletterFormComplete = every([newsletterName, newsletterEmail])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  //determine which country is selected based on the 'country' code
  const countrySelected =
    country && size(listOfCountries)
      ? find(listOfCountries, { code3: country })
      : null

  //find the product based on the country selected
  const productSelected = countrySelected
    ? find(products, (product) => {
        return every(product.filters, (value, id) => {
          if (isArray(value)) {
            //is the value one of many
            return includes(value, countrySelected[id]) ? true : false
          } else {
            return value === countrySelected[id] ? true : false
          }
        })
      })
    : null

  const { price, currency, currencySymbol, interval } = (function () {
    if (!productSelected) return {} //return an object that we can deconstruct

    const taxRates = get(productSelected, 'data.taxRates')
    const price = Number(get(productSelected, 'data.price.unit_amount')) / 100
    const currency = get(productSelected, 'data.price.currency')
    const interval = get(productSelected, 'data.price.recurring.interval')
    const currencySymbol = getCurrencySymbol(currency)

    return {
      price,
      currency,
      currencySymbol,
      interval,
      taxRates,
    }
  })()

  const onSelectSignupSmall = () => {
    setSignupCompletionInfo('')
    setSignupMethod(SIGNUP_METHOD_SMALL)
  }

  const onSelectSignupLarge = () => {
    setSignupCompletionInfo('')
    setSignupMethod(SIGNUP_METHOD_LARGE)
  }

  const formatPrice = (price) => {
    const isDefined = (v) => v !== undefined && v !== null
    if (isDefined(price)) {
      return price
    } else {
      return null
    }
  }
  const formattedPrice = formatPrice(price)

  return (
    <Fragment>
      <LoginRedirect disabled={disabled} ignoreStates={['signup']} />
      {/* <p>Signup page</p>
			<input type='text' value={email} onChange={ evt => setEmail( evt.target.value ) } placeholder='Email' />
			<input type='password' value={password} onChange={ evt => setPassword( evt.target.value ) } placeholder='Password' />
			<input type='text' value={companyName} onChange={ evt => setCompanyName( evt.target.value ) } placeholder='Compnay Name' />
			<input type='text' value={teamName} onChange={ evt => setTeamName( evt.target.value ) } placeholder='Team Name' />
			<button onClick={onSignup}>Process to card payment</button> */}

      <div style={{ backgroundColor: 'white' }}>
        <div className="signup wrap-long-words">
          <FlexRow className="wrapper-signup justify-end head-text">
            <p>
              already have an account? <Link to="/login">login</Link>
            </p>
          </FlexRow>

          <video
            hidden={true}
            src="https://delella.s3.eu-west-2.amazonaws.com/the+deliciously+ella+app+for+your+team.mp4"
            type="video/mp4"
            autoPlay
            muted
            loop
            playsInline
          ></video>

          <Summary />
          <HowItWorks />

          <div className="signup-form-container">
            {/* <div className="signup-form"> */}
            <LoadingMask enabled={loading} className="column column-right">
              {error && <ErrorMessage key={error}>{error}</ErrorMessage>}
              <FlexColumn>
                <h2 className="get_started">Get Started</h2>
                <div className="options">
                  <div
                    className="option"
                    data-selected={
                      signupMethod !== SIGNUP_METHOD_LARGE ? 'true' : 'false'
                    }
                    onClick={onSelectSignupSmall}
                  >
                    <h2>Smaller Teams</h2>
                    <h3>
                      <span>&lt; 100 employees</span>
                    </h3>
                    <ul>
                      <li>instant account set up and access</li>
                      <li>quick, secure payment via credit card</li>
                      <li>flexibility to add additional team members</li>
                      <li>troubleshooting support</li>
                      <li>internal marketing material available on request</li>
                    </ul>
                  </div>
                  <h2>or</h2>
                  <div
                    className="option"
                    data-selected={
                      signupMethod !== SIGNUP_METHOD_SMALL ? 'true' : 'false'
                    }
                    onClick={onSelectSignupLarge}
                  >
                    <h2>Larger Teams</h2>
                    <h3>
                      <span>101+ employees</span>
                    </h3>
                    <ul>
                      <li>dedicated account management</li>
                      <li>marketing and team engagement support</li>
                      <li>invoiced payments</li>
                      <li>flexibility to add additional team members</li>
                      <li>troubleshooting support</li>
                    </ul>
                  </div>
                </div>

                {signupMethod == SIGNUP_METHOD_SMALL && (
                  <div className="input-area">
                    <Input
                      required
                      value={email}
                      onChange={setEmail}
                      fullWidth
                      autoComplete="given-name"
                      placeholder="email"
                      type="email"
                    />
                    <Input
                      type="password"
                      required
                      value={password}
                      onChange={setPassword}
                      fullWidth
                      placeholder="password"
                    />
                    <Input
                      required
                      value={companyName}
                      onChange={setCompanyName}
                      fullWidth
                      autoComplete="companyName"
                      placeholder="company name"
                    />
                    <Input
                      required
                      value={teamName}
                      onChange={setTeamName}
                      fullWidth
                      autoComplete="teamName"
                      placeholder="team name"
                    />

                    <InputSelect
                      required
                      value={country}
                      options={listOfCountries}
                      onChange={setCountry}
                      fullWidth
                      autoComplete="country"
                    />

                    <div class="members-container" flex direction="row">
                      <InputNumber
                        min={2}
                        value={numberOfUsers}
                        onChange={setNumberOfUsers}
                      />
                      <div className="members-text">
                        <div className="member-calc">
                          <span>
                            members x {currencySymbol}
                            {price}&nbsp; = &nbsp;{' '}
                          </span>
                        </div>
                        <div className="total-cost">
                          <label id="signup_price">
                            {' '}
                            {currencySymbol}
                            {isNaN((numberOfUsers * price).toFixed(2))
                              ? null
                              : (numberOfUsers * price).toFixed(2)}
                          </label>{' '}
                          <span> &nbsp; per {interval}</span>
                        </div>
                      </div>
                    </div>

                    <FlexRow align="center" className="mt-1 mb-1">
                      <InputCheckbox
                        required
                        value={hasAgreedTerms}
                        onChange={setHasAgreedTerms}
                      >
                        I have read and agree to the{' '}
                        <Link
                          className="terms"
                          target="_blank"
                          to={{
                            pathname: 'https://deliciouslyella.com/terms/',
                          }}
                        >
                          terms and conditions
                        </Link>{' '}
                        *
                      </InputCheckbox>
                    </FlexRow>

                    <Button
                      disabled={!isFormComplete}
                      className="mt-1"
                      color="primary"
                      // startIcon={<PaymentIcon />}
                      onClick={onSubmitForm}
                    >
                      <img src={cardIcon} /> proceed to card payment
                    </Button>
                  </div>
                )}

                {signupMethod == SIGNUP_METHOD_LARGE && (
                  <Fragment>
                    {signupCompletionInfo ? (
                      <p>{signupCompletionInfo}</p>
                    ) : (
                      <div className="input-area">
                        <div className="inputs">
                          <Input
                            required
                            label="First Name"
                            value={firstName}
                            onChange={setFirstName}
                            fullWidth
                            autoComplete="first-name"
                            placeholder="first name"
                          />
                          <Input
                            required
                            label="Last Name"
                            value={lastName}
                            onChange={setLastName}
                            fullWidth
                            autoComplete="last-name"
                            placeholder="last name"
                          />
                        </div>

                        <Input
                          required
                          label="Work Email"
                          value={email}
                          onChange={setEmail}
                          fullWidth
                          autoComplete="companyName"
                          placeholder="work email"
                        />

                        <Input
                          label="Phone Number (optional)"
                          value={phoneNumber}
                          onChange={setPhoneNumber}
                          fullWidth
                          autoComplete="phoneNumber"
                          placeholder="phone number (optional)"
                        />

                        <Input
                          required
                          label="Company Size"
                          value={companySize}
                          onChange={setCompanySize}
                          fullWidth
                          autoComplete="companySize"
                          placeholder="company size"
                        />

                        <Input
                          required
                          label="Job Title"
                          value={jobTitle}
                          onChange={setJobTitle}
                          fullWidth
                          autoComplete="jobTitle"
                          placeholder="job title"
                        />
                        <div className="help-description">
                          <textarea
                            required
                            id="helpDescription"
                            name="helpDescription"
                            value={helpDescription}
                            placeholder="How can we help you? *"
                            onChange={(evt) =>
                              setHelpDescription(evt.target.value)
                            }
                          ></textarea>
                        </div>

                        <Button
                          disabled={!isEmailComplete}
                          className="mt-1"
                          color="primary"
                          // startIcon={<PaymentIcon />}
                          onClick={onSubmitEmail}
                        >
                          Submit
                        </Button>
                      </div>
                    )}
                  </Fragment>
                )}

                {/* <Grid item xs={12} className="mb-3 text-center submitBtn-signup">
                </Grid> */}
              </FlexColumn>
            </LoadingMask>
            {/* </div> */}
          </div>

          <GetInTouch />

          <Reviews />

          <p className="foot-text">
            For help or more information please get in touch at{' '}
            <a href="mailto:teams@deliciouslyella.com">
              teams@deliciouslyella.com
            </a>
          </p>

          <div className="newsletter">
            <h1>sign up to our newsletter</h1>
            <div className="newsletter-form">
              <Input
                inverted
                label="Your Name"
                value={newsletterName}
                onChange={setNewsletterName}
                fullWidth
              />
              <Input
                inverted
                label="Your Email Address"
                value={newsletterEmail}
                onChange={setNewsletterEmail}
                fullWidth
              />
              <button
                disabled={!newletterFormComplete}
                color="secondary"
                onClick={onNewsletterSignup}
              >
                sign up
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

Signup.propTypes = {
  disabled: PropTypes.bool,
  countries: PropTypes.any,
}

Signup.defaultProps = {
  disabled: false,
  countries: [],
}

const mapStateToProps = (state) => ({
  error: get(state, 'signup.error'),
  loading: get(state, 'signup.loading'),
  countryByIpAddress: get(state, 'signup.location.country_code_iso3'),
  countries: get(state, 'signup.info.countries'),
  products: get(state, 'signup.info.products'),
})

export default connect(mapStateToProps, {
  signupSetup,
  signupWake,
  signupGetInfo,
  signupEnquiry,
})(Signup)

// export default connect()(Signup);

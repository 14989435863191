import React from 'react'

import { filter, join, capitalize } from 'lodash-es'

import PropTypes from 'prop-types'

import './Flex.scss'

const Flex = ({
  className,
  style,
  children,
  direction,
  align,
  justify,
  autoSize,
}) => {
  const props = {
    className: join(
      filter([
        className,
        'flex',
        align ? `align-${align}` : null,
        justify ? `justify-${justify}` : null,
        direction ? `direction-${direction}` : null,
        autoSize ? 'size-auto' : null,
      ]),
      ' '
    ),
    style,
    children,
  }

  return <div {...props} />
}

Flex.propTypes = {
  direction: PropTypes.string,
  align: PropTypes.string,
  justify: PropTypes.string,
  autoSize: PropTypes.bool,
}

Flex.defaultProps = {}

export const FlexRow = (props) => {
  return <Flex {...props} direction="row" />
}

export const FlexColumn = (props) => {
  return <Flex {...props} direction="column" />
}

export const FlexSpacer = (props) => {
  return <div {...props} className="spacer" />
}

export default Flex

export const ROUTE_HOME = '/'
export const ROUTE_NEW_HOME = '/homepage'

export const ROUTE_SUPER_DASHBOARD = '/super-dashboard'

export const ROUTE_ADMIN = '/admin'
export const ROUTE_CORPORATION = `${ROUTE_ADMIN}/c/:corporationId`

export const ROUTE_DASHBOARD = `${ROUTE_CORPORATION}/dashboard`
export const ROUTE_DASHBOARD_USERS = `${ROUTE_DASHBOARD}/users`
export const ROUTE_DASHBOARD_INVOICES = `${ROUTE_DASHBOARD}/invoices`
export const ROUTE_DASHBOARD_INSIGHTS = `${ROUTE_DASHBOARD}/insights`
export const ROUTE_DASHBOARD_EDIT_PLAN = `${ROUTE_DASHBOARD}/edit-plan`
export const ROUTE_DASHBOARD_USAGE = `${ROUTE_DASHBOARD}/usage`
export const ROUTE_DASHBOARD_SUPER_ADMIN = `${ROUTE_DASHBOARD}/super-admin`

export const ROUTE_SUPER_ADMIN = `/super-admin`
export const ROUTE_SUPER_ADMIN_USERS = `${ROUTE_SUPER_ADMIN}/users`

export const ROUTE_SIGNUP = '/signup'

export const ROUTE_PAYMENT = `/payment`

export const ROUTE_PAYMENT_QUEUE = `${ROUTE_PAYMENT}/queue`
export const ROUTE_PAYMENT_SUCCESS = `${ROUTE_PAYMENT}/success`
export const ROUTE_PAYMENT_CANCEL = `${ROUTE_PAYMENT}/cancel`

export const ROUTE_LOGIN = '/login'
export const ROUTE_LOGOUT = '/logout'

export const ROUTE_SIGNUP_INVITE = '/signup-invite/:inviteid'

export const ROUTE_FORGOT_PASSWORD = '/forgot-password'

export const ROUTE_TERMS_AND_CONDITIONS = '/terms-and-conditions'

export const ROUTE_TEST = '/test'

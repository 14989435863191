import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { get } from 'lodash-es'

import logo from '../../assets/images/DE-Logo.png' // relative path to image
import { completeCorporationPayment } from '../../actions/corporation'
import { setWelcomeFlag } from '../../actions/admin'
import { useHistory } from 'react-router-dom'

import LoginRedirect from '../auth/LoginRedirect'
import { ROUTE_DASHBOARD } from '../../routes'
import LoadingMask from '../common/LoadingMask'
import ErrorMessage from '../common/ErrorMessage'
import { isLocalhost } from '../../utils/utils'

const PaymentSuccess = ({
  error,
  complete,
  corporationId,
  completeCorporationPayment,
  disabled,
  setWelcomeFlag
}) => {
  const history = useHistory()
  //run once only
  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const setupSessionId = params.get('session_id')
    if (!disabled && setupSessionId && corporationId) {
      // retrieve this session
      completeCorporationPayment(corporationId, {
        setupSessionId,
      })
        .then((result) => {
          setWelcomeFlag(true)
          if (isLocalhost()) {
            if (window.confirm('Return to dashboard')) {
              history.push(ROUTE_DASHBOARD)
            }
          } else {
            console.log('payment success')
            history.push(ROUTE_DASHBOARD)
          }
        })
        .catch((err) => {
          alert(`Error on payment success: "${err.message}"`)
        })
    }
  }, [disabled, completeCorporationPayment, corporationId])

  return (
    <LoadingMask className="fill-page" enabled={!complete}>
      {error && <ErrorMessage modal>{error}</ErrorMessage>}
    </LoadingMask>
  )
}

PaymentSuccess.propTypes = {
  disabled: PropTypes.bool,
}

PaymentSuccess.defaultProps = {
  disabled: false,
}

const mapStateToProps = (state) => ({
  complete: get(state, 'signup.complete'),
  error: get(state, 'signup.error'),
  corporationId: get(state, 'admin.corporations.0.id'),
})

export default connect(mapStateToProps, {
  completeCorporationPayment,
  setWelcomeFlag
})(PaymentSuccess)

// export default connect()(Payment);

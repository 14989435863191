import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { filter, join } from 'lodash-es'

import './ErrorMessage.scss'
import Modal from './Modal'
import { FormatClassNames } from '../../utils/utils.classnames'

const ErrorMessage = ({ className, as, modal, color, children }) => {
  const [hideMessage, setHideMessage] = useState(false)

  const ClassName = modal ? Modal : 'div'

  const props = {
    className: FormatClassNames(
      className,
      modal ? 'error-modal' : null,
      'error-message',
      color ? `color-${color}` : null
    ),
    onClose: modal ? () => setHideMessage(true) : null,
  }

  return hideMessage ? null : (
    <ClassName {...props}>
      <p className="message">{children}</p>
    </ClassName>
  )
}

ErrorMessage.propTypes = {
  modal: PropTypes.bool,
  color: PropTypes.string,
}

ErrorMessage.defaultProps = {
  modal: false,
}

export default ErrorMessage

import moment from 'moment'
import { isNumber } from './utils.validator'
import { get, isObject } from 'lodash-es'
import { currencies, getCurrencySymbol } from './utils.currencies'

const DATE_FORMAT = 'DD MMM YYYY'
export const parseDate = (date, defaultValue) => {
  if (isObject(date)) {
    if (isNumber(get(date, '_seconds'))) {
      date = get(date, '_seconds') * 1000
    }
  }

  return date
}
export const formatDate = (date, defaultValue) => {
  date = parseDate(date)
  return date ? moment(date).format(DATE_FORMAT) : defaultValue
}

export const formatPrice = (amount, currency) => {
  var value = (amount / 100).toFixed(2)
  return (currencies ? getCurrencySymbol(currency) : '') + value
}

export const formatEmail = (email) => {
  return (email || '').toLowerCase()
}

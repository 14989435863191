import { combineReducers } from 'redux'
import homepage from './homepage'
import auth from './auth'
import admin from './admin'
import superadmin from './superadmin'
import corporation from './corporation'
import signup from './signup'
import signupInvite from './signupInvite'
import invites from './invites'

export default combineReducers({
  homepage,
  auth,
  admin,
  superadmin,
  corporation,
  signup,
  signupInvite,
  invites,
})

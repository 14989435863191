import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/database'
import 'firebase/compat/functions'

import { omit } from 'lodash-es'

const { REACT_APP_FIREBASE_CONFIG, REACT_APP_ENABLE_EMULATORS } = process.env

// With this config, JSON.parse on line 28 should be removed

// const REACT_APP_FIREBASE_CONFIG = {
// 	apiKey: process.env.REACT_APP_APIKEY,
// 	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
// 	databaseURL: process.env.REACT_APP_DB_URL,
// 	projectId: process.env.REACT_APP_PROJECT_ID,
// 	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
// 	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
// 	appId: process.env.REACT_APP_APP_ID,
// 	measurementId: process.env.REACT_APP_MEASUREMENT_ID
// }

console.log('built using .env')
const config = REACT_APP_FIREBASE_CONFIG
  ? JSON.parse(REACT_APP_FIREBASE_CONFIG)
  : undefined
console.log('firebase:config', config ? true : false)

const app = config
  ? firebase.initializeApp(omit(config, 'messagingSenderId'))
  : firebase.initializeApp()

export const auth = app.auth()
export const db = app.firestore()
export const functions = app.functions()

if (REACT_APP_ENABLE_EMULATORS) {
  console.log('enabledEmulators', REACT_APP_ENABLE_EMULATORS)
  // Point to the RTDB emulator running on localhost.

  // auth.useEmulator("http://localhost:9599");
  // db.useEmulator("localhost", 8580);
  functions.useEmulator('localhost', 5501)
}

export default app

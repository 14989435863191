import React from 'react'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'

import './AdminHeader.scss'
import { get, map, filter } from 'lodash-es'
import { Link } from 'react-router-dom'
import { FormatClassNames } from '../../utils/utils.classnames'
import Button from '../common/Button'
import { createRoutePath } from '../../utils/utils.routes'
import { ROUTE_DASHBOARD_EDIT_PLAN } from '../../routes'
import { logout } from '../../actions/auth'

import LogoutRedirect from '../auth/LogoutRedirect'

const AdminHeader = ({
  routes,
  currentRoute,
  isSuper,
  disableLinks,
  corporationId,
  subscriptionPlan,
  isSelfService,
  corporation,
}) => {
  return (
    <div
      className={FormatClassNames(
        'admin-header',
        disableLinks ? 'disable-links' : null
      )}
    >
      <LogoutRedirect />

      <h1>Welcome to your account</h1>
      {/* TODO use dynamic name */}
      <div className="second-row">
        <h3 className="user-name">{corporation?.teamName || ''}</h3>
        {corporation?.status === 'ok' ? (
          <Button
            className="manage-btn"
            to={createRoutePath(ROUTE_DASHBOARD_EDIT_PLAN, { corporationId })}
          >
            manage{' '}
            {isSelfService && subscriptionPlan ? 'subscriptions' : 'account'}
          </Button>
        ) : (
          ''
        )}
      </div>
      {/* <nav>
        <ul>
          {map(filter(routes), (item, index) => {

            return <li
              key={index}
              className={FormatClassNames(
                'link',
                item.id === currentRoute ? 'selected' : null
              )}
            >
              <Link to={item.route}>{item.label}</Link>
            </li>
          })}
        </ul>
      </nav> */}
    </div>
  )
}

AdminHeader.propTypes = {
  currentRoute: PropTypes.string,
  disableLinks: PropTypes.bool,
  routes: PropTypes.array.isRequired,
}

AdminHeader.defaultProps = {
  disableLinks: false,
}

const mapStateToProps = (state) => ({
  isSuper: get(state, 'admin.isSuper') ? true : false,
})

export default connect(mapStateToProps, {
  logout,
})(AdminHeader)

import React from 'react'
import './Summary.scss'
import imgFeelBetterHeading from '../../assets/images/reskin/img-feel_better_heading.svg'
import imgFeelBetterFeatures from '../../assets/images/reskin/img-feel_better_features.jpg'

const rows = [
  '900+ healthy, simple plant-based recipes',
  '300+ at-home exercise classes from yoga to Pilates, cardio and barre',
  '150+ mindfulness and sleep tools to aid stress management and improved sleep',
  'Nutritionally-balanced meal plans and wellness plans',
  'A wellness tracker and meal planner to help your team stay on track of their wellness goals.',
]

const Summary = () => {
  return (
    <div className="wrapper-signup summary">
      <div className="intro">
        <div className="upper">
          <img src={imgFeelBetterHeading} alt="" />
          <h1 className="h1">
            Help your team feel better and work better every day
          </h1>
        </div>
        <div className="lower_text">
          {rows.map((text, i) => (
            <div className="row" key={`row_${i}`}>
              <img
                src={require(`../../assets/images/reskin/icn-row_${i + 1}.svg`)}
                alt=""
              />
              <h5>{text}</h5>
            </div>
          ))}
        </div>
        <div
          className="lower_image"
          style={{
            backgroundImage: `url(${imgFeelBetterFeatures})`,
          }}
        ></div>
      </div>
    </div>
  )
}

export default Summary

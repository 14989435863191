import React, { Fragment, useEffect } from 'react'

import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const TermsAndConditions = ({ homepageItems }) => {
  useEffect(() => {}, [])
  return (
    <div className="container">
      <h1>Terms And Conditions</h1>
    </div>
  )
}

TermsAndConditions.propTypes = {
  disabled: PropTypes.bool,
}

TermsAndConditions.defaultProps = {
  disabled: false,
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, {})(TermsAndConditions)

import {
  REGISTER_FAIL,
  AUTH_ERROR,
  LOGIN_FAIL,
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGOUT,
  USER_LOADED,
} from '../actions/types'

const initialState = {
  userChecked: false,
  isAuthenticated: false,
  error: null,
  loading: false,
  userEmail: null,
}

export default function (state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case LOGIN_START:
      return {
        ...state,
        isAuthenticated: false,
        error: null,
        loading: true,
        userEmail: null,
      }

    case USER_LOADED:
    case LOGIN_SUCCESS:
      return {
        ...state,
        error: null,
        isAuthenticated: true,
        userChecked: true,
        loading: false,
        userEmail: payload,
      }
    case REGISTER_FAIL:
    case AUTH_ERROR:
    case LOGIN_FAIL:
      return {
        ...state,
        isAuthenticated: false,
        userChecked: true,
        loading: false,
        userEmail: null,
        error: payload,
      }

    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        userEmail: null,
      }

    default:
      return state
  }
}

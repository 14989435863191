import React, { Fragment, useEffect } from 'react'

import { get } from 'lodash-es'

import { ROUTE_LOGIN } from '../../routes'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'

const LogoutRedirect = ({ disabled, isAuthenticated }) => {
  const history = useHistory()

  useEffect(() => {
    if (!disabled && !isAuthenticated) {
      history.push(ROUTE_LOGIN)
    }
  }, [isAuthenticated, disabled, history])

  return <Fragment></Fragment>
}

const mapStateToProps = (state) => ({
  isAuthenticated: get(state, 'auth.isAuthenticated'),
})

export default connect(mapStateToProps, {})(LogoutRedirect)

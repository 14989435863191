import { SUPER_ADMIN_LOADED } from '../actions/types'

const initialState = {
  users: [],
}

export default function (state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case SUPER_ADMIN_LOADED:
      return {
        ...state,
        users: payload,
      }
    default:
      return state
  }
}

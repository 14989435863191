import { Fragment, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { every, get } from 'lodash-es'
import PropTypes from 'prop-types'

import Logo from '../../assets/images/reskin/img-reskin_logo.svg'
import './Signup.scss'

import { signupSetup, signupWake, signupGetInfo } from '../../actions/signup'
import { getCurrencySymbol } from '../../utils/utils.currencies'
import {
  isCompanyNameValid,
  isEmailValid,
  isPasswordValid,
  isTeamNameValid,
} from '../../utils/utils.validator'

const StyledTextField = ({
  label,
  className,
  inputClassName,
  labelProps,
  validator,
  msgOnError,
  ...props
}) => {
  const [errorMsg, setErrorMsg] = useState('')

  const handleOnBlur = () => {
    if (!props.value || !validator) return
    if (!validator(props.value)) {
      setErrorMsg(msgOnError)
    } else {
      setErrorMsg('')
    }
  }

  return (
    <label
      className={`styled-text-label ${className ? className : ''}`}
      {...labelProps}
    >
      <span className="overline">{label}</span>
      <input
        type="text"
        className={`styled-text-input body-1 ${
          inputClassName ? inputClassName : ''
        }`}
        onBlur={handleOnBlur}
        {...props}
      />
      {errorMsg && <div className="styled-text-error body-3">{errorMsg}</div>}
    </label>
  )
}

/* ====================
 *        Layout
 * ==================== */

const CONTACT_LINK = 'mailto:teams@deliciouslyella.com'
const TNC_LINK = 'https://www.deliciouslyella.com/legal'
const PRIVACY_POLICY_LINK =
  'https://www.deliciouslyella.com/legal/privacy-policy'
const FOOTER_LINKS = [
  {
    label: 'Terms and Conditions',
    href: TNC_LINK,
  },
  {
    label: 'Privacy Policy',
    href: PRIVACY_POLICY_LINK,
  },
]
const FOOTER_COPYRIGHT = '© 2023 Deliciously Ella Ltd'

const Layout = ({ children }) => {
  return (
    <div className="layout">
      <header className="layout-header">
        <div />
        <img src={Logo} alt="Deliciously Ella Logo" className="layout-logo" />
        <div className="layout-header-help">
          Need Help? <a href={CONTACT_LINK}>Contact Us</a>
        </div>
      </header>
      {children}
      <footer className="layout-footer">
        {FOOTER_LINKS.map((link, idx) => (
          <Fragment key={`link-${idx}`}>
            {idx !== 0 && ' | '}
            <a href={link.href}>{link.label}</a>
          </Fragment>
        ))}
        <div>{FOOTER_COPYRIGHT}</div>
      </footer>
    </div>
  )
}

/* ====================
 *    Progress Bar
 * ==================== */

const STEPS = ['Create Account', 'Billing Details']

const ProgressBar = ({ step }) => {
  return (
    <nav className="progress">
      <div className="progress-inner">
        <ul className="progress-steps body-2">
          {STEPS.map((label, idx) => (
            <li
              key={`step-${idx}`}
              className={`progress-step ${
                step === idx ? 'progress-step_active' : ''
              }`}
            >
              {idx + 1}. {label}
            </li>
          ))}
        </ul>
      </div>
    </nav>
  )
}

/* ====================
 *        Form
 * ==================== */

const TEXT_INPUTS = [
  {
    id: 'email',
    label: 'Email',
    type: 'email',
    validator: isEmailValid,
    msgOnError: 'Invalid email address.',
  },
  {
    id: 'password',
    label: 'Create Password',
    type: 'password',
    autoComplete: 'new-password',
    validator: isPasswordValid,
    msgOnError: 'Password is too short.',
  },
  {
    id: 'passwordConfirm',
    label: 'Confirm Password',
    type: 'password',
  },
  {
    id: 'company',
    label: 'Company Name',
    type: 'text',
    validator: isCompanyNameValid,
    msgOnError: 'Company name is too short.',
  },
  {
    id: 'team',
    label: 'Team Name',
    type: 'text',
    validator: isTeamNameValid,
    msgOnError: 'Team name is too short.',
  },
]

const SIGN_IN_LINK = '/login'

const SignupForm = ({
  formId,
  members,
  countryCode,
  userData,
  countries,
  handleMembersAdd,
  handleMembersMinus,
  handleMembersChange,
  handleDataChange,
  handleCountryCodeChange,
  handleSubmit,
}) => {
  return (
    <div className="signup">
      <h1 className="signup-title">Become a Corporate Member</h1>
      <form id={formId} onSubmit={handleSubmit} className="signup-form">
        <section className="signup-members">
          <h2 className="signup-subtitle">Create Your Team</h2>
          <div className="signup-members-inputRow">
            <button
              type="button"
              onClick={handleMembersAdd}
              className="signup-members-button"
            >
              +
            </button>
            <input
              type="number"
              min="2"
              step="1"
              value={members}
              onChange={handleMembersChange}
              className="signup-numInput"
            />
            Member(s)
            <button
              type="button"
              onClick={handleMembersMinus}
              className="signup-members-button"
            >
              -
            </button>
          </div>
        </section>
        <section>
          <h2 className="signup-subtitle">Create your account</h2>
          <div>
            Already a member? <a href={SIGN_IN_LINK}>Sign In</a>
          </div>
          {TEXT_INPUTS.map((input) => {
            const { id, label, ...props } = input
            return (
              <StyledTextField
                key={id}
                name={id}
                label={label}
                value={userData[id]}
                onChange={handleDataChange}
                required
                className="signup-textInput"
                {...props}
                {...(id === 'passwordConfirm'
                  ? {
                      validator: (val) => val === userData.password,
                      msgOnError: 'Passwords do not match.',
                    }
                  : {})}
              />
            )
          })}
          <label className="signup-selectLabel overline">
            Select Country
            <select
              name="country"
              value={countryCode}
              onChange={handleCountryCodeChange}
              className="signup-selectInput body-1"
            >
              {countries &&
                countries.map((option) => (
                  <option key={option.code3} value={option.code3}>
                    {option.name}
                  </option>
                ))}
            </select>
          </label>
        </section>
      </form>
    </div>
  )
}

const SummaryPanel = connect((state) => ({
  error: get(state, 'signup.error'),
  loading: get(state, 'signup.loading'),
}))(({ error, loading, members, product, formId, isFormComplete }) => {
  const price = product?.data.price
  const unitPrice = (price?.unit_amount ?? 0) / 100
  const totalPrice = unitPrice * members
  const currencySymbol = price ? getCurrencySymbol(price.currency) : ''

  return (
    <section className="summary">
      <h2 className="summary-title">Summary</h2>
      <hr />
      <dl className="summary-price">
        <dt>
          {members} Member(s) x {currencySymbol}
          {unitPrice.toFixed(2)}
        </dt>
        <dd>
          {currencySymbol}
          {totalPrice.toFixed(2)}/year
        </dd>
      </dl>
      <div className="summary-cta">
        <button
          type="submit"
          form={formId}
          className="styled-button styled-button_primary styled-button_medium styled-button_fullWidth"
          disabled={!isFormComplete || loading}
        >
          {loading ? 'Loading...' : 'Continue'}
        </button>
        {error && <div className="summary-error body-2">{error}</div>}
        <p className="summary-cta-disclaimer body-3">
          By clicking the Continue button above, you agree to the{' '}
          <a href={TNC_LINK}>Terms & Conditions</a> and{' '}
          <a href={PRIVACY_POLICY_LINK}>Privacy Policy</a>
        </p>
      </div>
    </section>
  )
})

/* ====================
 *        Main
 * ==================== */

const FORM_ID = 'create-account-form'

const Homepage = ({
  products,
  countries,
  signupWake,
  signupGetInfo,
  signupSetup,
}) => {
  const [step, setStep] = useState(0)
  const [members, setMembers] = useState(5)
  const [countryCode, setCountryCode] = useState('GBR')
  const [userData, setUserData] = useState(
    Object.fromEntries(TEXT_INPUTS.map((input) => [input.id, '']))
  )

  const country = useMemo(
    () => countries?.find((_country) => _country.code3 === countryCode),
    [countries, countryCode]
  )

  const product = useMemo(() => {
    if (!country) return null
    return products?.find((product) => {
      // The last element does not have filter and acts as a default
      if (!product.filters) return true
      return Array.isArray(product.filters.code3)
        ? product.filters.code3.includes(country.code3)
        : product.filters.code3 === country.code3
    })
  }, [products, country])

  const isFormComplete = every([
    isEmailValid(userData.email),
    isPasswordValid(userData.password),
    userData.passwordConfirm && userData.passwordConfirm === userData.password,
    isCompanyNameValid(userData.company),
    isTeamNameValid(userData.team),
  ])

  const handleMembersAdd = () => setMembers((_members) => _members + 1)
  const handleMembersMinus = (e) => {
    setMembers((_members) => (_members > 2 ? _members - 1 : _members))
  }
  const handleMembersChange = (e) => {
    const val = e.target.value
    if (isNaN(Number(val))) return
    const num = Number(val)
    setMembers(Math.abs(Math.floor(num)))
  }

  const handleDataChange = (e) => {
    const key = e.target.name
    const val = e.target.value
    setUserData((_userData) => ({
      ..._userData,
      [key]: val,
    }))
  }

  const handleCountryCodeChange = (e) => setCountryCode(e.target.value)

  const handleSubmit = (e) => {
    e.preventDefault()
    const data = {
      email: userData.email,
      password: userData.password,
      teamName: userData.team,
      companyName: userData.company,
      numberOfUsers: members,
      country: countryCode,
      product: product,
    }
    signupSetup(data).catch((err) => console.error(err))
  }

  useEffect(() => {
    signupWake()
    signupGetInfo()
  }, [])

  useEffect(() => {
    let currentNode = document.body.firstChild
    while (currentNode) {
      if (
        currentNode.nodeType === Node.TEXT_NODE &&
        currentNode.nodeValue === ';\n\n'
      ) {
        currentNode.nodeValue = null
      }
      currentNode = currentNode.nextSibling
    }
  }, [])

  return (
    <Layout>
      <ProgressBar step={step} />
      <main className="mainSection">
        <SignupForm
          formId={FORM_ID}
          members={members}
          countryCode={countryCode}
          userData={userData}
          countries={countries}
          handleMembersAdd={handleMembersAdd}
          handleMembersMinus={handleMembersMinus}
          handleMembersChange={handleMembersChange}
          handleDataChange={handleDataChange}
          handleCountryCodeChange={handleCountryCodeChange}
          handleSubmit={handleSubmit}
        />
        <SummaryPanel
          members={members}
          product={product}
          formId={FORM_ID}
          isFormComplete={isFormComplete}
        />
      </main>
    </Layout>
  )
}

Homepage.propTypes = {}

Homepage.defaultProps = {}

const mapStateToProps = (state) => ({
  countries: get(state, 'signup.info.countries'),
  products: get(state, 'signup.info.products'),
})

export default connect(mapStateToProps, {
  signupSetup,
  signupWake,
  signupGetInfo,
})(Homepage)

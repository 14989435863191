import React from 'react'
import { Link } from 'react-router-dom'

import { isUndefined, filter, join } from 'lodash-es'
import PropTypes from 'prop-types'

import './Button.scss'
import { FormatClassNames } from '../../utils/utils.classnames'
import { Fragment } from 'react'

const Button = ({
  className,
  onClick,
  to,
  children,
  icon,
  size,
  color,
  variant,
  disabled,
}) => {
  const isLink = !isUndefined(to) ? true : false
  const ClassName = isLink ? Link : 'a'

  const props = {
    className: FormatClassNames(
      className,
      'button',
      size ? `size-${size}` : null,
      icon ? `icon` : null,
      color,
      variant,
      disabled ? 'disabled' : null
    ),
    disabled,
    onClick: !disabled && !isLink ? onClick : undefined,
    to: isLink ? to : undefined,
  }

  return (
    <ClassName {...props}>
      {icon ? (
        <Fragment>
          <img src={icon} />
          <span className="spacer">{children}</span>
        </Fragment>
      ) : (
        children
      )}
    </ClassName>
  )
}

Button.propTypes = {
  to: PropTypes.string,
}

export default Button

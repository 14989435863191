import { ADMIN_LOADED, SET_WELCOME_FLAG } from '../actions/types'

const initialState = {
  corporations: null,
  corporationSelected: null,
  welcome: false
}

export default function (state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case ADMIN_LOADED:
      return {
        ...state,
        isSuper: payload.isSuper,
        corporations: payload.corporations,
      }
    case SET_WELCOME_FLAG:
      return {
        ...state,
        welcome: payload,
      }
    default:
      return state
  }
}

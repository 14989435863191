import React, { Fragment, useState } from 'react'
import LoadingMask from '../common/LoadingMask'

const PaymentQueue = () => {
  return <LoadingMask enabled={true} className="fill-page" />
}

export default PaymentQueue

// export default connect()(Payment);

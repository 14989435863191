import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Lottie from 'lottie-react-web'

import { filter, join } from 'lodash-es'

import './LoadingMask.scss'
import animation from './LoadingAnim.json'
import Modal from './Modal'
import { FormatClassNames } from '../../utils/utils.classnames'

const LoadingMask = ({ className, as, enabled, children, style }) => {
  const ClassName = as || 'div'

  const props = {
    className: FormatClassNames(className, 'loading-mask'),
    style,
  }

  return (
    <ClassName {...props}>
      {children}
      {enabled && (
        <div className="overlay">
          <div className="animation">
            <Lottie options={{ animationData: animation }} />
          </div>
        </div>
      )}
    </ClassName>
  )
}

LoadingMask.propTypes = {
  enabled: PropTypes.bool,
}

LoadingMask.defaultProps = {
  enabled: false,
}

export default LoadingMask

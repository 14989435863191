import React, { Fragment, useEffect } from 'react'

import { get, includes, size } from 'lodash-es'
import PropTypes from 'prop-types'

import { ROUTE_ADMIN, ROUTE_DASHBOARD } from '../../routes'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'

const LoginRedirect = ({ disabled, status, ignoreStates, isAuthenticated }) => {
  const history = useHistory()

  useEffect(() => {
    if (!disabled && isAuthenticated) {
      if (size(ignoreStates) > 0) {
        if (!status) {
          //waiting for this to load
          return
        } else if (includes(ignoreStates, status)) {
          //told to ignore this status and not redirect
          return
        }
      }

      history.push(ROUTE_ADMIN)
    }
  }, [isAuthenticated, disabled, history, status, ignoreStates])

  return <Fragment></Fragment>
}

const mapStateToProps = (state) => ({
  isAuthenticated: get(state, 'auth.isAuthenticated'),
  status: get(state, 'corporation.status'),
})

LoginRedirect.propTypes = {
  ignoreStates: PropTypes.array,
}

LoginRedirect.defaultProps = {
  ignoreStates: [],
}

export default connect(mapStateToProps, {})(LoginRedirect)

import React, { Component, Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Link,
  Redirect,
  Route,
  Switch,
  useLocation,
  withRouter,
} from 'react-router-dom'

import PropTypes from 'prop-types'

import logo from '../../assets/images/logo.svg'
import rightArrow from '../../assets/images/LongRightArrow.png'

import { saveAs } from 'file-saver'

import moment from 'moment'
import {
  map,
  join,
  camelCase,
  filter,
  uniqBy,
  get,
  first,
  slice,
  size,
  isEmpty,
  orderBy,
  includes,
  upperCase,
  lowerCase,
  every,
} from 'lodash-es'
import {
  createCorporateVouchers,
  resendCorporateVouchers,
  removeCorporationVouchers,
  setupCorporationOffSessionPayment,
  loadCorporation,
  unloadCorporation,
} from '../../actions/corporation'

import Button from '../common/Button'
import Input from '../common/Input'
import Modal from '../common/Modal'

import { parseCsvEmailList, parseCsvFile } from '../../utils/parser'

import { FlexRow, FlexColumn } from '../common/Flex'
import Uploader from '../common/Uploader'
import Dashboard from '../layout/DashBoard'

import './AdminRoutes.scss'
import AdminHeader from './AdminHeader'
import {
  ROUTE_ADMIN,
  ROUTE_CORPORATION,
  ROUTE_DASHBOARD,
  ROUTE_DASHBOARD_EDIT_PLAN,
  ROUTE_DASHBOARD_INSIGHTS,
  ROUTE_DASHBOARD_INVOICES,
  ROUTE_DASHBOARD_SUPER_ADMIN,
  ROUTE_DASHBOARD_USAGE,
} from '../../routes'

import AdminInvoices from './AdminInvoices'
import AdminInsights from './AdminInsights'
import AdminUsage from './AdminUsage'
import AdminEditPlan from './AdminEditPlan'
import LoadingMask from '../common/LoadingMask'
import AdminCorporations from './AdminCorporations'
import AdminCorporationMain from './AdminCorporationMain'
import { createRoutePath } from '../../utils/utils.routes'

//assets

// .then((users) => {
//   //present the list of users - compare to existing
//   // const existingUsers =

//   // //we could/should check the csv file now before we upload it
//   // inviteUsers(map(users, (user) => user.email));
//   // onHideInviteUsers();
// })

// fontawesome.library.add(faCheckSquare, faCoffee);
const AdminRoutes = ({
  corporations,
  corporation,
  corporationId,
  loadCorporation,
  unloadCorporation,
}) => {
  return (
    <LoadingMask loading={corporations ? false : true}>
      <CorporationRouteWatcher
        loadCorporation={loadCorporation}
        unloadCorporation={unloadCorporation}
      />
      {!corporation ? (
        <AdminCorporations />
      ) : (
        <Switch>
          <Route exact path={ROUTE_ADMIN} component={AdminCorporations} />
          <Route
            exact
            path={ROUTE_DASHBOARD}
            render={() => {
              return <AdminCorporationMain />
            }}
          />

          <Route
            exact
            path={ROUTE_DASHBOARD_INVOICES}
            component={AdminInvoices}
          />

          <Route
            exact
            path={ROUTE_DASHBOARD_INSIGHTS}
            component={AdminInsights}
          />

          <Route exact path={ROUTE_DASHBOARD_USAGE} component={AdminUsage} />

          <Route
            exact
            path={ROUTE_DASHBOARD_EDIT_PLAN}
            component={AdminEditPlan}
          />

          <Route
            render={() => (
              <Redirect
                to={createRoutePath(ROUTE_DASHBOARD, { corporationId })}
              />
            )}
          />
        </Switch>
      )}
    </LoadingMask>
  )
}

// Need to connect component to Redux

const mapStateToProps = (state) => ({
  corporation: get(state, 'corporation.data'),
  corporationId: get(state, 'corporation.data.id'),
  corporations: get(state, 'admin.corporations'),
  isSuper: get(state, 'admin.isSuper') ? true : false,
})

export default connect(mapStateToProps, {
  loadCorporation,
  unloadCorporation,
})(AdminRoutes)

export const ROUTES = (state) => {
  var isSuper = get(state, 'admin.isSuper') ? true : false
  var corporationId = get(state, 'corporation.data.id')

  console.log('corporationId', corporationId)

  return corporationId
    ? [
        {
          id: 'dashboard',
          route: createRoutePath(ROUTE_DASHBOARD, { corporationId }),
          label: 'Account',
        },
        // {id:'usage',route:createRoutePath( ROUTE_DASHBOARD_USAGE, {corporationId} ),label:'Usage'},
        {
          id: 'insight',
          route: createRoutePath(ROUTE_DASHBOARD_INSIGHTS, { corporationId }),
          label: 'Insights',
        },
      ]
    : []
}

class CorporationRouteWatcher extends Component {
  componentWillUnmount() {
    this.props.unloadCorporation()
  }

  render() {
    const { loadCorporation, unloadCorporation } = this.props
    return (
      <Route
        path={ROUTE_CORPORATION}
        render={(data) => {
          const params = get(data, 'match.params')
          const { corporationId } = params || {}

          if (corporationId !== this.corporationId) {
            this.corporationId = corporationId
            if (corporationId === ':corporationId') {
              //alert('corporationId is not set');
            } else {
              if (this.corporationId) {
                loadCorporation(this.corporationId)
              } else {
                //unloadCorporation();
              }
            }
          }

          return null
        }}
      />
    )
  }
}

CorporationRouteWatcher.propTypes = {
  loadCorporation: PropTypes.func.isRequired,
  unloadCorporation: PropTypes.func.isRequired,
}

CorporationRouteWatcher.defaultProps = {}

import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { parseCsvEmailList } from '../../utils/parser'
import Button from '../common/Button'
import Input from '../common/Input'
import Uploader from '../common/Uploader'

import PropTypes from 'prop-types'
import Modal from '../common/Modal'
import TableList from '../common/TableList'

import { saveAs } from 'file-saver'

import {
  find,
  first,
  filter,
  get,
  isEmpty,
  pick,
  size,
  map,
  merge,
  slice,
  orderBy,
  indexOf,
} from 'lodash-es'
import { isEmailValid, isNumber } from '../../utils/utils.validator'
import {
  createCorporateVouchers,
  loadCorporationVouchers,
} from '../../actions/corporation'
import { formatEmail } from '../../utils/utils.format'
import { FlexColumn, FlexRow, FlexSpacer } from '../common/Flex'
import ErrorMessage from '../common/ErrorMessage'
import LoadingMask from '../common/LoadingMask'

import './AdminUserUpload.scss'

import icoClose from '../../assets/images/icoClose.svg'
import icoInviteSent from '../../assets/images/icoInviteSent.svg'

const ERROR_INVALID_EMAIL = 'Invalid email'
const ERROR_USER_ALREADY_EXISTS = 'User already exists'
const ERROR_CSV_DUPLICATION = 'Duplication in CSV'

const plural = (arr) => (size(arr) > 1 ? 's' : '')

const AdminUserUpload = ({
  limit,
  corporation,
  existingVouchers,
  onClose,
  createCorporateVouchers,
  loadCorporationVouchers,
  uploadingInvites,
  progressInvites,
}) => {
  const [mode, setMode] = useState('')
  const [error, setError] = useState('')
  const [inviteUserEmail, setInviteUserEmail] = useState('')
  const [usersInQueue, setUsersInQueue] = useState([])
  const [usersWithIssues, setUsersWithIssues] = useState([])
  const [invitesSent, setInvitesSent] = useState(false)

  useEffect(() => {
    //clear error whenever the inviteUserEmail is changed
    setError('')
  }, [inviteUserEmail])

  const onClickUploadUser = () => {
    setError('')
    setMode('single')
  }

  const onUploadedUsers = (files) => {
    //clear state
    setError('')
    //reparse
    parseCsvEmailList(first(files))
      .then(onInviteUsers)
      .catch((err) => {
        setError(err.message || err)
      })
  }

  const onDownloadUsersCsv = () => {
    var blob = new Blob(
      [
        `"Name","Email"
"John Doe","john.doe@example.com"
"John Smith","john.smith@example.com"`,
      ],
      { type: 'text/plain;charset=utf-8' }
    )
    saveAs(blob, 'example-vouchers.csv')
  }

  const onInviteUser = (user) => {
    onInviteUsers([user], { skipQueue: true })
  }

  const onInviteUsers = (users, { skipQueue = false } = {}) => {
    // const existingVouchers = get( corporation, 'vouchers' );
    //add errors to the user invitations if detected
    users = map(users, (user) => {
      var error = user.error || null

      if (!isEmailValid(user.email)) {
        error = ERROR_INVALID_EMAIL
      } else if (
        size(filter(existingVouchers, { email: user.email })) > 0 ? true : false
      ) {
        error = ERROR_USER_ALREADY_EXISTS
      } else if (find(users, pick(user, 'email')) !== user) {
        error = ERROR_CSV_DUPLICATION
      }

      return error ? merge(user, { error }) : user
    })

    const invalidUsers = orderBy(
      filter(users, (user) => (user.error ? true : false)),
      (item) => {
        return (
          indexOf(
            [
              null,
              ERROR_INVALID_EMAIL,
              ERROR_CSV_DUPLICATION,
              ERROR_USER_ALREADY_EXISTS,
            ],
            item.error
          ) || 10
        )
      }
    )
    setUsersWithIssues(invalidUsers)

    const validUsers = filter(users, (user) => (!user.error ? true : false))
    setUsersInQueue(validUsers)

    if (isNumber(limit) && size(validUsers) > limit) {
      setError(`Insufficient invites, only ${limit} available`)
    } else {
      if (size(invalidUsers) === 0 && size(validUsers) > 0 && skipQueue) {
        //we need to pass the list as currently the list is empty
        onStartQueue(null, validUsers)
      } else {
        setMode('processing')
      }
    }
  }

  const onIgnoreIssues = () => {
    setUsersWithIssues([])
  }

  const onStartQueue = (evt, users) => {
    //upload the next batch
    users = users || usersInQueue

    createCorporateVouchers(
      corporation.id,
      map(filter(users), (user) => user.email),
      { reloadVouchers: false }
    )
      .then(() => {
        // TODO: (Optional) Tell user that we've completed the upload
        onInvitesSent()
      })
      .catch((err) => {
        // do something with the error
        onClose()
      })
  }

  const onInvitesSent = () => {
    setInvitesSent(true)
  }

  const cacheQueue = (users) => {
    localStorage.setItem('user-upload-queue', JSON.stringify(users))
  }

  return (
    <Fragment>
      {/* THE INITIAL POP UP */}
      {uploadingInvites ? (
        <Modal show={true} className="size-medium processing-popup">
          <FlexRow className="modal-header">
            <h5>Invite Members</h5>
            <button onClick={onClose}>
              <img src={icoClose} alt="close" width={22} height={22} />
            </button>
          </FlexRow>
          {/* TODO: Warn user not to go away */}
          <p>Please do not close or refresh your browser</p>
          <LoadingMask
            className="processing-loader"
            enabled={true}
          ></LoadingMask>
          <p>Processing... [{(progressInvites * 100).toFixed(0)}%]</p>
        </Modal>
      ) : (
        <Modal
          show={true}
          onClose={onClose}
          className="invite-popup invite-members size-medium">
          <FlexRow className="modal-header">
            <h5>Invite Members</h5>
            <button onClick={onClose}>
              <img src={icoClose} alt="close" width={22} height={22} />
            </button>
          </FlexRow>
          {error && <ErrorMessage className="mb-1">{error}</ErrorMessage>}
          {mode === 'processing' &&
            (size(usersWithIssues) > 0 ? (
              <div className='error-box'>
                <ErrorMessage className="mb-1" color="grey">
                  {size(usersWithIssues)} Issue{plural(usersWithIssues)}{' '}
                  detected
                </ErrorMessage>
                <TableList
                  limit={5}
                  items={usersWithIssues}
                  renderer={(user, index) => (
                    <FlexRow key={index} align="stretch" className="entry">
                      <p className="grow">{user.email}</p>
                      <p className="status">{user.error}</p>
                    </FlexRow>
                  )}
                />
                <FlexRow className="mt-1">
                  <Button onClick={onClose}>Cancel</Button>
                  <div className="grow"></div>
                  {size(usersInQueue) > 0 && (
                    <Button onClick={onIgnoreIssues}>Continue</Button>
                  )}
                </FlexRow>
              </div>
            ) : (
              !invitesSent && (
                <Fragment>
                  <TableList
                    limit={5}
                    items={usersInQueue}
                    renderer={(user, index) => (
                      <FlexRow key={index} align="stretch" className="entry">
                        <p className="grow">{user.email}</p>
                        <p className="status">{user.error}</p>
                      </FlexRow>
                    )}
                  />
                  <Button onClick={onStartQueue} className='upload-member-button'>
                    Upload {size(usersInQueue)} member{plural(usersInQueue)}
                  </Button>
                </Fragment>
              )
            ))}

          {mode === 'single' && !invitesSent && (
            <div className="invite-members-box">
              <div className="invite-single-member">
                <Input
                  value={inviteUserEmail}
                  onChange={setInviteUserEmail}
                  className="modal-email-input"
                  placeholder="Enter email address"
                />
                <Button
                  className="invite-single-member-button"
                  disabled={!isEmailValid(inviteUserEmail)}
                  onClick={() => {
                    onInviteUser({ email: formatEmail(inviteUserEmail) })
                  }}
                >
                  Invite
                </Button>
              </div>
              <p className="invite-sent"></p>
            </div>
          )}
          {isEmpty(mode) && (
            <div className="content-box">
              <div className="button-box">
                <Button className="" onClick={onClickUploadUser}>
                  Invite Single User
                </Button>
                <Uploader onChange={onUploadedUsers}>
                  <Button>Upload Users</Button>
                </Uploader>
              </div>
              <a
                className="example-csv"
                style={{ cursor: 'hand' }}
                onClick={onDownloadUsersCsv}
              >
                download example CSV
              </a>
            </div>
          )}
        </Modal>
      )}
      {invitesSent && (
        <Modal
          onClose={onClose}
          className="invite-popup invites-sent size-medium"
        >
          <FlexRow className="modal-header">
            <h5>Invite Members</h5>
            <button onClick={onClose}>
              <img src={icoClose} alt="close" width={22} height={22} />
            </button>
          </FlexRow>
          <div className="content-box-sent">
            <FlexRow className="justify-start">
              <span className="body-1">
                {usersInQueue.length} invite{plural(usersInQueue)} sent!
              </span>
            </FlexRow>
            <FlexRow className="justify-start">
              <Button onClick={onClose}>close</Button>
            </FlexRow>
          </div>
        </Modal>
      )}
    </Fragment>
  )
}

AdminUserUpload.propTypes = {
  onClose: PropTypes.func.isRequired,
  uploadingInvites: PropTypes.bool.isRequired,
  progressInvites: PropTypes.number.isRequired,
}

AdminUserUpload.defaultProps = {
  existingVouchers: [],
  uploadingInvites: false,
  progressInvites: 0,
}

const mapStateToProps = (state) => ({
  corporation: get(state, 'corporation.data'),
  existingVouchers: get(state, 'corporation.vouchers'),
  progressInvites: get(state, 'invites.progress'),
  uploadingInvites: get(state, 'invites.uploading'),
})

export default connect(mapStateToProps, {
  createCorporateVouchers,
  loadCorporationVouchers,
})(AdminUserUpload)

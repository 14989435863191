import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { get } from 'lodash-es'
import {
  logout,
  loadSignupInvite,
  completeSignupInvite,
} from '../../actions/auth'
import { ROUTE_HOME, ROUTE_LOGIN } from '../../routes'

// components
import Button from '../common/Button'
import Input from '../common/Input'
import { FlexColumn, FlexRow } from '../common/Flex'

// assets
import Logo from '../../assets/images/imgFeelBetterWorkBetterLogo.png'

// styles
import './Login.scss'
import ErrorMessage from '../common/ErrorMessage'
import LoadingMask from '../common/LoadingMask'
import { isPasswordValid } from '../../utils/utils.validator'
import LoginRedirect from './LoginRedirect'

const SignupInvite = ({
  error,
  inviteId,
  logout,
  loadSignupInvite,
  completeSignupInvite,
  disabled,
}) => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')

  const onSubmit = () => {
    setLoading(true)
    completeSignupInvite(inviteId, password).then(() => {
      history.push(ROUTE_LOGIN)
      setLoading(false)
    })
  }

  useEffect(() => {
    loadSignupInvite(inviteId)
    logout()
  }, [])

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onSubmit()
    }
  }

  const isValid = isPasswordValid(password)

  return (
    <Fragment>
      <div className="login-layout">
        <FlexRow className="header-row">
          <Link className="logo" to={ROUTE_HOME}>
            <img
              src={Logo}
              alt="Deliciously Ella Logo"
              width={200}
              height={200}
            />
          </Link>
        </FlexRow>
        <div className="body">
          {error && <ErrorMessage className="error mt-1">{error}</ErrorMessage>}
          <LoadingMask enabled={loading} className="form-area">
            {error ? (
              <FlexColumn>
                <Button
                  className="login-button"
                  color="secondary"
                  to={ROUTE_LOGIN}
                >
                  Return to login
                </Button>
              </FlexColumn>
            ) : (
              <FlexColumn>
                <Input
                  required
                  type="password"
                  label="Password"
                  placeholder=""
                  value={password}
                  onChange={setPassword}
                  onKeyDown={handleKeyDown}
                  fullWidth
                  autoComplete="family-name"
                />

                <Button
                  className="login-button"
                  color="secondary"
                  disabled={!isValid}
                  onClick={onSubmit}
                >
                  Create Account
                </Button>
              </FlexColumn>
            )}
          </LoadingMask>
        </div>
      </div>
    </Fragment>
  )
}

SignupInvite.propTypes = {
  inviteId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}

SignupInvite.defaultProps = {
  disabled: false,
}

const mapStateToProps = (state) => ({
  data: get(state, 'signupInvite.data'),
  error: get(state, 'signupInvite.error'),
})

export default connect(mapStateToProps, {
  logout,
  loadSignupInvite,
  completeSignupInvite,
})(SignupInvite)

import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'

import Modal from '../common/Modal'

import insightsPreview from '../../assets/images/insights-preview.png'
import AdminHeader from './AdminHeader'

import {
  loadCorporationSubscriptionUsage,
  updateCorporationSetting,
} from './../../actions/corporation'

import './AdminUsage.scss'

import { saveAs } from 'file-saver'

import InputCheckbox from '../common/InputCheckbox'
import { ROUTE_DASHBOARD, ROUTE_DASHBOARD_INSIGHTS } from '../../routes'
import {
  get,
  size,
  map,
  filter,
  sumBy,
  join,
  isString,
  uniqBy,
} from 'lodash-es'
import { useHistory } from 'react-router-dom'
import AdminStatusBlocker from './AdminStatusBlocker'
import { ROUTES } from './AdminRoutes'
import BackButton from '../common/BackButton'
import { createRoutePath } from '../../utils/utils.routes'
import LogoutButton from '../common/LogoutButton'
import Button from '../common/Button'

import moment from 'moment'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { isNumber } from '../../utils/utils.validator'
import { formatDate } from '../../utils/utils.format'
import { FlexRow, FlexSpacer } from '../common/Flex'
import ErrorMessage from '../common/ErrorMessage'

const toMoment = (date) => {
  return isNumber(date) ? moment(date) : null
}

const removeWhiteSpace = (val) => {
  return val ? val.replace(/\s/g, '') : val
}

const AdminUsage = ({
  corporation,
  corporationId,
  subscriptionUsage,
  loadCorporationSubscriptionUsage,
  routes,
}) => {
  const [startDate, setStartDate] = useState(moment().add(-1, 'day').toDate())
  const [endDate, setEndDate] = useState(new Date())
  const [showPublishNotes, setShowPublishNotes] = useState(false)

  useEffect(() => {
    if (corporationId) {
      loadCorporationSubscriptionUsage(corporationId)
    }
  }, [corporationId])

  useEffect(() => {
    // console.log('calculateUsage', startDate, endDate, size( subscriptionUsage ) );
  }, [startDate, endDate])

  const onClickLoadUsage = () => {
    loadCorporationSubscriptionUsage(corporationId)
  }

  const startRange = moment(startDate).startOf('day')
  const endRange = moment(endDate).endOf('day')

  const subscriptionsInRange = filter(
    map(subscriptionUsage, (item) => {
      //how many days is this in range for?
      const subStartAt = isNumber(item.startedAt)
        ? moment(item.startedAt).startOf('day')
        : null

      console.log(subStartAt, item)

      if (!subStartAt) {
        //this is an invalid state - put it down to being test data and ignore it
        return null
      }
      //if no end requested assume it's the same as our search range
      const subEndAt = isNumber(item.endedAt)
        ? moment(item.endedAt).endOf('day')
        : endRange.clone()

      //we need to compare startAt and startRange with endAt and endRange to get a overlapRange
      const mStartAt = subStartAt.isBefore(startRange) ? startRange : subStartAt
      const mEndAt = subEndAt.isAfter(endRange) ? endRange : subEndAt

      console.log(mStartAt, mEndAt)

      //get the days between the mStartAt and mEndAt
      return {
        rangeStart: mStartAt,
        rangeEnd: mEndAt,
        rangeDays: mEndAt.diff(mStartAt, 'days'),
        data: item,
      }
    }),
    (item) => item && item.rangeDays > 0
  )

  const totalUsage = sumBy(subscriptionsInRange, 'rangeDays')

  const onDownloadCsv = () => {
    var blob = new Blob(
      [
        join(
          map(subscriptionsInRange, (item) =>
            join(
              [
                item.data.userId,
                item.data.userEmail,
                item.rangeDays,
                formatDate(item.data.startedAt, ''),
                formatDate(item.data.endedAt, ''),
              ],
              ','
            )
          ),
          '\r\n'
        ),
      ],
      { type: 'text/plain;charset=utf-8' }
    )
    saveAs(
      blob,
      `usage-${removeWhiteSpace(formatDate(startDate))}-${removeWhiteSpace(
        formatDate(endDate)
      )}.csv`
    )
  }

  const onPublish = () => {
    setShowPublishNotes(true)
  }

  const onHidePublish = () => {
    setShowPublishNotes(false)
  }

  return (
    <AdminStatusBlocker className="admin admin-usage container">
      <AdminHeader currentRoute={'usage'} routes={routes} />
      <div className="row invoice-list">
        <div className="column-menu">
          <div className="link action">
            <BackButton
              to={createRoutePath(ROUTE_DASHBOARD, { corporationId })}
              className="dash-nav"
            >
              back to account
            </BackButton>
          </div>
          <div className="link action">
            <LogoutButton />
          </div>
        </div>
        <div className="column-main">
          {/* <div className="body-frame"> */}
          <h1>Usage</h1>

          {/* <Button onClick={onClickLoadUsage}>Load Usage</Button> */}
          <table>
            <thead>
              <tr>
                <th>
                  <h5>from</h5>
                </th>
                <th>
                  <h5>to</h5>
                </th>
                <th>
                  <h5>total users</h5>
                </th>
                <th>
                  <h5>total user/days</h5>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <DatePicker
                    className="datepicker"
                    selected={startDate}
                    onChange={setStartDate}
                  />
                </td>
                <td>
                  <DatePicker
                    className="datepicker"
                    selected={endDate}
                    onChange={setEndDate}
                  />
                </td>
                <td>{size(uniqBy(subscriptionsInRange, 'data.userEmail'))}</td>
                <td>{totalUsage}</td>
              </tr>
            </tbody>
          </table>
          <FlexRow align="center" className="summary">
            <FlexSpacer />
            <Button color="secondary" onClick={onDownloadCsv}>
              Download CSV
            </Button>
            <Button color="secondary" onClick={onPublish}>
              Publish
            </Button>
          </FlexRow>
          {showPublishNotes && (
            <ErrorMessage className="align-left">
              <p>
                We should create a snapshot of this date range and total usage.
              </p>
              <p>This will allow:</p>
              <ul>
                <li>
                  Admin to 'Publish' usage for a specific Month or Quarter which
                  they then invoice to the client
                </li>
                <li>
                  Load this page with startDate automatically poluated with the
                  last endDate from the most recent published "range"
                </li>
              </ul>
            </ErrorMessage>
          )}
          <table>
            <thead>
              <tr>
                <th>
                  <h5>email</h5>
                </th>
                <th>
                  <h5>date start</h5>
                </th>
                <th>
                  <h5>date end</h5>
                </th>
                <th>
                  <h5>usage</h5>
                </th>
              </tr>
            </thead>
            <tbody>
              {map(subscriptionsInRange, (item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <p>{item.data.userEmail}</p>
                    </td>
                    <td>
                      <p>{formatDate(item.data.startedAt)}</p>
                    </td>
                    <td>
                      <p>{formatDate(item.data.endedAt)}</p>
                    </td>
                    <td className="center">
                      <p>{item.rangeDays}</p>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          {/* </div> */}
        </div>
      </div>
    </AdminStatusBlocker>
  )
}

const mapStateToProps = (state) => ({
  corporation: get(state, 'corporation.data'),
  corporationId: get(state, 'corporation.data.id'),
  subscriptionUsage: get(state, 'corporation.subscriptionUsage'),
  routes: ROUTES(state),
})

export default connect(mapStateToProps, {
  updateCorporationSetting,
  loadCorporationSubscriptionUsage,
})(AdminUsage)

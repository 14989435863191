import React, { Fragment, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  join,
  sortBy,
  get,
  map,
  first,
  size,
  includes,
  some,
  filter,
  startCase,
} from 'lodash-es'
import moment from 'moment'
import { saveAs } from 'file-saver'
import { logout } from '../../actions/auth'

// utils
import { createRoutePath } from '../../utils/utils.routes'
import { parseDate } from '../../utils/utils.format'
import {
  ROUTE_HOME,
  ROUTE_DASHBOARD,
  ROUTE_SUPER_ADMIN_USERS,
} from '../../routes'

// actions
import { loadCorporation } from '../../actions/corporation'
import { lookupUsersByEmail } from '../../actions/admin'

//components
import Input from '../common/Input'
import Button from '../common/Button'
import LoadingMask from '../common/LoadingMask'
import ButtonIcon from '../common/ButtonIcon'
import AdminHeader from './AdminHeader'
import searchIcon from '../../assets/images/search-icon.svg'
import AdminCreateCorporation from './AdminCreateCorporation'
import WelcomeModal from '../common/WelcomeModal'

// styles
import './AdminCorporations.scss'

// assets
import Logo from '../../assets/images/reskin/img-reskin_logo.svg'
import logoutSvg from '../../assets/images/logout.svg'

import downloadIcon from '../../assets/images/search-icon.svg'

import Dropdown from '../common/Dropdown'

const menuItems = [
  {
    title: 'dashboard',
    url: ROUTE_DASHBOARD,
  },
  {
    title: 'settings',
    url: ROUTE_SUPER_ADMIN_USERS,
  },
]
const infoList = [
  'company',
  'team',
  'account',
  'total subscriptions',
  'subscription left',
  'plan type',
]
const AdminCorporations = ({
  isSuper = false,
  corporations: allCorporations,
  loadCorporation,
  lookupUsersByEmail,
  logout,
}) => {
  const history = useHistory()
  const [search, setSearch] = useState('')
  const [showCreateCorporation, setShowCreateCorporation] = useState(false)
  const [showWelcomeModal, setShowWelcomeModal] = useState(false)

  const onShowCreateCorporation = () => {
    //show the create corporation modal
    setShowCreateCorporation(true)
  }

  const onHideCreateCorporation = () => {
    //show the create corporation modal
    setShowCreateCorporation(false)
  }


  useEffect(() => {
    if (!isSuper && size(allCorporations) === 1) {
      //alert('load default corporation');
      //autoselect this corporation
      // loadCorporation( first( allCorporations ) );
      const corporation = first(allCorporations)
      history.push(
        createRoutePath(ROUTE_DASHBOARD, { corporationId: corporation.id })
      )
    }
  }, [allCorporations])

  const isearch = search.toLowerCase()

  const corporations = sortBy(
    isearch
      ? filter(allCorporations, (corporation) => {
        return some([
          includes((corporation.companyName || '').toLowerCase(), isearch),
          includes((corporation.teamName || '').toLowerCase(), isearch),
          includes((corporation.email || '').toLowerCase(), isearch),
        ])
      })
      : allCorporations,
    (item) => {
      // console.log( parseDate( item.renewsAt ) );
      return -(parseDate(item.renewsAt) || 0)
    }
  )

  const onSearchByEmail = () => {
    if (search) {
      lookupUsersByEmail(search).then((result) => {
        console.log('lookupUsersByEmail', result)
      })
    }
  }

  const onDownloadCorporationsCsv = () => {
    var blob = new Blob(
      [
        join(
          map(allCorporations, (item) =>
            join(
              [item.id, item.companyName, item.teamName, item.numberOfUsers],
              ','
            )
          ),
          '\r\n'
        ),
      ],
      { type: 'text/plain;charset=utf-8' }
    )
    saveAs(blob, 'corporations.csv')
  }

  // const tempInvoices = [
  //   { id: 1, date: "18 November 2020", amount: `£${(9.99 * corporation.numberOfUsers).toFixed(2)}` },
  // ];

  if (!isSuper) {
    return <LoadingMask enabled={true} />
  }
  const handleLinkClick = (url) => {
    history.push(url)
  }
  return (
    <div className="admin admin-corporations wrapper-admin">
      {/* <AdminHeader disableLinks routes={[]} /> */}
      <WelcomeModal show={showWelcomeModal} setShow={setShowWelcomeModal} />

      <div className="header">
        <Link to={ROUTE_HOME}>
          <img src={Logo} alt="Deliciously Ella Logo" width={140} height={48} />
          <img src={logoutSvg} onClick={logout} alt='logout' className='body-2 logout-text' />
        </Link>
      </div>
      <div className="mobile-selector">
        <Dropdown options={menuItems} />
      </div>
      <div className="row invoice-list">
        <div className="column-menu">
          {isSuper ? (
            <Fragment>
              <div className="list-box">
                <div
                  onClick={() => handleLinkClick(ROUTE_SUPER_ADMIN_USERS)}
                  className="dash-nav">
                  <div className="link action">
                    settings
                  </div>
                </div>
                <div
                  color="none"
                  onClick={onShowCreateCorporation}
                  className="dash-nav"
                >
                  <div className="link action">
                    create team

                  </div>
                </div>
              </div>
              <div className="link action">
                <Link to="/" onClick={logout}>
                  log out
                </Link>
              </div>
            </Fragment>
          ) : null}
        </div>
        <div className="column-main">
          <AdminHeader routes={[]} />
          <div className="member-list">
            <div className="left-box">
              <div className="d-flex search-list">
                <Button
                  onClick={onShowCreateCorporation}
                  className="create-btn"
                >
                  create team
                </Button>
                <Input
                  value={search}
                  onChange={setSearch}
                  className="search-email"
                  icon={searchIcon}
                />
                {isSuper ? (
                  <div className="controls">
                    <Button
                      className="download"
                      onClick={onDownloadCorporationsCsv}
                    >
                      download teams (CSV)
                    </Button>
                    {showCreateCorporation && (
                      <AdminCreateCorporation
                        onClose={onHideCreateCorporation}
                      />
                    )}
                  </div>
                ) : null}
              </div>
              <div className="table-scroll">
                <table>
                  <thead>
                    <tr>
                      <th>
                        <h5>company</h5>
                      </th>
                      <th>
                        <h5>team</h5>
                      </th>
                      <th>
                        <h5>type</h5>
                      </th>
                      <th>
                        <h5>members</h5>
                      </th>
                      <th>
                        <h5>renews at</h5>
                      </th>
                      <th>
                        <h5></h5>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {map(corporations, (corporation, index) => {
                      if (
                        includes(['terminated'], get(corporation, 'status'))
                      ) {
                        return null
                      }

                      const linkToCorporation = createRoutePath(
                        ROUTE_DASHBOARD,
                        { corporationId: corporation.id }
                      )

                      return (
                        <tr key={index}>
                          <td>
                            <Link to={linkToCorporation}>
                              {get(corporation, 'companyName')}
                            </Link>
                          </td>
                          <td>
                            <Link to={linkToCorporation}>
                              {get(corporation, 'teamName')}
                            </Link>
                          </td>
                          <td>{startCase(get(corporation, 'type'))}</td>
                          <td>{get(corporation, 'numberOfUsers')}</td>
                          <td>
                            {moment(
                              get(corporation, 'renewsAt._seconds') * 1000
                            ).format('DD/MM/YYYY')}
                          </td>
                          <td>
                            <ButtonIcon
                              src={downloadIcon}
                              alt="view"
                              to={linkToCorporation}
                            />
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// Need to connect component to Redux

const mapStateToProps = (state) => ({
  corporations: get(state, 'admin.corporations'),
  isSuper: get(state, 'admin.isSuper') ? true : false,
  welcome: get(state, 'admin.welcome'),
})

export default connect(mapStateToProps, {
  loadCorporation,
  lookupUsersByEmail,
  logout,
})(AdminCorporations)

import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import { Link, Route, Switch, Redirect } from 'react-router-dom'

import logo from '../../assets/images/logo.svg'
import rightArrow from '../../assets/images/LongRightArrow.png'

import { saveAs } from 'file-saver'

import moment from 'moment'
import { get, size } from 'lodash-es'

import Button from '../common/Button'
import Input from '../common/Input'

import { FlexRow, FlexColumn } from '../common/Flex'

import Uploader from '../common/Uploader'
import Dashboard from '../layout/DashBoard'

import './SuperAdminRoutes.scss'

import {
  ROUTE_SUPER_ADMIN,
  ROUTE_SUPER_ADMIN_USERS,
  ROUTE_SIGNUP,
  ROUTE_DASHBOARD,
  ROUTE_ADMIN,
} from '../../routes'

import LoadingMask from '../common/LoadingMask'
import SuperAdminUsers from './SuperAdminUsers'
import LogoutButton from '../common/LogoutButton'
import AdminStatusBlocker from '../admin/AdminStatusBlocker'
import SuperAdminHeader from './SuperAdminHeader'
import BackButton from '../common/BackButton'

//assets

// .then((users) => {
//   //present the list of users - compare to existing
//   // const existingUsers =

//   // //we could/should check the csv file now before we upload it
//   // inviteUsers(map(users, (user) => user.email));
//   // onHideInviteUsers();
// })

// fontawesome.library.add(faCheckSquare, faCoffee);
const SuperAdminRoutes = ({ isLoaded, isSuper, isAuthenticated }) => {
  return (
    <LoadingMask loading={!isLoaded}>
      <AdminStatusBlocker className="admin admin-super-main wrapper-admin">
        <SuperAdminHeader />

        <div className="row">
          <div className="column-menu">
            <h2>admin</h2>
            <div className="link action">
              <BackButton to={ROUTE_ADMIN}>exit settings</BackButton>
            </div>

            {/* <div className="link action">
            <LogoutButton />
          </div> */}
          </div>
          <div className="column-main">
            <Switch>
              <Route
                exact
                path={ROUTE_SUPER_ADMIN}
                render={() => {
                  return <Redirect to={ROUTE_SUPER_ADMIN_USERS} />
                }}
              />

              <Route
                exact
                path={ROUTE_SUPER_ADMIN_USERS}
                render={() => {
                  return <SuperAdminUsers />
                }}
              />

              {/* DEFAULT REDIRECT */}
              <Route
                render={() => {
                  return isAuthenticated ? (
                    <Redirect to={ROUTE_SUPER_ADMIN} />
                  ) : (
                    <Redirect to={ROUTE_SIGNUP} />
                  )
                }}
              />
            </Switch>
          </div>
        </div>
      </AdminStatusBlocker>
    </LoadingMask>
  )
}

// Need to connect component to Redux

const mapStateToProps = (state) => ({
  isLoaded: size(get(state, 'admin.corporations')) > 0,
  isSuper: get(state, 'admin.isSuper'),
  isAuthenticated: get(state, 'auth.isAuthenticated'),
})

export default connect(mapStateToProps, {})(SuperAdminRoutes)

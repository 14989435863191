import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { get } from 'lodash-es'
import { login } from '../../actions/auth'
import { ROUTE_FORGOT_PASSWORD, ROUTE_HOME } from '../../routes'

// components
import LoginRedirect from '../auth/LoginRedirect'
import Button from '../common/Button'
import Input from '../common/Input'
import { FlexColumn, FlexRow } from '../common/Flex'

// assets
import Logo from '../../assets/images/reskin/img-reskin_logo.svg'

// styles
import './Login.scss'
import ErrorMessage from '../common/ErrorMessage'
import LoadingMask from '../common/LoadingMask'

const Login = ({ loading, error, login, disabled }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const onSubmit = () => {
    login(email, password)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onSubmit()
    }
  }

  switch (error) {
    case 'There is no user record corresponding to this identifier. The user may have been deleted.':
    case 'The password is invalid or the user does not have a password.':
      error = 'Invalid email or password'
      break
  }

  return (
    <Fragment>
      <LoginRedirect disabled={disabled} />
      <div className="login-layout">
        <FlexRow className="header-row">
          <Link className="logo" to={ROUTE_HOME}>
            <img
              src={Logo}
              alt="Deliciously Ella Logo"
              width={200}
              height={200}
            />
          </Link>
        </FlexRow>
        <div className="body">
          {error && <ErrorMessage className="error mt-1">{error}</ErrorMessage>}
          <LoadingMask enabled={loading} className="form-area">
            <FlexColumn>
              <Input
                autoFocus
                required
                type="email"
                label="Email"
                placeholder="email address"
                value={email}
                onChange={setEmail}
                // autoComplete="given-name"
              />
              <Input
                required
                type="password"
                label="Password"
                placeholder="password"
                value={password}
                onChange={setPassword}
                onKeyDown={handleKeyDown}
                fullWidth
                autoComplete="family-name"
              />

              <Button
                className="login-button"
                color="secondary"
                onClick={onSubmit}
              >
                Login
              </Button>
              <div className="center">
                <Link to={ROUTE_FORGOT_PASSWORD} className="forgot-password">
                  Forgot Password?
                </Link>
              </div>
            </FlexColumn>
          </LoadingMask>
        </div>
      </div>
    </Fragment>
  )
}

Login.propTypes = {
  disabled: PropTypes.bool,
}

Login.defaultProps = {
  disabled: false,
}

const mapStateToProps = (state) => ({
  loading: get(state, 'auth.loading'),
  error: get(state, 'auth.error'),
  isAuthenticated: get(state, 'auth.isAuthenticated'),
})

export default connect(mapStateToProps, { login })(Login)

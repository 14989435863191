import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash-es'
import Button from '../common/Button'

import { setupCorporationSubscription } from '../../actions/corporation'
import { logout } from '../../actions/auth'
import { FormatClassNames } from '../../utils/utils.classnames'

import './AdminStatusBlocker.scss'
import Modal from '../common/Modal'

// fontawesome.library.add(faCheckSquare, faCoffee);
const AdminStatusBlocker = ({
  className,
  children,
  status,
  loaded,
  logout,
  corporationId,
  setupCorporationSubscription,
  isSuper,
}) => {
  const hasFault = !(function () {
    switch (status) {
      //ALL GOOD
      case 'ok':
        return true
      //STILL WORKING THROUGH PAYMENT WORKFLOW
      case 'setup-subscription':
      case 'setup-subscription-failed':
      case 'signup':
        return false
      //ALLOW LEGACY CORPORATIONS TO WORK
      case null:
      case undefined:
        return true
      //SOMETHING ELSE - STOP THEM
      default:
        return true
    }
  })()

  const renderIssueResolution = () => {
    switch (status) {
      case 'setup':
      case 'setup-subscription':
      case 'signup':
        return (
          <Fragment>
            <p>Your account setup has not been completed yet</p>
            <Button
              className="mt-1 retry"
              onClick={() => setupCorporationSubscription(corporationId)}
            >
              Retry Payment Setup
            </Button>
          </Fragment>
        )

      case 'setup-subscription-failed':
        return (
          <Fragment>
            <p>Your account subscription setup failed</p>
            <Button
              className="mt-1 retry"
              onClick={() => setupCorporationSubscription(corporationId)}
            >
              Retry Payment Setup
            </Button>
          </Fragment>
        )

      default:
        return null
    }
  }

  return (
    <div className={FormatClassNames(className)}>
      {children}
      {!isSuper && hasFault && (
        <Modal className="setup-failed">
          <div className="p1 center">{renderIssueResolution()}</div>
          <div className="center mt-1">
            <a className="link" onClick={logout}>
              Log out
            </a>
          </div>
        </Modal>
      )}
    </div>
  )
}

// Need to connect component to Redux

const mapStateToProps = (state) => ({
  corporationId: get(state, 'corporation.data.id'),
  loaded: get(state, 'corporation.data') ? true : false,
  status: get(state, 'corporation.data.status'),
  isSuper: get(state, 'admin.isSuper') ? true : false,
})

export default connect(mapStateToProps, {
  setupCorporationSubscription,
  logout,
})(AdminStatusBlocker)

import React from 'react'

import Button from './Button'
import icoArrowLeft from '../../assets/images/icoArrowRight.svg'

const BackButton = (props) => {
  return <Button icon={icoArrowLeft} variant="none" {...props} />
}

BackButton.propTypes = Button.propTypes

export default BackButton

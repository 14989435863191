import { get, isString } from 'lodash-es'

export const setImageURL = (imageURL) => {
  if (!imageURL) return ''
  let newUrl = ''
  if (imageURL[2] === 'd') {
    newUrl = imageURL.substring(11)
    newUrl = '//images' + newUrl
  }
  if (imageURL[2] === 'i') {
    return imageURL
  }
  return newUrl
}

export const isLocalhost = () => {
  return window.location.hostname === 'localhost' ? true : false
}

// export const formatDate = date => {
//   const dates = {
//     "01": "January",
//     "02": "February",
//     "03": "March",
//     "04": "April",
//     "05": "May",
//     "06": "June",
//     "07": "July",
//     "08": "August",
//     "09": "September",
//     "10": "October",
//     "11": "November",
//     "12": "December"
//   };

//   const nth = function(d) {
//     if (d > 3 && d < 21) return "th";
//     switch (d % 10) {
//       case 1:
//         return "st";
//       case 2:
//         return "nd";
//       case 3:
//         return "rd";
//       default:
//         return "th";
//     }
//   };

//   const month = date.substr(5, 2);
//   let day = date.substr(8, 2);
//   const afterDay = nth(day);
//   const finalMonth = dates[month];
//   if (day[0] === "0") day = day[1];
//   const finalDate = `${day}${afterDay} ${finalMonth}`;
//   return finalDate;
// };

export const extractId = (data) => {
  // THIS IS TO ENSURE THAT DEVELOPER PASSES THE ID AND NOT THE INSTANCE
  // DEFAULTS TO THE DATA IF IT'S A STRING
  return isString(data) ? data : get(data, 'id') || data
}

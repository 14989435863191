import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Dropdown from '../common/Dropdown'

import { updateCorporationSetting } from './../../actions/corporation'
import { logout } from '../../actions/auth'
import './AdminInsights.scss'
import SideNavBar from '../common/SideNavBar'
import { get } from 'lodash-es'
import Logo from '../../assets/images/reskin/img-reskin_logo.svg'
import logoutSvg from '../../assets/images/logout.svg'

import AdminStatusBlocker from './AdminStatusBlocker'
import { ROUTES } from './AdminRoutes'
import {
  ROUTE_HOME,
  ROUTE_DASHBOARD_EDIT_PLAN,
  ROUTE_DASHBOARD,
  ROUTE_DASHBOARD_INSIGHTS,
  ROUTE_DASHBOARD_INVOICES
} from '../../routes'

const menuItems = [
  {
    title: 'account',
    url: ROUTE_DASHBOARD,
  },
  {
    title: 'manage account',
    url: ROUTE_DASHBOARD_EDIT_PLAN,
  },
  {
    title: 'insights',
    url: ROUTE_DASHBOARD_INSIGHTS,

  },
  {
    title: 'invoices',
    url: ROUTE_DASHBOARD_INVOICES,
  },
]

const AdminInsights = ({
  corporation,
  corporationId,
  isSuper,
  isSelfService,
  logout,
}) => {
  return (
    <AdminStatusBlocker className="admin insights-warpper">
      <div className="header">
        <Link to={ROUTE_HOME}>
          <img src={Logo} alt="Deliciously Ella Logo" width={140} height={48} />
          <img src={logoutSvg} onClick={logout} alt='logout' className='body-2 logout-text' />
        </Link>
      </div>
      <div className="mobile-selector">
        <Dropdown options={menuItems} isSelfService={isSelfService} />
      </div>
      <div className="row">
        <SideNavBar
          isSuper={isSuper}
          isSelfService={isSelfService}
          corporation={corporation}
          corporationId={corporationId}
          logout={logout}
        />
        <h2>Coming Soon!</h2>
      </div>
    </AdminStatusBlocker>
  )
}

const mapStateToProps = (state) => ({
  registeredNotificationsInsights: get(
    state,
    'corporation.data.settings.registeredNotificationsInsights'
  ),
  companyName: get(state, 'corporation.data.companyName'),
  corporation: get(state, 'corporation.data'),
  corporationId: get(state, 'corporation.data.id'),
  isSuper: get(state, 'admin.isSuper') ? true : false,
  isSelfService:
    get(state, 'corporation.data.type') === 'selfService' ? true : false,
  routes: ROUTES(state),
})

export default connect(mapStateToProps, {
  updateCorporationSetting,
  logout,
})(AdminInsights)

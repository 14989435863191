import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, Link } from 'react-router-dom'

import { ROUTE_TEST } from '../../routes'
import Login from '../auth/Login'
import Homepage from '../archive_homepage/Homepage'
import AdminInsights from '../admin/AdminInsights'

import { map, first } from 'lodash-es'

import Signup from '../archive_signup/Signup'
import PaymentSuccess from '../payment/PaymentSuccess'
import PaymentCancel from '../payment/PaymentCancel'
import AdminCorporationMain from '../admin/AdminCorporationMain'
import AdminInvoices from '../admin/AdminInvoices'
import AdminEditPlan from '../admin/AdminEditPlan'
import Button from '../common/Button'
import Input from '../common/Input'
import Title from '../common/Title'
import { FlexColumn, FlexRow } from '../common/Flex'
import Modal from '../common/Modal'
import Uploader from '../common/Uploader'
import { parseCsvFile } from '../../utils/parser'

import './Test.scss'
import ForgotPassword from '../auth/ForgotPassword'
import LoadingMask from '../common/LoadingMask'

function Test() {
  const [inputCardDetails, setInputCardDetails] = useState(
    'xxxx-xxxx-xxxx-0123'
  )
  const [inputAddress, setInputAddress] = useState(
    '150 Adam Street, Manchester, M10 3AR'
  )

  return (
    <Fragment>
      <ul className="menu">
        {map(
          {
            home: 'Homepage',
            login: 'Login',
            forgot_password: 'Forgot Password',
            signup: 'Signup',
            signup_success: 'Signup Completed',
            signup_cancelled: 'Signup Cancelled',
            admin_users: 'Admin / Users',
            admin_invoices: 'Admin / Invoices',
            admin_edit_plan: 'Admin / Edit Plan',
            admin_insights: 'Admin / Insights',
            components_buttons: 'Components / Buttons',
            components_inputs: 'Components / Inputs',
            components_modal: 'Components / Modal',
            components_upload: 'Components / Upload',
            components_loading: 'Components / Loading',
          },
          (name, id) => {
            return (
              <li key={id}>
                <Link to={`${ROUTE_TEST}/${id}`}>{name}</Link>
              </li>
            )
          }
        )}
      </ul>
      <Switch>
        {/* THIS IS LIST OF TESTS */}
        {/* HOMEPAGE */}
        <Route exact path={[ROUTE_TEST, `${ROUTE_TEST}/home`]}>
          <Homepage />
        </Route>
        {/* LOGIN */}
        <Route exact path={`${ROUTE_TEST}/login`}>
          <Login disabled />
        </Route>
        {/* FORGOT PASSWORD */}
        <Route exact path={`${ROUTE_TEST}/forgot_password`}>
          <ForgotPassword disabled />
        </Route>
        {/* SIGNUP */}
        <Route exact path={`${ROUTE_TEST}/signup`}>
          <Signup disabled />
        </Route>
        {/* SIGNUP_CALLBACK_SUCCESS */}
        <Route exact path={`${ROUTE_TEST}/signup_success`}>
          <PaymentSuccess disabled />
        </Route>
        {/* SIGNUP_CALLBACK_COMPLETED */}
        <Route exact path={`${ROUTE_TEST}/signup_cancelled`}>
          <PaymentCancel disabled />
        </Route>
        {/* ADMIN_USERS */}
        <Route exact path={`${ROUTE_TEST}/admin_users`}>
          <AdminCorporationMain />
        </Route>
        {/* ADMIN_INVOICES */}
        <Route exact path={`${ROUTE_TEST}/admin_invoices`}>
          <AdminInvoices />
        </Route>
        {/* ADMIN_EDIT_PLAN */}
        <Route exact path={`${ROUTE_TEST}/admin_edit_plan`}>
          <AdminEditPlan />
        </Route>
        {/* INSIGHTS */}
        <Route exact path={`${ROUTE_TEST}/admin_insights`}>
          <AdminInsights />
        </Route>
        {/* COMPONENTS_BUTTONS */}
        <Route exact path={`${ROUTE_TEST}/components_buttons`}>
          <Button color="secondary">Confirm</Button>
          <Button>Change Password</Button>
          <Button>Edit</Button>
        </Route>
        {/* COMPONENTS_BUTTONS */}
        <Route exact path={`${ROUTE_TEST}/components_inputs`}>
          <div className="clearMenu">
            <Title>Billing</Title>
            <Title as="h2">Billing</Title>
            <Title as="h3">Billing</Title>
            <Title as="h4">Billing</Title>
            <Title as="h5">Billing</Title>

            <FlexRow align="end">
              <Input
                className="grow"
                label="Card Detail"
                value={inputCardDetails}
                onChange={setInputCardDetails}
              />
              <Button>Edit</Button>
            </FlexRow>

            <Input
              label="Billing Address"
              value={inputAddress}
              onChange={setInputAddress}
            />
          </div>
        </Route>
        {/* COMPONENTS_MODAL */}
        <Route exact path={`${ROUTE_TEST}/components_modal`}>
          <Modal show={true}>
            <FlexColumn align="stretch" className="p-1">
              <Button className="mb-1">Invite User</Button>
              <Button>Upload Multiple Users</Button>
            </FlexColumn>
          </Modal>
        </Route>

        {/* COMPONENTS_UPLOAD */}
        <Route exact path={`${ROUTE_TEST}/components_upload`}>
          <Modal show={true}>
            <FlexColumn align="stretch" className="p-1">
              <Uploader
                onChange={(files) => {
                  parseCsvFile(first(files)).then((result) => {
                    console.log(result)
                  })
                }}
              >
                <Button>Upload Multiple Users</Button>
              </Uploader>
            </FlexColumn>
          </Modal>
        </Route>

        {/* COMPONENTS_MODAL */}
        <Route exact path={`${ROUTE_TEST}/components_loading`}>
          <LoadingMask enabled={true}>
            <div
              style={{
                backgroundColor: 'red',
                width: 400,
                height: 400,
                margin: 200,
              }}
            >
              Loading
            </div>
          </LoadingMask>
        </Route>
      </Switch>
    </Fragment>
  )
}

// Need to connect component to Redux

export default connect()(Test)

import React, { Fragment } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const RestrictedLanding = ({ openLogin, isAuthenticated }) => {
  if (isAuthenticated) {
    return <Redirect to="/home" />
  }

  return (
    <Fragment>
      <div className="restricted-landing">
        <video
          autoPlay
          muted
          src="https://deliciously-ella.s3.eu-west-2.amazonaws.com/app-header.mp4"
        ></video>
        <div className="action-area">
          <button onClick={() => openLogin(true)}>Login</button>
          <p>
            Donec mollis hendrerit risus. Sed lectus. In consectetuer turpis ut
            velit. Donec interdum, metus et hendrerit aliquet, dolor diam
            sagittis ligula, eget egestas libero turpis vel mi. Nam ipsum risus,
            rutrum vitae, vestibulum eu, molestie vel, lacus. Fusce fermentum
            odio nec arcu. Proin faucibus arcu quis ante. Sed fringilla mauris
            sit amet nibh. Duis leo.
          </p>
          <button>Learn more</button>
        </div>
      </div>
    </Fragment>
  )
}

RestrictedLanding.protoTypes = {
  isAuthenticated: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
})

export default connect(mapStateToProps)(RestrictedLanding)

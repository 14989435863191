import React from 'react'
import './WelcomeModal.scss'
import welcomeCover from '../../assets/images/welcome-cover.png'
import closeSvg from '../../assets/images/closeSvg.svg'
import Button from './Button'

export default function WelcomeModal({ show, setShow }) {
  if (!show) return null

  return (
    <div className="welcome-modal-wrapper">
      <div className="mask" />
      <div className="welcome-modal">
        <div className="left-box">
          <img src={welcomeCover} alt="" />
        </div>
        <div className="right-box">
          <div className="top-close-btn" onClick={() => setShow(false)}>
            <img src={closeSvg} alt="close" />
          </div>
          <h4 className="title">
            Welcome to Deliciously Ella Corporate Membership
          </h4>
          <h5>Thank you for choosing to become part of our community.</h5>

          <h5>
            You can now explore thousands of simple tools for a healthier life.
          </h5>
          <Button className="close-btn" onClick={() => setShow(false)}>
            Close
          </Button>
        </div>
      </div>
    </div>
  )
}

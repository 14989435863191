import React from 'react'

import './Pagination.scss'

const Pagination = ({ setPage, maxPage, currentPage }) => {
  return (
    <div className="pagination">
      <button
        onClick={() => setPage(currentPage - 1)}
        disabled={currentPage <= 0}
      >
        &lt;
      </button>
      <p>
        {currentPage + 1} of {maxPage}
      </p>
      <button
        onClick={() => setPage(currentPage + 1)}
        disabled={currentPage + 1 >= maxPage}
      >
        &gt;
      </button>
    </div>
  )
}

export default Pagination

import React from 'react'

import './Reviews.scss'

import imgReviewCantLive from '../../assets/images/imgReviewCantLive.png'
import imgReviewLifeChanging from '../../assets/images/imgReviewLifeChanging.png'
import imgReviewBestApp from '../../assets/images/imgReviewBestApp.png'

const Reviews = () => {
  return (
    <div className="review-container">
      <img src={imgReviewCantLive} alt="review: can't live without this app" />
      <img src={imgReviewLifeChanging} alt="review: life changing" />
      <img src={imgReviewBestApp} alt="review: best app ever!" />
    </div>
  )
}

export default Reviews

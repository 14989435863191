import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { map, filter, size, slice, isFunction, every } from 'lodash-es'

import './TableList.scss'
import Input from './Input'
import { FlexRow, FlexSpacer } from './Flex'
import Button from './Button'
import Pagination from './Pagination'
import searchIcon from '../../assets/images/search-icon.svg'

const TableList = ({
  items,
  renderer,
  filterer,
  limit,
  onDownloadUsersCsv,
  onShowFilter,
  onClearFilter,
  filters,
  corporation,
  onShowInviteUsers,
  numberOfAvailableInvites,
  vouchers,
}) => {
  const [search, setSearch] = useState('')
  //const [numberItemsPerPage, setNumberOfItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(0)

  useEffect(() => {
    //if search term changes we go back to page 1
    setCurrentPage(0)
  }, [search])

  const itemsFiltered = isFunction(filterer) ? filterer(items, search) : items
  const numberOfPages = Math.ceil(size(itemsFiltered) / limit)

  const itemsOnPage = slice(
    itemsFiltered,
    currentPage * limit,
    (currentPage + 1) * limit
  )

  const totalNumberOfVouchers = size(vouchers)
  return (
    <div className="table-list">
      {isFunction(filterer) && (
        <div className="search-list">
          <Input
            value={search}
            onChange={setSearch}
            className="search-email"
            icon={searchIcon}
          />

          {totalNumberOfVouchers > 0 && (
            <Button className="download body-2" onClick={onDownloadUsersCsv}>
              Download CSV
            </Button>
          )}
          {corporation.status == 'ok' && filterer ? (
            <FlexRow className="table-btn-box">
              {!every(filters) && (
                <Button className="clear-button" onClick={onClearFilter}>
                  clear
                </Button>
              )}
              <Button className="filter-button" onClick={onShowFilter}>
                filter
              </Button>
            </FlexRow>
          ) : (
            ''
          )}
          <Button
            disabled={numberOfAvailableInvites == 0 ? true : false}
            className="invite-button"
            onClick={onShowInviteUsers}
          >
            invite members
          </Button>
        </div>
      )}
      <div className="invites">
        {map(itemsOnPage, renderer)}
        {numberOfPages > 1 && (
          <Pagination
            currentPage={currentPage}
            maxPage={numberOfPages}
            setPage={setCurrentPage}
          />
        )}
      </div>
    </div>
  )
}

TableList.propTypes = {
  items: PropTypes.array.isRequired,
  renderer: PropTypes.func.isRequired,
  filterer: PropTypes.func,
  limit: PropTypes.number.isRequired,
}

TableList.defaultProps = {
  limit: 10,
}

export default TableList

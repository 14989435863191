import { ADMIN_LOADED, SET_WELCOME_FLAG } from './types'
import { db, functions } from '../firebase'

import { first } from 'lodash-es'
import { loadCorporation } from './corporation'
import { logout } from './auth'
import store from '../../src/store'
const corporateSelfService = functions.httpsCallable('corporateSelfService')

// export const loadAdmin = () => async dispatch => {
//   console.log("loadAdmin", uid );
// //   alert('load admin');
//   try {
// 	return db.collection('admins').doc( uid )
// 	  .get()
// 	  .then( async (result) => {
// 		const data = result.data();
// 		console.log('loaded data');
// 		if( !data ){
// 			alert('User is not an Administrator');
// 			return dispatch( logout() )
// 			.then( result => {
// 				return false;
// 			} )
// 		}else{
// 			console.log( data );

// 			dispatch( loadAdminInfo() )
// 		}

// 	} );
//   } catch (error) {}
// };

export const lookupUsersByEmail = (email) => async (dispatch) => {
  return corporateSelfService({
    action: 'search-by-email',
    data: { email },
  })
    .then((result) => result.data || result)
    .then((data) => {
      const { status, result, error } = data

      switch (status) {
        case 'ok':
          return result
          break
        case 'error':
        default:
          throw new Error(error || 'Unknown error')
          break
      }
    })
}

export const loadAdmin = () => async (dispatch) => {
  // return Promise.resolve();
  return corporateSelfService({
    action: 'get-admin-info',
  })
    .then((result) => result.data || result)
    .then((data) => {
      console.log('account-info', data)
      const { status, result, error } = data
      console.log(data)
      switch (status) {
        case 'ok':
          dispatch({
            type: ADMIN_LOADED,
            payload: result,
          })

          return true
          break
        case 'error':
        default:
          alert(error || 'Unknown error')
          dispatch(logout())
          break
      }
    })
}

export const notifyAdmin =
  ({ subject, body }) =>
    async (dispatch) => {
      return corporateSelfService({
        action: 'notify-admin',
        data: {
          subject,
          body,
        },
      })
        .then((result) => result.data || result)
        .then((data) => {
          console.log('notify-admin', data)
          const { status, result, error } = data
          console.log(data)
          switch (status) {
            case 'ok':
              return true
              break
            case 'error':
            default:
              alert(error || 'Unknown error')
              return false
              break
          }
        })
    }

export const setWelcomeFlag = (flag) => async (dispatch) => {
  dispatch({
    type: SET_WELCOME_FLAG,
    payload: flag,
  })
}


import React from 'react'
import PropTypes from 'prop-types'

import { filter, join } from 'lodash-es'

import './Modal.scss'

const Modal = ({ className, onClose, children, size, show }) => {
  if (!show) {
    return null
  }

  const props = {
    className: join(
      filter([className, 'modal', size ? `size-${size}` : null]),
      ' '
    ),
    children,
  }

  return (
    <div className="modal-container">
      <div className="modal-bg" onClick={onClose} />
      <div {...props} />
    </div>
  )
}

Modal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
}

Modal.defaultProps = {
  show: true,
  onClose: () => console.warn('Modal onClose has not been implemented'),
}

export default Modal

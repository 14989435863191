import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { get } from 'lodash-es'
import { connect } from 'react-redux'

import LoadingMask from './LoadingMask'

const LoadingBlocker = ({ className, children, userChecked }) => {
  const ClassName = LoadingMask

  return (
    <ClassName enabled={!userChecked} className={className}>
      {/* BLOCKER ONLY DISPLAYS CHILDREN ONCE USER HAS BEEN CHECKED */}
      {userChecked && children}
    </ClassName>
  )
}

LoadingBlocker.propTypes = {
  enabled: PropTypes.bool,
}

LoadingBlocker.defaultProps = {
  enabled: false,
}

const mapStateToProps = (state) => ({
  userChecked: get(state, 'auth.userChecked'),
})

export default connect(mapStateToProps, {})(LoadingBlocker)

import React from 'react'

import { filter, join } from 'lodash-es'
import PropTypes from 'prop-types'
import Button from '../common/Button'
import './Input.scss'
import { FormatClassNames } from '../../utils/utils.classnames'

function Input({
  className,
  inverted,
  readOnly,
  required,
  autoFocus,
  label,
  value,
  type,
  placeholder,
  onChange,
  onKeyDown,
  children,
  icon,
  haveEdit,
  onEdit,
}) {
  const props = {
    className: FormatClassNames(
      className,
      'input',
      inverted ? 'inverted' : null
    ),
    children,
  }

  const propsInput = {
    placeholder,
    value: value || '',
    readOnly,
    required,
    autoFocus,
    onChange: (evt) => {
      if (!readOnly) {
        onChange(evt.target.value)
      }
    },
    onKeyDown: (evt) => {
      if (onKeyDown != null) {
        onKeyDown(evt)
      }
    },
  }

  return (
    <div {...props}>
      <label>
        {label}
        {required ? ' *' : null}
      </label>
      {icon && <img src={icon} alt="icon" className="icon" />}
      <input {...propsInput} type={type} />
      {haveEdit && (
        <p variant="none" className="edit-button" onClick={() => onEdit()}>
          Edit
        </p>
      )}
    </div>
  )
}

Input.propTypes = {
  readOnly: PropTypes.bool,
  inverted: PropTypes.bool,
  autoFocus: PropTypes.bool,
}

Input.defaultProps = {
  readOnly: false,
  inverted: false,
  autoFocus: false,
}

export default Input

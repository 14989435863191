import React from 'react'
import PropTypes from 'prop-types'

import { filter, join } from 'lodash-es'

import './InputCheckbox.scss'
import { FlexRow } from './Flex'
import { FormatClassNames } from '../../utils/utils.classnames'

const InputCheckbox = ({ className, value, children, onChange }) => {
  return (
    <FlexRow
      autoSize
      align="center"
      className={FormatClassNames('input-checkbox', className)}
    >
      <label
        onClick={() => onChange(!value)}
        className="container"
        id="signup_agree"
      >
        <input
          className="mr-1"
          type="checkbox"
          onChange={(evt) => onChange(evt.target.checked)}
        />
        {children}
      </label>
    </FlexRow>
  )
}

InputCheckbox.propTypes = {
  value: PropTypes.bool.isRequired,
}

InputCheckbox.defaultProps = {}

export default InputCheckbox

import React from 'react'

import { filter, join } from 'lodash-es'

import './Title.scss'

const Title = ({ className, as, children }) => {
  const ClassName = as || 'h1'

  const props = {
    className: join(filter([className, 'title']), ' '),
    children,
  }

  return <ClassName {...props} />
}

Title.propTypes = {}

export default Title

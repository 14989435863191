import React from 'react'
import { Link } from 'react-router-dom'

import { isUndefined, filter, join } from 'lodash-es'
import PropTypes from 'prop-types'

import './ButtonIcon.scss'

const ButtonIcon = ({
  className,
  onClick,
  to,
  href,
  color,
  disabled,
  alt,
  src,
  target,
}) => {
  const isLink = !isUndefined(to) ? true : false
  const ClassName = isLink ? Link : 'a'
  const props = {
    className: join(
      filter([className, 'button-icon', color, disabled ? 'disabled' : null]),
      ' '
    ),
    onClick: !isLink ? onClick : undefined,
    to,
    href,
    target,
  }

  return (
    <ClassName {...props}>
      <img src={src} alt={alt} />
    </ClassName>
  )
}

ButtonIcon.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
}

export default ButtonIcon

import { each } from 'lodash-es'

export function createRoutePath(path, parameters, params) {
  let output = path.toString()

  each(parameters, (value, id) => {
    output = output.replace(`:${id}`, value)
  })

  // if( params ){
  // 	output += '?' + $.param( params );
  // }

  return output
}

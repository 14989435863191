import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Logo from '../../assets/images/reskin/img-reskin_logo.svg'

import LoginRedirect from '../auth/LoginRedirect'
import { passwordReset } from '../../actions/auth'

import { get } from 'lodash-es'

import ErrorMessage from '../common/ErrorMessage'
import Button from '../common/Button'
import Input from '../common/Input'
import { FlexColumn, FlexRow } from '../common/Flex'

import { ROUTE_FORGOT_PASSWORD, ROUTE_HOME, ROUTE_LOGIN } from '../../routes'
import { Link } from 'react-router-dom'

import './Login.scss'
import LoadingMask from '../common/LoadingMask'


const ForgotPassword = ({ passwordReset, disabled }) => {
  const [email, setEmail] = useState('')
  const [complete, setComplete] = useState(false)
  var [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const onSubmit = () => {
    //reset the error
    setError(null)
    setLoading(true)
    //reset using the email provided
    passwordReset(email)
      .then(() => {
        setLoading(false)
        setComplete(true)
      })
      .catch((err) => {
        var error = err.message || err
        switch (error) {
          case 'There is no user record corresponding to this identifier. The user may have been deleted.':
            // error = "Invalid email";
            setError(null)
            setComplete(true)
            // complete = true;
            break
          default:
            setError(error)
            break
        }
        setLoading(false)
      })
  }

  return (
    <Fragment>
      <LoginRedirect disabled={disabled} />
      <div className="login-layout">
        <FlexRow className="header-row">
          <Link className="logo" to={ROUTE_HOME}>
            <img
              src={Logo}
              alt="Deliciously Ella Logo"
              width={200}
              height={200}
            />
          </Link>
        </FlexRow>
        <div className="body">
          {error && <ErrorMessage className="error mt-1">{error}</ErrorMessage>}
          <LoadingMask enabled={loading} className="form-area">
            <FlexColumn>
              {complete ? (
                <div>
                  <p className="center">
                    Password reminder has been sent - please check your email
                  </p>
                </div>
              ) : (
                <Fragment>
                  <Input
                    autoFocus
                    required
                    type="email"
                    label="Email"
                    placeholder="email"
                    value={email}
                    onChange={setEmail}
                  // autoComplete="given-name"
                  />
                  <Button
                    className="login-button"
                    color="secondary"
                    onClick={onSubmit}
                  >
                    Send Reminder
                  </Button>
                </Fragment>
              )}

              <div className="center">
                <Link to={ROUTE_LOGIN} className="forgot-password">
                  &lt; Back to Login
                </Link>
              </div>
            </FlexColumn>
          </LoadingMask>
        </div>
      </div>
    </Fragment>
  )
}

ForgotPassword.propTypes = {
  disabled: PropTypes.bool,
}

ForgotPassword.defaultProps = {
  disabled: true,
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, { passwordReset })(ForgotPassword)

import React from 'react'
import PropTypes from 'prop-types'

import { filter, join, isNumber, isNaN } from 'lodash-es'
import plusSvg from '../../assets/images/plus.svg'
import decrementSvg from '../../assets/images/decrement.svg'
import './InputNumber.scss'
import { FlexRow } from './Flex'
import { FormatClassNames } from '../../utils/utils.classnames'

const InputNumber = ({ className, value, onChange, min, max, disabled }) => {
  const setValue = (value) => {
    onChange(Math.max(min, Math.min(max, value)))
  }

  const increment = () => {
    setValue(value + 1)
  }

  const decrement = () => {
    setValue(value - 1)
  }

  const onInputChange = (evt) => {
    var value = Number(
      join(
        filter(evt.target.value, (i) => /[0-9]/.test(i)),
        ''
      )
    )
    if (isNumber(value) && !isNaN(value)) {
      setValue(value)
    }
  }

  const handleFocus = (evt) => {
    evt.target.select()
  }

  return (
    <FlexRow autoSize className={FormatClassNames('input-number', className)}>
      <button disabled={disabled} className='button-text' onClick={increment}>
        <img src={plusSvg} alt='plus' width={24} height={24} />
      </button>

      <label id="signup_quant" className="body-2">
        <input
          type="text"
          value={value || ''}
          onChange={onInputChange}
          onFocus={handleFocus}
        />
        members
      </label>
      <button disabled={disabled} className='button-text' onClick={decrement}>
        <img src={decrementSvg} alt='decrement' width={24} height={24} />
      </button>
    </FlexRow>
  )
}

InputNumber.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  disabled: PropTypes.bool.isRequired,
}

InputNumber.defaultProps = {
  min: 1,
  max: Infinity,
  disabled: false,
}

export default InputNumber

import {
  CORPORATION_UNLOADED,
  CORPORATION_INVOICES_UPDATED,
  CORPORATION_LOADED,
  CORPORATION_VOUCHERS_UPDATED,
  CORPORATION_SUBSCRIPTION_USAGE_UPDATED,
  CORPORATION_SIGNUP_INFO_UPDATED,
  INVITES_UPLOAD_STARTED,
  INVITES_UPLOAD_PROGRESS,
  INVITES_UPLOAD_INCOMPLETE,
  INVITES_UPLOAD_COMPLETED,
} from './types'
import { db, functions } from '../firebase'

import { extractId, isLocalhost } from '../utils/utils'

import { filter, pick, map, get, times, size, chunk, flatten } from 'lodash-es'
import { redirectToCheckout } from '../stripe'

import Promise from 'bluebird'

import {
  isEmailValid,
  assertEmailValid,
  isPasswordValid,
  assertPasswordValid,
  isTeamNameValid,
  assertTeamNameValid,
  isCompanyNameValid,
  assertCompanyNameValid,
} from './../utils/utils.validator'
import { ROUTE_PAYMENT_CANCEL, ROUTE_PAYMENT_SUCCESS } from '../routes'

import { auth } from '../firebase'
import { loadAdmin } from './admin'

const corporateSelfService = functions.httpsCallable('corporateSelfService')

export const wakeupCorporationService = () => async (dispatch) => {
  return corporateSelfService({
    action: 'wakeup',
  })
}

export const getCorporationSignUpInfo = () => async (dispatch) => {
  return corporateSelfService({
    action: 'get-signup-info',
  })
    .then((result) => result.data)
    .then(({ status, result, error }) => {
      // console.log('signup-info', status, result, error);
      dispatch({
        type: CORPORATION_SIGNUP_INFO_UPDATED,
        payload: result,
      })
    })
    .catch((error) => {
      console.error('Error occurred:', error)
      // Dispatch another action to handle the failure case
      dispatch({
        type: CORPORATION_SIGNUP_INFO_UPDATED,
        error: error,
      })
    })
}

export const terminateCorporation =
  (corporationId, data) => async (dispatch) => {
    corporationId = extractId(corporationId)
    return corporateSelfService({
      action: 'terminate-account',
      corporationId,
      data,
    })
      .then((result) => result.data)
      .then((data) => {
        console.log('result', data)
        const { status, result, error } = data
        switch (status) {
          case 'ok':
            //unload the existing corporation
            dispatch(unloadCorporation())
            //reload admin info
            dispatch(loadAdmin())
            break
          default:
            alert(error || 'Unknown error')
            break
        }
      })
  }

export const switchCorporationToEnterprise =
  (corporationId) => async (dispatch) => {
    corporationId = extractId(corporationId)
    return corporateSelfService({
      action: 'switch-account',
      corporationId,
      data: {
        type: 'enterprise',
      },
    })
      .then((result) => result.data)
      .then((data) => {
        console.log('result', data)
        const { status, result, error } = data
        switch (status) {
          case 'ok':
            //reload the existing corporations
            dispatch(loadAdmin())
            dispatch(
              loadCorporation(corporationId, { disableVoucherUpdate: true })
            )
            break
          default:
            alert(error || 'Unknown error')
            break
        }
      })
  }

export const createCorporation =
  ({
    type = 'selfService',
    email,
    password,
    teamName,
    companyName,
    numberOfUsers,
    activationLimit,
    country,
    product,
  }) =>
  async (dispatch) => {
    console.log(
      'createCorporation',
      email,
      password,
      teamName,
      companyName,
      numberOfUsers
    )

    assertEmailValid(email)
    assertCompanyNameValid(companyName)
    assertCompanyNameValid(teamName)

    switch (type) {
      case 'selfService':
        assertPasswordValid(password)
        console.assert(product, 'createCorporation requires product')
        break
    }

    console.assert(country, 'createCorporation requires country')

    const productId = extractId(product)

    const data = {
      type,
      email,
      password,
      teamName,
      companyName,
      numberOfUsers,
      activationLimit,
      country,
      productId,
    }

    return corporateSelfService({
      action: 'setup',
      data,
    })
      .then((result) => result.data || result)
      .then(async (data) => {
        const { status, result, error } = data
        console.log(data)
        switch (status) {
          case 'ok':
            const { corporationId, userId } = result
            console.assert(
              corporationId,
              'Expected a result to contain corporationId'
            )

            switch (type) {
              case 'selfService':
                console.assert(userId, 'Expected a result to contain userId')
                //IF WE SIGN IN THE USER NOW THEY WILL RETURN TO THE PAYMENT SUCCESS SCREEN AND AUTOMATICALLY CONTINUE
                await auth.signInWithEmailAndPassword(email, password)
                // now we set up the subscription
                return dispatch(
                  setupCorporationSubscription(corporationId, productId)
                )
                break
              case 'enterprise':
              default:
                dispatch(loadAdmin())
                break
            }
            break
          case 'error':
          default:
            throw new Error(error || 'Unknown error')
            break
        }
      })
  }

export const setupCorporationOffSessionPayment =
  (corporationId, cancelUrl) => async (dispatch) => {
    corporationId = extractId(corporationId)

    console.assert(
      corporationId,
      'setupCorporationOffSessionPayment requires corporationId'
    )
    //if will use the default on the account if needed
    // console.assert( productId, 'setupCorporationPaymentDetails requires productId' );

    return Promise.resolve().then(() => {
      //MAKE REQUEST FOR UPDATE TO CORPORATION PAYMENT
      const currentDomain = `${window.location.origin}`

      corporateSelfService({
        action: 'setup-payment',
        corporationId,
        data: {
          successUrl: `${currentDomain}${ROUTE_PAYMENT_SUCCESS}?session_id={CHECKOUT_SESSION_ID}`,
          cancelUrl:
            cancelUrl ||
            `${currentDomain}${ROUTE_PAYMENT_CANCEL}?session_id={CHECKOUT_SESSION_ID}`,
        },
      })
        .then((result) => result.data || result)
        .then((data) => {
          const { status, result, error } = data
          console.log(data)
          switch (status) {
            case 'ok':
              const { setupSessionId } = result
              redirectToCheckout(setupSessionId)
              break
            case 'error':
            default:
              throw new Error(error || 'Unknown error')
              break
          }
        })
    })
  }

export const setupCorporationSubscription =
  (corporationId, productId, cancelUrl) => async (dispatch) => {
    corporationId = extractId(corporationId)
    productId = extractId(productId)

    console.assert(
      corporationId,
      'setupCorporationPaymentDetails requires corporationId'
    )
    //if will use the default on the account if needed
    // console.assert( productId, 'setupCorporationPaymentDetails requires productId' );

    return Promise.resolve().then(() => {
      //MAKE REQUEST FOR UPDATE TO CORPORATION PAYMENT
      const currentDomain = `${window.location.origin}`

      return corporateSelfService({
        action: 'setup-subscription',
        corporationId,
        data: {
          productId,
          successUrl: `${currentDomain}${ROUTE_PAYMENT_SUCCESS}?session_id={CHECKOUT_SESSION_ID}&corporationId=${corporationId}`,
          cancelUrl:
            cancelUrl ||
            `${currentDomain}${ROUTE_PAYMENT_CANCEL}?session_id={CHECKOUT_SESSION_ID}&corporationId=${corporationId}`,
        },
      })
        .then((result) => result.data || result)
        .then((data) => {
          const { status, result, error } = data
          console.log(data)
          switch (status) {
            case 'ok':
              const { setupSessionId } = result

              console.assert(
                setupSessionId,
                'Expected a setupSessionId to start the shopping cart'
              )

              if (isLocalhost()) {
                if (!window.confirm('continue to checkout?')) {
                  return false
                }
              }
              //continue to the checkout
              redirectToCheckout(setupSessionId)
              break
            case 'error':
            default:
              throw new Error(error || 'Unknown error')
              break
          }
        })
    })
  }

export const completeCorporationPayment =
  (corporationId, { setupSessionId }) =>
  async (dispatch) => {
    return corporateSelfService({
      action: 'complete-payment',
      data: {
        setupSessionId,
      },
    })
      .then((result) => result.data)
      .then(({ result, status, error }) => {
        switch (status) {
          case 'error':
            console.error(error)
            alert(error)
            break
          case 'ok':
            // dispatch({
            // 	type: CORPORATION_LOADED,
            // 	payload: {
            // 		id: corporationId,
            // 		...result
            // 	}
            // });
            break
        }

        //also load the corporation vouchers
        dispatch(loadCorporation(corporationId, { disableVoucherUpdate: true }))
      })
  }

export const unloadCorporation = () => async (dispatch) => {
  dispatch({
    type: CORPORATION_UNLOADED,
  })
}

export const loadCorporation =
  (corporationId, { disableVoucherUpdate = false } = {}) =>
  async (dispatch, getState) => {
    corporationId = extractId(corporationId)

    //check that we don't currently have another corporation loaded
    const currentCorporationId = get(getState(), 'corporation.data.id')

    if (currentCorporationId && corporationId != currentCorporationId) {
      dispatch(unloadCorporation())
    }

    try {
      return corporateSelfService({
        action: 'get-account-info',
        corporationId,
      })
        .then((result) => result.data)
        .then(({ result, status, error }) => {
          switch (status) {
            case 'error':
              console.error(error)
              alert(error)
              break
            case 'ok':
              dispatch({
                type: CORPORATION_LOADED,
                payload: {
                  id: corporationId,
                  ...result,
                },
              })
              break
          }

          //also load the corporation vouchers
          if (!disableVoucherUpdate) {
            dispatch(loadCorporationVouchers(corporationId))
          }
        })
    } catch (error) {}
  }

export const loadCorporationVouchers = (corporationId) => async (dispatch) => {
  corporationId = extractId(corporationId)

  corporateSelfService({
    action: 'get-vouchers',
    corporationId,
  })
    .then((result) => result.data)
    .then(({ status, result, error }) => {
      console.log('corporationVouchers', error, result)
      //filter the vouchers if has email
      result = filter(result, (voucher) =>
        voucher && voucher.email ? true : false
      )
      dispatch({
        type: CORPORATION_VOUCHERS_UPDATED,
        payload: result,
      })
    })
}

export const loadCorporationSubscriptionUsage =
  (corporationId) => async (dispatch) => {
    corporationId = extractId(corporationId)

    corporateSelfService({
      action: 'get-account-subscription-usage',
      corporationId,
    })
      .then((result) => result.data)
      .then(({ status, result, error }) => {
        console.log('corporationSubscriptionUsage', error, result)
        //filter the vouchers if has email
        dispatch({
          type: CORPORATION_SUBSCRIPTION_USAGE_UPDATED,
          payload: result,
        })
      })
  }

export const loadCorporationInvoices = (corporationId) => async (dispatch) => {
  console.log('loadCorporationInvoices', corporationId)
  corporationId = extractId(corporationId)

  corporateSelfService({
    action: 'get-account-charges',
    corporationId,
  })
    .then((result) => result.data)
    .then(({ status, result, error }) => {
      console.log('corporationInvoices', error, result)
      //filter the vouchers if has email
      // result = filter( result, voucher => voucher && voucher.email ? true : false )
      dispatch({
        type: CORPORATION_INVOICES_UPDATED,
        payload: result,
      })
    })
}

export const createCorporateVouchers =
  (corporationId, emails, { reloadVouchers = true } = {}) =>
  async (dispatch) => {
    corporationId = extractId(corporationId)

    const batches = chunk(emails, 10)
    const totalNumberOfBatches = size(batches)

    dispatch({ type: INVITES_UPLOAD_STARTED })

    return Promise.mapSeries(batches, (emails, index) => {
      dispatch({
        type: INVITES_UPLOAD_PROGRESS,
        payload: index / totalNumberOfBatches,
      })

      return corporateSelfService({
        action: 'create-vouchers',
        corporationId,
        data: {
          emails,
        },
      })
        .then((result) => result.data)
        .then(({ status, result, error }) => {
          if (error) {
            alert(error)
            dispatch({ type: INVITES_UPLOAD_COMPLETED })
            throw new Error(error)
          } else {
            return result
          }
          // else if( reloadVouchers ){
          // 	dispatch( loadCorporationVouchers( corporationId ) );
          // }
        })
    })
      .then((result) => flatten(result))
      .then((result) => {
        dispatch({ type: INVITES_UPLOAD_COMPLETED })
        dispatch(loadCorporationVouchers(corporationId))
      })
  }

export const resendCorporateVoucher =
  (corporationId, voucher) => async (dispatch) => {
    corporationId = extractId(corporationId)
    resendCorporateVouchers(corporationId, [extractId(voucher)])
  }
export const resendCorporateVouchers =
  (corporationId, vouchers) => async (dispatch) => {
    //ensure format is validated
    corporationId = extractId(corporationId)
    vouchers = map(vouchers, extractId)

    return corporateSelfService({
      action: 'invite-vouchers',
      corporationId,
      data: {
        vouchers,
      },
    })
      .then((result) => result.data)
      .then(({ status, result, error }) => {
        console.log(status, error, result)
        if (error) {
          throw new Error(error)
        }
      })
  }

export const removeCorporationVouchers =
  (corporationId, vouchers) => async (dispatch) => {
    corporationId = extractId(corporationId)
    vouchers = map(vouchers, extractId)

    return corporateSelfService({
      action: 'remove-vouchers',
      corporationId,
      data: {
        vouchers,
      },
    })
      .then((result) => result.data)
      .then(({ status, result, error }) => {
        if (error) {
          throw new Error(error)
        } else {
          dispatch(loadCorporationVouchers(corporationId))
        }
      })
  }

export const updateCorporationSetting =
  (corporationId, id, value) => async (dispatch) => {
    dispatch(updateCorporationSettings(corporationId, { [id]: value }))
  }

export const updateCorporationSettings =
  (corporationId, settings) => async (dispatch) => {
    return corporateSelfService({
      action: 'update-account-settings',
      corporationId,
      data: settings,
    })
      .then((result) => result.data)
      .then(() => {
        dispatch(loadCorporation(corporationId, { disableVoucherUpdate: true }))
      })
  }

export const updateCorporationPlanSize =
  (corporationId, { numberOfUsers, activationLimit, cancelRoute }) =>
  async (dispatch) => {
    const currentDomain = `${window.location.origin}`

    return corporateSelfService({
      action: 'update-number-of-users',
      corporationId,
      data: {
        numberOfUsers,
        activationLimit,
        successUrl: `${currentDomain}${ROUTE_PAYMENT_SUCCESS}?session_id={CHECKOUT_SESSION_ID}`,
        cancelUrl: `${currentDomain}${
          cancelRoute || ROUTE_PAYMENT_CANCEL
        }?session_id={CHECKOUT_SESSION_ID}`,
      },
    })
      .then((result) => result.data)
      .then(({ result, status, error }) => {
        //if we need to take action there will be a setupSessionIf
        console.log(result, status, error)
        switch (status) {
          case 'ok':
            //SUBSCRIPTION SIZE CHANGED IN THE BACKGROUND - WE DON'T NEED TO MAKE THEM PAY

            // const setupSessionId = get(result, 'setupSessionId');
            // if( setupSessionId ){
            // 	//we need to pay first
            // 	redirectToCheckout(setupSessionId);
            // }else{
            //load for changes to the corporation
            dispatch(
              loadCorporation(corporationId, { disableVoucherUpdate: true })
            )
            //load for additional invoices that have been raised
            dispatch(loadCorporationInvoices(corporationId))
            // }
            break
          case 'error':
          default:
            throw new Error(error || 'Unknown error')
            break
        }
      })
  }

export const quoteUpdateCorporationPlanSize =
  (corporationId, { numberOfUsers }) =>
  async (dispatch) => {
    return corporateSelfService({
      action: 'quote-update-number-of-users',
      corporationId,
      data: {
        numberOfUsers,
      },
    })
      .then((result) => result.data)
      .then(({ result, status, error }) => {
        //if we need to take action there will be a setupSessionIf
        console.log(result, status, error)
        switch (status) {
          case 'ok':
            return result
            break
          case 'error':
          default:
            throw new Error(error || 'Unknown error')
            break
        }
      })
  }

export const updateCorporationDetails =
  (corporationId, details) => async (dispatch) => {
    return corporateSelfService({
      action: 'update-account-info',
      corporationId,
      data: details,
    })
      .then((result) => result.data)
      .then(({ result, status, error }) => {
        //if we need to take action there will be a setupSessionIf
        console.log(result, status, error)
        switch (status) {
          case 'ok':
            dispatch(
              loadCorporation(corporationId, { disableVoucherUpdate: true })
            )
            return result
            break
          case 'error':
          default:
            throw new Error(error || 'Unknown error')
            break
        }
      })
  }

export const editCorporationPaymentMethod =
  (corporationId) => async (dispatch) => {
    const currentDomain = `${window.location.origin}`

    return corporateSelfService({
      action: 'update-account-payment-method',
      corporationId,
      data: {
        // successUrl: `${currentDomain}${ROUTE_PAYMENT_SUCCESS}?session_id={CHECKOUT_SESSION_ID}`,
        // cancelUrl: `${currentDomain}${ROUTE_PAYMENT_CANCEL}?session_id={CHECKOUT_SESSION_ID}`
        successUrl: `${window.location.href}?session_id={CHECKOUT_SESSION_ID}`,
        cancelUrl: `${window.location.href}?session_id={CHECKOUT_SESSION_ID}`,
      },
    })
      .then((result) => result.data)
      .then(({ result, status, error }) => {
        //if we need to take action there will be a setupSessionIf
        console.log(result, status, error)
        switch (status) {
          case 'ok':
            const { setupSessionId } = result
            console.assert(
              setupSessionId,
              'updateCorporationPaymentMethod expected a setupSessionId'
            )
            //we need to pay first
            redirectToCheckout(setupSessionId)
            break
          case 'error':
          default:
            throw new Error(error || 'Unknown error')
            break
        }
      })
  }

import {
  SIGNUP_COMPLETE,
  SIGNUP_ERROR,
  SIGNUP_START,
  SIGNUP_LOCATION_UPDATED,
  CORPORATION_SIGNUP_INFO_UPDATED,
} from '../actions/types'

const initialState = {
  info: null,
  location: null,
  complete: false,
  error: null,
  loading: false,
}

export default function (state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case CORPORATION_SIGNUP_INFO_UPDATED:
      console.log(payload)
      return {
        ...state,
        info: payload,
      }
    case SIGNUP_START:
      return {
        ...state,
        loading: true,
        complete: false,
        error: null,
      }
    case SIGNUP_COMPLETE:
      return {
        ...state,
        loading: false,
        complete: true,
        error: null,
      }
    case SIGNUP_ERROR:
      return {
        ...state,
        complete: false,
        loading: false,
        error: payload,
      }
    case SIGNUP_LOCATION_UPDATED:
      return {
        ...state,
        location: payload,
      }

    default:
      return state
  }
}

import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { parseCsvEmailList } from '../../utils/parser'
import Button from '../common/Button'
import Input from '../common/Input'
import Uploader from '../common/Uploader'

import PropTypes from 'prop-types'
import Modal from '../common/Modal'

import {
  find,
  first,
  filter,
  get,
  isEmpty,
  pick,
  size,
  map,
  merge,
  slice,
  orderBy,
  indexOf,
} from 'lodash-es'

import { terminateCorporation } from '../../actions/corporation'

import { FlexRow, FlexSpacer } from '../common/Flex'

import './AdminTerminateCorporation.scss'

const AdminTerminateCorporation = ({
  terminateCorporation,
  corporation,
  show,
  onClose,
}) => {
  const [note, setNote] = useState('')

  const onSubmit = () => {
    if (
      window.confirm(
        'Continuing will remove all access to all existing users and delete all their vouchers'
      )
    ) {
      terminateCorporation(corporation, { note })
    }
  }

  return (
    <Modal className="terminate-admin " show={show} onClose={onClose}>
      <h2>confirm delete team</h2>
      <p>please give your reason for deleting this team</p>
      <textarea
        value={note}
        onChange={(evt) => setNote(evt.target.value)}
      ></textarea>
      <p>are you sure you want to delete this team?</p>
      <FlexRow>
        <FlexSpacer />
        {onClose && (
          <Button onClick={onClose} className="cancel-button">
            cancel
          </Button>
        )}
        <Button onClick={onSubmit} className="delete-button">
          yes, delete
        </Button>
      </FlexRow>
    </Modal>
  )
}

AdminTerminateCorporation.propTypes = {
  onClose: PropTypes.func,
  show: PropTypes.bool,
}

AdminTerminateCorporation.defaultProps = {
  show: true,
}

const mapStateToProps = (state) => ({
  corporation: get(state, 'corporation.data'),
})

export default connect(mapStateToProps, {
  terminateCorporation,
})(AdminTerminateCorporation)

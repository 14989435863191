import React, { useState } from 'react'
import { connect } from 'react-redux'
import Button from '../common/Button'
import Input from '../common/Input'
import Uploader from '../common/Uploader'

import PropTypes from 'prop-types'
import Modal from '../common/Modal'

import {
  find,
  first,
  filter,
  get,
  isEmpty,
  pick,
  size,
  map,
  merge,
  slice,
  orderBy,
  indexOf,
} from 'lodash-es'

import { switchCorporationToEnterprise } from '../../actions/corporation'

import { FlexRow, FlexSpacer } from '../common/Flex'

import './AdminSwitchCorporation.scss'
import LoadingMask from '../common/LoadingMask'

const AdminSwitchCorporation = ({
  switchCorporationToEnterprise,
  corporation,
  show,
  onClose,
}) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const onSubmit = () => {
    if (
      window.confirm(
        'Continuing will switch the account over to an enterprise account and is not reversible'
      )
    ) {
      setLoading(true)
      setError('')
      switchCorporationToEnterprise(corporation, {})
        .then(() => {
          setLoading(false)
          onClose()
        })
        .catch((err) => {
          setError(err.message)
        })
    }
  }

  return (
    <Modal className="switch-admin" show={show} onClose={onClose}>
      <LoadingMask enabled={loading}>
        <p>Warning: Switching to an enterprise account is not reversible.</p>
        <br />
        <FlexRow>
          {onClose && <Button onClick={onClose}>Cancel</Button>}
          <FlexSpacer />
          <Button onClick={onSubmit}>Submit</Button>
        </FlexRow>
      </LoadingMask>
    </Modal>
  )
}

AdminSwitchCorporation.propTypes = {
  onClose: PropTypes.func,
  show: PropTypes.bool,
}

AdminSwitchCorporation.defaultProps = {
  show: true,
}

const mapStateToProps = (state) => ({
  corporation: get(state, 'corporation.data'),
})

export default connect(mapStateToProps, {
  switchCorporationToEnterprise,
})(AdminSwitchCorporation)

import React, { useState } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import {
  ROUTE_ADMIN,
  ROUTE_DASHBOARD_INVOICES,
  ROUTE_DASHBOARD_EDIT_PLAN,
  ROUTE_DASHBOARD,
  ROUTE_DASHBOARD_INSIGHTS,
} from '../../routes'
import BackButton from '../common/BackButton'
import { createRoutePath } from '../../utils/utils.routes'
import Button from '../common/Button'
import AdminTerminateCorporation from '../admin/AdminTerminateCorporation'

const menuItems = [
  {
    title: 'account',
    url: ROUTE_DASHBOARD,
  },
  {
    title: 'manage account',
    url: ROUTE_DASHBOARD_EDIT_PLAN,
  },
  {
    title: 'insights',
    url: ROUTE_DASHBOARD_INSIGHTS,
  },
]
export default function SideNavBar({
  isSuper,
  isSelfService,
  corporation,
  corporationId,
  logout,
}) {
  const location = useLocation()
  const history = useHistory()
  const urlLastSegment = location.pathname.split('/').pop()
  const [showTerminateCorporation, setShowTerminateCorporation] =
    useState(false)

  const onShowTerminateCorporation = () => {
    setShowTerminateCorporation(true)
  }
  const onHideTerminateCorporation = () => {
    setShowTerminateCorporation(false)
  }
  const handleLinkClick = (url) => {
    history.push(url)
  }
  return (
    <div className="column-menu">
      <div className="list-box">
        {isSuper ? (
          <div className="link action">
            <BackButton to={ROUTE_ADMIN}>back</BackButton>
          </div>
        ) : null}
        {menuItems.map((item, index) => {
          return (
            <div
              onClick={() => handleLinkClick(item.url)}
              className="dash-nav"
              key={item.title}
            >
              <p
                key={index}
                className={`link action ${
                  `${urlLastSegment}` === item.url.split('/').pop()
                    ? 'selected'
                    : ''
                }`}
              >
                {item.title}
              </p>
            </div>
          )
        })}
      </div>
      <div className="list-box">
        {isSelfService && (
          <div className="link action">
            <Link
              to={createRoutePath(ROUTE_DASHBOARD_INVOICES, { corporationId })}
              className="dash-nav"
            >
              <Button color="none">invoices</Button>
            </Link>
          </div>
        )}
        <div onClick={logout}>
          <div className="link action">log out</div>
        </div>
        {isSuper && (
          <div className="link action">
            <Button color="none" onClick={onShowTerminateCorporation}>
              delete team
            </Button>
          </div>
        )}
      </div>
      {showTerminateCorporation && (
        <AdminTerminateCorporation onClose={onHideTerminateCorporation} />
      )}
    </div>
  )
}

import React from 'react'

import { filter, join, map } from 'lodash-es'
import PropTypes from 'prop-types'

import './InputSelect.scss'
import { FormatClassNames } from '../../utils/utils.classnames'

function InputSelect({
  className,
  inverted,
  readOnly,
  required,
  autoFocus,
  label,
  options,
  value,
  type,
  placeholder,
  onChange,
  onKeyDown,
  children,
}) {
  const props = {
    className: FormatClassNames(
      className,
      'input-select',
      inverted ? 'inverted' : null
    ),
    children,
  }

  const propsInputSelect = {
    placeholder,
    value: value || '',
    readOnly,
    required,
    autoFocus,
    onChange: (evt) => {
      if (!readOnly) {
        console.log(evt.target.value)
        onChange(evt.target.value)
      }
    },
    onKeyDown: (evt) => {
      if (onKeyDown != null) {
        onKeyDown(evt)
      }
    },
  }

  return (
    <div {...props}>
      <label>
        {label}
        {required ? ' *' : null}
      </label>
      <select {...propsInputSelect} type={type}>
        {map(options, (option, index) => {
          return (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          )
        })}
      </select>
    </div>
  )
}

InputSelect.propTypes = {
  readOnly: PropTypes.bool,
  inverted: PropTypes.bool,
  autoFocus: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
}

InputSelect.defaultProps = {
  readOnly: false,
  inverted: false,
  autoFocus: false,
}

export default InputSelect

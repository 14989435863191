import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Lottie from 'lottie-react-web'

import { filter, join } from 'lodash-es'

import './LogoutButton.scss'
import rightArrow from '../../assets/images/LongRightArrow.png'

import { FormatClassNames } from '../../utils/utils.classnames'
import { logout } from '../../actions/auth'
import { connect } from 'react-redux'

const LogoutButton = ({ className, children, logout }) => {
  const props = {
    className: FormatClassNames(className, 'logout-button'),
  }

  return (
    <div className="dash-nav logout-button" onClick={logout}>
      <p>logout</p>
    </div>
  )
}

LogoutButton.propTypes = {}

LogoutButton.defaultProps = {}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, {
  logout,
})(LogoutButton)

import {} from '../actions/types'

const initialState = {}

export default function (state = initialState, action) {
  const { type } = action

  switch (type) {
    // case GET_LATEST_ITEMS:
    //   return {
    //     ...state,
    //     latestThoughts: payload.articles,
    //     latestRecipes: payload.recipes,
    //     thoughtsLoading: false
    //   };
    default:
      return state
  }
}

import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import copyLink from '../../assets/images/copy-link.svg'
import icoClose from '../../assets/images/icoClose.png'
import circleTick from '../../assets/images/circle-tick.png'
import { saveAs } from 'file-saver'
import moment from 'moment'
import {
  map,
  join,
  filter,
  get,
  size,
  orderBy,
  includes,
  lowerCase,
  every,
} from 'lodash-es'
import {
  unloadCorporation,
  createCorporateVouchers,
  resendCorporateVouchers,
  removeCorporationVouchers,
  setupCorporationOffSessionPayment,
} from '../../actions/corporation'
import { setWelcomeFlag } from '../../actions/admin'

import Button from '../common/Button'
import Dropdown from '../common/Dropdown'
import Modal from '../common/Modal'
import SideNavBar from '../common/SideNavBar'

import { FlexRow, FlexSpacer } from '../common/Flex'

import './AdminCorporationMain.scss'
import AdminHeader from './AdminHeader'
import {
  ROUTE_HOME,
  ROUTE_DASHBOARD_EDIT_PLAN,
  ROUTE_DASHBOARD,
  ROUTE_DASHBOARD_INSIGHTS,
  ROUTE_DASHBOARD_INVOICES
} from '../../routes'
import LoadingMask from '../common/LoadingMask'

//assets
import removeIcon from '../../assets/images/remove.svg'
import AdminStatusBlocker from './AdminStatusBlocker'
import { formatEmail } from '../../utils/utils.format'
import { isEmailValid } from '../../utils/utils.validator'
import TableList from '../common/TableList'
import AdminUserUpload from './AdminUserUpload'
import { copyTextToClipboard } from '../../utils/util.clipboard'
import ToolTip from '../common/ToolTip'
import Logo from '../../assets/images/reskin/img-reskin_logo.svg'
import { logout } from '../../actions/auth'
import WelcomeModal from '../common/WelcomeModal'
import { ROUTES } from './AdminRoutes'
import { FormatClassNames } from '../../utils/utils.classnames'
import _ from 'lodash'
import logoutSvg from '../../assets/images/logout.svg'


const DEFAULT_FILTERS = () => ({
  inactive: true,
  active: true,
})
const menuItems = [
  {
    title: 'account',
    url: ROUTE_DASHBOARD,
  },
  {
    title: 'manage account',
    url: ROUTE_DASHBOARD_EDIT_PLAN,
  },
  {
    title: 'insights',
    url: ROUTE_DASHBOARD_INSIGHTS,

  },
  {
    title: 'invoices',
    url: ROUTE_DASHBOARD_INVOICES,
  },
]
// .then((users) => {
//   //present the list of users - compare to existing
//   // const existingUsers =

//   // //we could/should check the csv file now before we upload it
//   // inviteUsers(map(users, (user) => user.email));
//   // onHideInviteUsers();
// })

// fontawesome.library.add(faCheckSquare, faCoffee);
const AdminCorporationMain = ({
  routes,
  isSuper,
  corporation,
  corporationId,
  vouchers,
  corporations,
  subscription,
  subscriptionPlan,
  paymentLast4,
  paymentBrand,
  companyName,
  email,
  unloadCorporation,
  setupCorporationOffSessionPayment,
  createCorporateVouchers,
  resendCorporateVouchers,
  removeCorporationVouchers,
  isSelfService,
  logout,
  welcome,
  setWelcomeFlag
}) => {

  const [showInviteMoreUsers, setShowInviteMoreUsers] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [filters, setFilters] = useState(DEFAULT_FILTERS())
  const [filtersEdit, setFiltersEdit] = useState(DEFAULT_FILTERS())
  const [showWelcomeModal, setShowWelcomeModal] = useState(false)
  const onHideInviteUsers = () => {
    setShowInviteMoreUsers(null)
  }

  const onShowInviteUsers = () => {
    setShowInviteMoreUsers(true)
  }

  // process CSV data

  const inviteUser = (email) => {
    return inviteUsers([email])
  }



  useEffect(() => {
    if (welcome) {
      setShowWelcomeModal(true)
      setWelcomeFlag(false)
    };
  }, []);

  const inviteUsers = (emails) => {
    //check all the email addresses before continuing
    if (
      !every(emails, (email) => {
        if (!isEmailValid(email)) {
          alert(`'${email}' is not valid. Check and please try again.`)
          return false
        }
        //this one is ok
        return true
      })
    ) {
      return null
    }

    setLoading(true)
    return createCorporateVouchers(corporation.id, emails)
      .catch((err) => {
        alert(err)
      })
      .then(() => {
        setLoading(false)
      })
  }

  // handle file upload
  const resendVoucherInvitation = (voucher) => {
    setLoading(true)
    resendCorporateVouchers(corporation, [voucher]).then(() => {
      setLoading(false)
    })
  }

  const copyVoucherInvitation = (voucher) => {
    console.log('voucher', voucher)
    if (!voucher.shortLink) {
      alert('Voucher link is missing')
    } else {
      copyTextToClipboard(voucher.shortLink)
    }
  }

  const removeVoucher = (voucher) => {
    var msg = `Revoke access for ${voucher.email}?`
    if (window.confirm(msg)) {
      removeCorporationVouchers(corporation, [voucher])
    }
  }

  const onDownloadUsersCsv = () => {
    var blob = new Blob(
      [
        join(
          map(vouchers, (voucher) => join([voucher.email], ',')),
          '\r\n'
        ),
      ],
      { type: 'text/plain;charset=utf-8' }
    )
    saveAs(blob, 'vouchers.csv')
  }

  const onShowFilter = () => {
    //make a copy of the filters
    setFiltersEdit(filters)
    setShowFilter(true)
  }

  const onHideFilter = () => {
    setShowFilter(false)
  }

  const onClearFilter = () => {
    setFilters(DEFAULT_FILTERS())
  }

  const onApplyFilter = () => {
    setShowFilter(false)
    setFilters(filtersEdit)
  }

  const onToggleFilter = (evt) => {
    const id = evt.currentTarget.getAttribute('data-filter')
    const f = _.clone(filtersEdit)
    f[id] = !f[id]
    setFiltersEdit(f)
    console.log(id, f)
  }

  if (!corporation) {
    return <LoadingMask className="fill-page" enabled={true}></LoadingMask>
  }

  const totalNumberOfVouchers = size(vouchers)

  const numberOfUsers = corporation.numberOfUsers
  const numberOfAvailableMembers = (function () {
    switch (get(corporation, 'type')) {
      case 'selfService':
      case 'enterprise':
        return corporation
          ? corporation.activationLimit > 0
            ? corporation.activationLimit -
            size(filter(vouchers, { redeemed: true }))
            : numberOfUsers - totalNumberOfVouchers
          : 0
        break
      default:
        return 0
        break
    }
  })()

  const numberOfAvailableInvites = corporation.activationLimit
    ? numberOfUsers
    : numberOfAvailableMembers

  return (
    <AdminStatusBlocker className="admin admin-main wrapper-admin">
      {/* <AdminHeader currentRoute={'dashboard'} routes={routes} /> */}
      <WelcomeModal show={showWelcomeModal} setShow={setShowWelcomeModal} />
      <div className="header">
        <Link to={ROUTE_HOME}>
          <img src={Logo} alt="Deliciously Ella Logo" width={140} height={48} />
          <img src={logoutSvg} onClick={logout} alt='logout' className='body-2 logout-text' />
        </Link>
      </div>
      <div className="mobile-selector">
        <Dropdown options={menuItems} isSelfService={isSelfService} />
      </div>
      <div className="row">
        <SideNavBar
          isSuper={isSuper}
          isSelfService={isSelfService}
          corporation={corporation}
          corporationId={corporationId}
          logout={logout}
        />
        <LoadingMask enabled={loading} className="column-main">
          <AdminHeader
            currentRoute={'dashboard'}
            routes={routes}
            corporationId={corporationId}
            isSelfService={isSelfService}
            subscriptionPlan={subscriptionPlan}
            corporation={corporation}
          />

          <FlexRow className="member-list">
            <FlexRow className="main">
              <div className="invites mt-1">
                <TableList
                  items={vouchers || []}
                  onShowFilter={onShowFilter}
                  onClearFilter={onClearFilter}
                  filters={filters}
                  vouchers={vouchers}
                  corporation={corporation}
                  onShowInviteUsers={onShowInviteUsers}
                  onDownloadUsersCsv={onDownloadUsersCsv}
                  numberOfAvailableInvites={numberOfAvailableInvites}
                  filterer={(items, search) => {
                    search = formatEmail(search)
                    return orderBy(
                      filter(items, (item) => {
                        if (
                          search &&
                          !includes(formatEmail(item.email), search)
                        ) {
                          return false
                        }

                        if (filters.active && item.redeemed) {
                          //only show this if it's active
                          return true
                        } else if (filters.inactive && !item.redeemed) {
                          //only show this if it's active
                          return true
                        } else {
                          return false
                        }

                        return true
                      }),
                      'createdAt',
                      ['desc']
                    )
                  }}
                  renderer={(voucher, index) => (
                    <FlexRow
                      key={index}
                      align="center"
                      className={FormatClassNames(
                        'entry',
                        'with-controls'
                      )}
                    >
                      <span className="grow align-left">{voucher.email}</span>

                      <FlexRow className="controls">
                        {!voucher.redeemed && (
                          <Button
                            size="small"
                            variant="none"
                            className="resend-button"
                            onClick={() => resendVoucherInvitation(voucher)}
                          >
                            resend
                          </Button>
                        )}
                        {!voucher.redeemed && (
                          <Button
                            size="small"
                            onClick={() => copyVoucherInvitation(voucher)}
                            className="copy-link"
                          >
                            <img
                              src={copyLink}
                              alt="copy link"
                              width={21}
                              height={21}
                            />
                          </Button>
                        )}
                        <ToolTip label="Revoke Access">
                          <Button
                            variant="none"
                            className="remove-member-button"
                            onClick={() => removeVoucher(voucher)}
                          >
                            <img src={removeIcon} alt="Remove member" />
                          </Button>
                        </ToolTip>
                      </FlexRow>
                    </FlexRow>
                  )}
                />
              </div>
            </FlexRow>
            <div className="right-box">
              <div className="info">
                <p className="subtitle item-name"> company</p>
                <p className="body-1">{corporation.companyName}</p>
              </div>
              <div className="info">
                <p className="subtitle item-name">team</p>
                <p className="body-1">{corporation.teamName}</p>
              </div>
              <div className="info">
                <p className="subtitle item-name">account</p>
                <p className="body-1">
                  {isEmailValid(corporation.email) ? corporation.email : ''}
                </p>
              </div>
              <div className="info">
                <p className="subtitle item-name">total subscriptions</p>
                <p className="body-1">{numberOfUsers}</p>
              </div>
              <div className="info">
                <p className="subtitle item-name">
                  {corporation.activationLimit
                    ? 'activations left'
                    : 'subscriptions left'}
                </p>
                <p className="body-1">{numberOfAvailableMembers}</p>
              </div>
              <div className="info">
                <p className="subtitle item-name">plan type</p>
                <p className="body-1">{lowerCase(get(corporation, 'type'))}</p>
              </div>
              {!isSelfService && (
                <div className="info">
                  <p className="subtitle item-name">plan renews</p>
                  <p className="body-1">
                    {moment(get(corporation, 'renewsAt._seconds') * 1000).format(
                      'DD/MM/YY'
                    )}
                  </p>
                </div>
              )}

            </div>
          </FlexRow>

          {!isSelfService && (
            <div className="request-help">
              <p>
                Contact{' '}
                <a href="mailto:teams@deliciouslyella.com">
                  teams@deliciouslyella.com
                </a>{' '}
                to edit your plan and add subscriptions
              </p>
            </div>
          )}
        </LoadingMask>
      </div>

      {showInviteMoreUsers && (
        <AdminUserUpload
          limit={numberOfAvailableInvites}
          existingUsers={vouchers}
          onClose={onHideInviteUsers}
        />
      )}

      {showFilter && (
        <Modal onClose={onHideFilter} className="filters-popup size-medium">
          {onHideFilter && (
            <FlexRow className="close">
              <FlexSpacer />
              <img
                src={icoClose}
                alt="close"
                onClick={onHideFilter}
                width={22}
                height={22}
              />
            </FlexRow>
          )}
          <h2>Filter</h2>
          <ul>
            <li data-filter="active" onClick={onToggleFilter}>
              <span>active</span>
              {_.get(filtersEdit, 'active') && (
                <img src={circleTick} alt="enabled" width={20} height={20} />
              )}
            </li>
            <li data-filter="inactive" onClick={onToggleFilter}>
              <span>inactive</span>{' '}
              {_.get(filtersEdit, 'inactive') && (
                <img src={circleTick} alt="enabled" width={20} height={20} />
              )}
            </li>
          </ul>
          <FlexRow>
            <FlexSpacer />
            <Button onClick={onApplyFilter} className="apply-button">
              apply filter
            </Button>
          </FlexRow>
        </Modal>
      )}
    </AdminStatusBlocker>
  )
}

// Need to connect component to Redux

const mapStateToProps = (state) => ({
  corporation: get(state, 'corporation.data'),
  corporationId: get(state, 'corporation.data.id'),
  vouchers: get(state, 'corporation.vouchers'),
  corporations: get(state, 'admin.corporations'),
  subscription: get(state, 'corporation.data.payment.subscription'),
  subscriptionPlan: get(state, 'corporation.data.payment.subscription.plan'),
  paymentLast4: get(state, 'corporation.data.payment.method.card.last4'),
  paymentBrand: get(state, 'corporation.data.payment.method.card.brand'),
  companyName: get(state, 'corporation.data.companyName'),
  email: get(state, 'auth.userEmail'),
  isSuper: get(state, 'admin.isSuper') ? true : false,
  routes: ROUTES(state),
  welcome: get(state, 'admin.welcome'),
  isSelfService:
    get(state, 'corporation.data.type') === 'selfService' ? true : false,
})

export default connect(mapStateToProps, {
  createCorporateVouchers,
  resendCorporateVouchers,
  removeCorporationVouchers,
  setupCorporationOffSessionPayment,
  unloadCorporation,
  logout,
  setWelcomeFlag
})(AdminCorporationMain)

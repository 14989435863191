import React, { Fragment } from 'react'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'

import Logo from '../../assets/images/reskin/img-reskin_logo.svg'
import logoutSvg from '../../assets/images/logout.svg'

import '../admin/AdminHeader.scss'

import { get, map } from 'lodash-es'
import { FlexRow } from '../common/Flex'
import { Link } from 'react-router-dom'
import {
  ROUTE_DASHBOARD,
  ROUTE_SUPER_ADMIN_USERS,
  ROUTE_DASHBOARD_INSIGHTS,
  ROUTE_DASHBOARD_INVOICES,
} from '../../routes'
import { FormatClassNames } from '../../utils/utils.classnames'

import { logout } from '../../actions/auth'
import Button from '../common/Button'
import LogoutRedirect from '../auth/LogoutRedirect'

const SuperAdminHeader = ({ currentRoute, logout, disableLinks }) => {
  return (
    <FlexRow
      align="center"
      justify="flex-start"
      className={FormatClassNames(
        'admin-header',
        disableLinks ? 'disable-links' : null
      )}
    >
      <LogoutRedirect />
      <Link to={ROUTE_DASHBOARD} >
        <img src={Logo} alt="Deliciously Ella Logo" width={140} height={48} />
      </Link>
      <img src={logoutSvg} onClick={logout} alt='logout' className='body-2 logout-text' />
      <nav>
        <ul>
          {map(
            [
              // {route:ROUTE_SUPER_ADMIN_USERS,label:'Admins'},
            ],
            (item, index) => (
              <li
                key={index}
                className={FormatClassNames(
                  'link',
                  item.route === currentRoute ? 'selected' : null
                )}
              >
                <Link to={item.route}>{item.label}</Link>
              </li>
            )
          )}
          {/* <li><Button color="secondary" size='small' onClick={logout}>Logout</Button></li> */}
        </ul>
      </nav>
    </FlexRow>
  )
}

SuperAdminHeader.propTypes = {
  currentRoute: PropTypes.string,
  disableLinks: PropTypes.bool,
}

SuperAdminHeader.defaultProps = {
  disableLinks: false,
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, {
  logout,
})(SuperAdminHeader)

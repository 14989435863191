import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { auth } from '../../firebase'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { get, some, debounce, size, upperCase, filter } from 'lodash-es'
// components
import Dashboard from '../layout/DashBoard'
import Button from '../common/Button'
import Input from '../common/Input'
import Modal from '../common/Modal'
import { FlexColumn, FlexRow, FlexSpacer } from '../common/Flex'
import { logout } from '../../actions/auth'

import './AdminEditPlan.scss'

import {
  quoteUpdateCorporationPlanSize,
  updateCorporationPlanSize,
  updateCorporationDetails,
  editCorporationPaymentMethod,
} from '../../actions/corporation'

// assets

import loadingGrid from '../../assets/images/grid.svg'
import InputNumber from '../common/InputNumber'
import logoutSvg from '../../assets/images/logout.svg'

import {
  ROUTE_HOME,
  ROUTE_DASHBOARD,
  ROUTE_DASHBOARD_EDIT_PLAN,
  ROUTE_DASHBOARD_INSIGHTS,
  ROUTE_DASHBOARD_INVOICES
} from '../../routes'
import SideNavBar from '../common/SideNavBar'
import AdminStatusBlocker from './AdminStatusBlocker'
import Logo from '../../assets/images/reskin/img-reskin_logo.svg'
import icoClose from '../../assets/images/icoClose.png'
import Dropdown from '../common/Dropdown'

import { signupRetryPayment } from '../../actions/signup'
import LoadingMask from '../common/LoadingMask'
import ErrorMessage from '../common/ErrorMessage'
import { loadUser } from '../../actions/auth'
import { formatPrice } from '../../utils/utils.format'
import { ROUTES } from './AdminRoutes'

import AdminTerminateCorporation from './AdminTerminateCorporation'
import AdminSwitchCorporation from './AdminSwitchCorporation'

const PADDING = { padding: '10px 25px' }
const menuItems = [
  {
    title: 'account',
    url: ROUTE_DASHBOARD,
  },
  {
    title: 'manage account',
    url: ROUTE_DASHBOARD_EDIT_PLAN,
  },
  {
    title: 'insights',
    url: ROUTE_DASHBOARD_INSIGHTS,

  },
  {
    title: 'invoices',
    url: ROUTE_DASHBOARD_INVOICES,
  },
]
// fontawesome.library.add(faCheckSquare, faCoffee);
const AdminEditPlan = ({
  corporationId,
  corporation,
  companyName,
  subscription,
  subscriptionPlan,
  paymentLast4,
  paymentBrand,
  vouchers,
  numberOfVouchers,
  numberOfUsers,
  numberOfUsersAtRenewal,
  quoteUpdateCorporationPlanSize,
  updateCorporationPlanSize,
  updateCorporationDetails,
  editCorporationPaymentMethod,
  loadUser,
  email,
  routes,
  isSuper,
  isSelfService,
  logout,
}) => {
  const [inputNumberOfUsers, setInputNumberOfUsers] = useState(
    numberOfUsers || 0
  )

  const [inputActivationLimit, setInputActivationLimit] = useState(
    corporation.activationLimit
  )
  const [inputCardDetails, setInputCardDetails] = useState('')
  const [inputAddress, setInputAddress] = useState('')
  const [inputEmail, setInputEmail] = useState(email || '')
  const [inputPassword, setInputPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [showChangePassword, setShowChangePassword] = useState(false)
  const [inputCompanyName, setInputCompanyName] = useState('')
  const [inputTeamName, setInputTeamName] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  const [requiresQuote, setRequiresQuote] = useState(false)
  const [quote, setQuote] = useState(null)

  const [showTerminateCorporation, setShowTerminateCorporation] =
    useState(false)
  const [showSwitchCorporation, setShowSwitchCorporation] = useState(false)
  const [readOnlyItems, setReadOnlyItems] = useState({
    email: true,
    companyName: true,
    teamName: true,
  })

  const onShowTerminateCorporation = () => {
    setShowTerminateCorporation(true)
  }

  const onHideTerminateCorporation = () => {
    setShowTerminateCorporation(false)
  }

  const onShowSwitchCorporation = () => {
    setShowSwitchCorporation(true)
  }

  const onHideSwitchCorporation = () => {
    setShowSwitchCorporation(false)
  }

  if (!numberOfUsersAtRenewal) {
    numberOfUsersAtRenewal = numberOfUsers
  }

  useEffect(() => {
    setInputNumberOfUsers(numberOfUsersAtRenewal)

    if (corporation) {
      setInputTeamName(corporation.teamName)
      setInputCompanyName(corporation.companyName)
    }
  }, [numberOfUsers, numberOfUsersAtRenewal, corporation])

  useEffect(() => {
    if (email) {
      setInputEmail(email)
    }
  }, [email])

  useEffect(() => {
    setQuote(null)
    watchNumberOfUsers(inputNumberOfUsers)
  }, [inputNumberOfUsers])

  const watchNumberOfUsers = useCallback(
    debounce((value) => {
      switch (corporation.type) {
        case 'selfService':
          //be careful to ensure there is a subscription plan
          if (subscriptionPlan) {
            //we require a quote
            setRequiresQuote(true)
            setError(null)
            setQuote(null)
            quoteUpdateCorporationPlanSize(corporation.id, {
              numberOfUsers: value,
            })
              .then((result) => {
                console.log('received invoice/quote', get(result, 'invoice'))
                // console.log('received quote', result);
                setRequiresQuote(false)
                setQuote(result || {})
              })
              .catch((err) => alert(`Error generating quote: ${err.message}`))
          }
          break
      }
    }, 1000),
    [corporation.type]
  )

  const cancelEditPlan = () => {
    setInputNumberOfUsers(numberOfUsersAtRenewal)
    setInputActivationLimit(corporation.activationLimit)
  }

  const confirmEditPlan = () => {
    var acceptsChanges = true

    if (isSelfService) {
      const msg =
        inputNumberOfUsers > numberOfUsersAtRenewal
          ? `Your plan will increase to ${quote.to} members and you will be immediately charged the difference. Do you wish to continue?`
          : `At the next renewal date, your plan will reduce to ${quote.to} members and the new charge will take effect. Do you wish to continue?`

      acceptsChanges = window.confirm(msg)
    } else {
      const numberOfRedeemedVouchers = size(
        filter(vouchers, { redeemed: true })
      )

      if (
        corporation.activationLimit > 0 &&
        inputActivationLimit < numberOfRedeemedVouchers
      ) {
        return window.alert(
          `Activation limit is lower than number of redeemed subscriptions. Please adjust to ${numberOfRedeemedVouchers} or higher.`
        )
      }

      acceptsChanges = isSuper
    }

    if (acceptsChanges) {
      //change number of users
      setRequiresQuote(false)
      setError(null)
      setQuote(null)
      setIsLoading(true)
      updateCorporationPlanSize(corporation.id, {
        numberOfUsers: inputNumberOfUsers,
        activationLimit:
          inputActivationLimit != corporation.activationLimit
            ? inputActivationLimit
            : null,
        cancelRoute: ROUTE_DASHBOARD_EDIT_PLAN,
      })
        .catch((err) => {
          setError(err.message || err)
        })
        .then(() => {
          setIsLoading(false)
        })
    }
  }

  const changeCardDetails = () => {
    setIsLoading(true)

    editCorporationPaymentMethod(corporation.id)
      .catch((err) => {
        setError('Error connecting to Stripe')
      })
      .then(() => {
        setIsLoading(false)
      })
  }

  const saveDetails = () => {
    if (areCorporationDetailsDifferent) {
      setIsLoading(true)
      updateCorporationDetails(corporation.id, {
        companyName: inputCompanyName,
        teamName: inputTeamName,
      })
        .catch((err) => {
          setError('Error connecting to Stripe')
        })
        .then(() => {
          setIsLoading(false)
        })
    }

    if (areUserDetailsDifferent) {
      //change the userName
      auth.currentUser
        .updateEmail(inputEmail)
        .then(() => {
          alert('successfully updated email')
          loadUser()
        })
        .catch((err) => {
          setInputEmail(email)
          alert(err.message)
        })
    }
    setReadOnlyItems({
      ...readOnlyItems,
      email: true,
      companyName: true,
      teamName: true,
    })
  }

  const isPlanDifferent =
    inputNumberOfUsers !== numberOfUsersAtRenewal
      ? true
      : false || inputActivationLimit != corporation.activationLimit
        ? true
        : false

  const areCorporationDetailsDifferent = corporation
    ? some([
      corporation.companyName !== inputCompanyName,
      corporation.teamName !== inputTeamName,
    ])
    : false

  const areUserDetailsDifferent = corporation
    ? some([email !== inputEmail])
    : false

  const areDetailsDifferent = corporation
    ? some([areCorporationDetailsDifferent, areUserDetailsDifferent])
    : false

  const updatePassword = () => {
    //set password in firebase
    var user = auth.currentUser

    user
      .updatePassword(newPassword)
      .then(function () {
        setShowChangePassword(false)
        setInputPassword(newPassword)
        setNewPassword('')
        setConfirmPassword('')
      })
      .catch(function (error) {
        console.log('error', error)
        // An error happened.
        alert(error.message || error)
      })
  }

  const handleEdit = (field) => {
    setReadOnlyItems({
      ...readOnlyItems,
      [field]: false,
    })
  }

  return (
    <AdminStatusBlocker className="admin wrapper-admin plan-wrapper">
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <div className="header">
        <Link to={ROUTE_HOME}>
          <img src={Logo} alt="Deliciously Ella Logo" width={140} height={48} />
          <img src={logoutSvg} onClick={logout} alt='logout' className='body-2 logout-text' />
        </Link>
      </div>
      <div className="mobile-selector">
        <Dropdown options={menuItems} isSelfService={isSelfService} />
      </div>
      <div className="row">
        <SideNavBar
          isSuper={isSuper}
          isSelfService={isSelfService}
          corporation={corporation}
          corporationId={corporationId}
          logout={logout}
        />

        <LoadingMask enabled={isLoading} className="column-main">
          {/* <AdminHeader currentRoute={'editPlan'} routes={routes} /> */}
          <div className="edit-plan">
            {
              <div className={isPlanDifferent ? 'edit-plan-top' : null}>
                {numberOfUsersAtRenewal !== numberOfUsers && (
                  <p className="pb-2">
                    you currently have {numberOfUsers} members, the plan below
                    will take effect at the next renewal.
                  </p>
                )}

                {!isSelfService && (
                  <Fragment>
                    <div className="plan-calc">
                      <h4>edit plan</h4>
                      <FlexColumn className='plan-details-plan'>
                        <InputNumber
                          min={Math.max(2, numberOfVouchers)}
                          value={inputNumberOfUsers}
                          disabled={!isSuper}
                          onChange={setInputNumberOfUsers}
                        />
                        {corporation.activationLimit && (
                          <div>
                            <h4>Activation limit</h4>
                            <InputNumber
                              value={inputActivationLimit}
                              disabled={!isSuper}
                              onChange={setInputActivationLimit}
                            />
                          </div>
                        )}
                      </FlexColumn>
                      {isSuper && isPlanDifferent && (
                        <div className="confirm-cancel">
                          <Button
                            onClick={confirmEditPlan}
                            className="confirm-button"
                          >
                            Confirm
                          </Button>
                          <a onClick={cancelEditPlan} className="cancel-link">
                            cancel
                          </a>
                        </div>
                      )}
                    </div>
                  </Fragment>
                )}

                {isSelfService && subscriptionPlan && (
                  <div className="plan-calc">
                    <h4>edit plan</h4>
                    <FlexRow align='center'>
                      <div className="plan-details-plan">
                        <InputNumber
                          min={Math.max(2, numberOfVouchers)}
                          value={inputNumberOfUsers}
                          onChange={setInputNumberOfUsers}
                        />
                        {isSelfService ? (
                          <div className='plan-count'>
                            <p>
                              <b>members</b> x{' '}
                              {formatPrice(
                                subscriptionPlan.amount,
                                subscriptionPlan.currency
                              )}{' '}
                              =
                            </p>
                            <div className="per-year">
                              <p className="cost">
                                {formatPrice(
                                  subscriptionPlan.amount * inputNumberOfUsers,
                                  subscriptionPlan.currency
                                )}
                              </p>
                              <p className="text">
                                {' '}
                                per {upperCase(subscriptionPlan.interval)}
                              </p>
                            </div>
                          </div>
                        ) : (
                          <p>
                            <b>members</b>
                          </p>
                        )}
                      </div>


                    </FlexRow>
                    {isPlanDifferent && (
                      <div className="confirm-cancel">
                        {isSelfService ? (
                          <Button
                            disabled={!quote}
                            onClick={confirmEditPlan}
                            className={`confirm-button ${!quote && 'loading'}`}
                          >
                            {quote && 'Confirm'}
                            {!quote && (
                              <img
                                src={loadingGrid}
                                className="grid-loader"
                                alt="Back"
                              />
                            )}
                          </Button>
                        ) : (
                          <Button
                            onClick={confirmEditPlan}
                            className="confirm-button"
                          >
                            Confirm
                          </Button>
                        )}
                        <a onClick={cancelEditPlan} className="cancel-link">
                          cancel
                        </a>
                      </div>
                    )}
                    {get(quote, 'amount') > 0 &&
                      get(subscription, 'quantity') &&
                      subscription.quantity < quote.to &&
                      quote && (
                        <FlexRow className="additional-payment">
                          <div>
                            <p className="mb-1">
                              you need to pay the difference of{' '}
                              <b>{formatPrice(quote.amount, quote.currency)}</b>{' '}
                              today to upgrade to <b>{quote.to} members</b>{' '}
                              until your next renewal date.
                            </p>
                          </div>
                        </FlexRow>
                      )}
                  </div>
                )}
              </div>
            }

            <div style={isSelfService ? null : PADDING} className="bottom-div">
              {isSelfService && (
                <div className='billing-section'>
                  <h4>billing method</h4>
                  <FlexRow align="end">
                    <Input
                      readOnly
                      className="grow input-field"
                      label=""
                      placeholder="credit card (Stripe)"
                      haveEdit
                      onEdit={changeCardDetails}
                      value={
                        paymentBrand && paymentLast4
                          ? `**** **** **** ${paymentLast4} (${paymentBrand.toUpperCase()})`
                          : ''
                      }
                    />
                    {/* <Button variant='none' onClick={}>Edit</Button> */}
                  </FlexRow>
                </div>
              )}

              {/*
              <h2>Billing</h2>
              <FlexRow align="end">
                <Input
                  readOnly
                  className="grow input-field"
                  label="Card Detail"
                  placeholder="Credit card (Stripe)"
                  value={inputCardDetails}
                  onChange={setInputCardDetails}
                />
                <Button onClick={changeCardDetails}>Edit</Button>
              </FlexRow>

              <Input
                readOnly
                className="grow"
                label="Billing Address"
                type="text"
                placeholder="150 Adam Street, Manchester, M10 3AR"
                value={inputAddress}
                onChange={setInputAddress}
              />
            */}

              {!isSuper && (
                <div className='manage-account-box'>
                  <h4>Manage Account</h4>
                  <Input
                    className="grow"
                    label="Company Name"
                    type="text"
                    placeholder=""
                    readOnly={readOnlyItems.companyName}
                    value={inputCompanyName}
                    onChange={setInputCompanyName}
                    haveEdit
                    onEdit={() => handleEdit('companyName')}
                  />
                  <Input
                    className="grow"
                    label="Team Name"
                    type="text"
                    placeholder=""
                    readOnly={readOnlyItems.teamName}
                    value={inputTeamName}
                    onChange={setInputTeamName}
                    haveEdit
                    onEdit={() => handleEdit('teamName')}
                  />
                  <Input
                    className="grow"
                    label="account"
                    type="email"
                    placeholder=""
                    readOnly={readOnlyItems.email}
                    value={inputEmail || ''}
                    onChange={setInputEmail}
                    haveEdit
                    onEdit={() => handleEdit('email')}
                  />

                  <Input
                    className="grow no-border"
                    label="Password"
                    type="password"
                    value={'••••••••'}
                    readOnly
                    onChange={setInputPassword}
                    haveEdit
                    onEdit={() => setShowChangePassword(true)}
                  />

                  <FlexRow align="end"></FlexRow>
                </div>
              )}

              {areDetailsDifferent && (
                <Button onClick={saveDetails} className="save-change-button">
                  save changes
                </Button>
              )}

              {isSuper && (
                <Fragment>
                  <h4 className="account-padding">advanced</h4>
                  <FlexRow className="controls controls-box">
                    {isSelfService && (
                      <Button onClick={onShowSwitchCorporation}>
                        switch to enterprise
                      </Button>
                    )}

                    <Button onClick={onShowTerminateCorporation}>
                      delete team
                    </Button>
                  </FlexRow>
                  {showSwitchCorporation && (
                    <AdminSwitchCorporation onClose={onHideSwitchCorporation} />
                  )}
                  {showTerminateCorporation && (
                    <AdminTerminateCorporation
                      onClose={onHideTerminateCorporation}
                    />
                  )}
                </Fragment>
              )}
            </div>
            {!isSelfService && (
              <div className="request-help-text">
                <p>
                  Contact{' '}
                  <a href="mailto:teams@deliciouslyella.com">
                    teams@deliciouslyella.com
                  </a>{' '}
                  to edit your plan and add subscriptions
                </p>
              </div>
            )}
          </div>
        </LoadingMask>
      </div>
      <Modal
        show={showChangePassword ? true : false}
        onClose={() => {
          setShowChangePassword(null)
        }}
        className="change-password-popup"
      >
        <FlexRow className="modal-header">
          <h5>Change password</h5>
          <button
            onClick={() => {
              setShowChangePassword(null)
            }}
          >
            <img src={icoClose} alt="close" width={22} height={22} />
          </button>
        </FlexRow>
        <p className='body-2 input-label'>New password *</p>
        <Input
          type="password"
          placeholder=""
          value={newPassword}
          onChange={setNewPassword}
          className="change-password-input"
        // required
        />
        <p className='body-2 input-label'>Confirm new password *</p>
        <Input
          type="password"
          placeholder=""
          value={confirmPassword}
          className="change-password-input"
          onChange={setConfirmPassword}
        // required
        />
        <Button
          className="change-password-button"
          type="submit"
          disabled={newPassword !== confirmPassword || newPassword.length < 6}
          onClick={updatePassword}
        >
          change password
        </Button>
      </Modal>
    </AdminStatusBlocker>
  )
}

// Need to connect component to Redux

const mapStateToProps = (state) => ({
  subscription: get(state, 'corporation.data.payment.subscription'),
  subscriptionPlan: get(state, 'corporation.data.payment.subscription.plan'),
  paymentLast4: get(state, 'corporation.data.payment.method.card.last4'),
  paymentBrand: get(state, 'corporation.data.payment.method.card.brand'),
  companyName: get(state, 'corporation.data.companyName'),
  vouchers: get(state, 'corporation.vouchers'),
  numberOfVouchers: size(get(state, 'corporation.vouchers')),
  email: get(state, 'auth.userEmail'),
  numberOfUsers: get(state, 'corporation.data.numberOfUsers'),
  numberOfUsersAtRenewal: get(state, 'corporation.data.numberOfUsersAtRenewal'),
  corporation: get(state, 'corporation.data'),
  corporationId: get(state, 'corporation.data.id'),
  routes: ROUTES(state),
  isSuper: get(state, 'admin.isSuper'),
  isSelfService:
    get(state, 'corporation.data.type') === 'selfService' ? true : false,
})

export default connect(mapStateToProps, {
  quoteUpdateCorporationPlanSize,
  updateCorporationPlanSize,
  signupRetryPayment,
  updateCorporationDetails,
  editCorporationPaymentMethod,
  loadUser,
  logout,
})(AdminEditPlan)

import React, { useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'

// Component imports

import PrivateRoute from './components/routing/PrivateRoute'
import RestrictedLanding from './components/layout/RestrictedLanding'

import AdminCorporationMain from './components/admin/AdminCorporationMain'
import AdminInvoices from './components/admin/AdminInvoices'
import Test from './components/test/Test'
import { get } from 'lodash-es'

// Redux
import { Provider } from 'react-redux'
// ACTIONS
import { loadUser } from './actions/auth'

import './App.scss'

import {
  ROUTE_SUPER_ADMIN,
  ROUTE_SUPER_DASHBOARD,
  ROUTE_ADMIN,
  ROUTE_DASHBOARD,
  ROUTE_DASHBOARD_INVOICES,
  ROUTE_DASHBOARD_EDIT_PLAN,
  ROUTE_DASHBOARD_INSIGHTS,
  ROUTE_LOGIN,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_SIGNUP,
  ROUTE_SIGNUP_INVITE,
  ROUTE_PAYMENT_QUEUE,
  ROUTE_PAYMENT_CANCEL,
  ROUTE_PAYMENT_SUCCESS,
  ROUTE_TEST,
  ROUTE_TERMS_AND_CONDITIONS,
  ROUTE_NEW_HOME,
} from './routes'

import Signup from './components/signup/Signup'

import store from './store'
import Login from './components/auth/Login'
import SignupInvite from './components/auth/SignupInvite'
import ForgotPassword from './components/auth/ForgotPassword'

import TermsAndConditions from './components/info/TermsAndConditions'
import AdminInsights from './components/admin/AdminInsights'
import AdminEditPlan from './components/admin/AdminEditPlan'
import LoadingBlocker from './components/common/LoadingBlocker'

import PaymentSuccess from './components/payment/PaymentSuccess'
import PaymentCancel from './components/payment/PaymentCancel'
import PaymentQueue from './components/payment/PaymentQueue'
import AdminRoutes from './components/admin/AdminRoutes'
import SuperAdminRoutes from './components/superadmin/SuperAdminRoutes'

const { REACT_APP_VERSION = '?.?.?', NODE_ENV = 'unknown' } = process.env

function App() {
  useEffect(() => {
    setTimeout(() => {
      console.log(`${REACT_APP_VERSION} [${NODE_ENV}]`)
    }, 2000)
    store.dispatch(loadUser())
  }, [])

  return (
    <Provider store={store}>
      <LoadingBlocker className="root">
        <Router>
          <Switch>
            {/* TERMS AND CONDITIONS */}
            <Route
              exact
              path={ROUTE_TERMS_AND_CONDITIONS}
              component={TermsAndConditions}
            />

            {/* SIGNUP */}
            <Route exact path={ROUTE_SIGNUP} component={Signup} />

            {/* SIGNUP QUEUE */}
            <Route exact path={ROUTE_PAYMENT_QUEUE} component={PaymentQueue} />

            {/* SIGNUP_SUCCESS */}
            <Route
              exact
              path={ROUTE_PAYMENT_SUCCESS}
              component={PaymentSuccess}
            />

            {/* SIGNUP_SUCCESS */}
            <Route
              exact
              path={ROUTE_PAYMENT_CANCEL}
              component={PaymentCancel}
            />

            {/* LOGIN */}
            <Route exact path={ROUTE_LOGIN} component={Login} />

            {/* SIGNUP_INVITE */}
            <Route
              exact
              path={ROUTE_SIGNUP_INVITE}
              render={(route) => {
                const inviteId = get(route, 'match.params.inviteid')
                return <SignupInvite inviteId={inviteId} />
              }}
            />

            {/* FORGOT_PASSWORD */}
            <Route
              exact
              path={ROUTE_FORGOT_PASSWORD}
              component={ForgotPassword}
            />

            {/* DASHBOARD */}
            <PrivateRoute path={ROUTE_ADMIN} component={AdminRoutes} />

            {/* SUPER_ADMIN */}
            <PrivateRoute
              path={ROUTE_SUPER_ADMIN}
              component={SuperAdminRoutes}
            />

            {/* TESTIING AREA */}
            <Route path={ROUTE_TEST} component={Test} />

            {/* DEFAULT REDIRECT */}
            <Route
              render={() => {
                return get(store.getState(), 'auth.isAuthenticated') ? (
                  <Redirect to={ROUTE_ADMIN} />
                ) : (
                  <Redirect to={ROUTE_SIGNUP} />
                )
              }}
            />
          </Switch>
        </Router>
      </LoadingBlocker>
      {/* <p className="app-info">{REACT_APP_VERSION} [{NODE_ENV}]</p> */}
    </Provider>
  )
}

export default App

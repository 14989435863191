import React, { useEffect } from 'react'

import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import LoginRedirect from '../auth/LoginRedirect'

import Signup from '../archive_signup/Signup'

const Homepage = ({ homepageItems }) => {
  useEffect(() => {}, [])
  return (
    <div className="container">
      <LoginRedirect />
      <Signup />
    </div>
  )
}

Homepage.propTypes = {
  disabled: PropTypes.bool,
}

Homepage.defaultProps = {
  disabled: false,
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, {})(Homepage)
